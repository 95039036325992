<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3 class="secondary--text">Company addresses</h3>
      <div
        v-if="!isAddMode && addressesList.length"
        @click="addNewAddress"
        class="icon-cont"
      >
        <span class="mdi mdi-plus neutral--text"/>
      </div>
    </div>
    <div class="P-modal-component-body">
      <ManageAddress v-if="isAddMode || isEditMode || !addressesList.length"/>
      <AddressList v-else/>
    </div>
  </div>
</template>
<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import ManageAddress from "./ManageAddress";
  import AddressList from "./AddressList";

  export default {
    name: "CompanyAddressesAdmin",
    components: {AddressList, ManageAddress},
    computed: {
      ...mapState({
        addressesList: state => state.companyAddressesAdmin.addressesList,
        isAddMode: state => state.companyAddressesAdmin.isAddMode,
        isEditMode: state => state.companyAddressesAdmin.isEditMode,
        manageAdminModule: state => state.manageAdminModule
      })
    },
    async mounted() {
      if (this.manageAdminModule.providerId) {
        await this.getCompanyAddresses(this.manageAdminModule.providerId).then(data => {
          this.updateAddressList(data)
        })
      }
    },
    methods: {
      ...mapActions({
        getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses'
      }),

      ...mapMutations({
        updateAddressList: 'companyAddressesAdmin/updateAddressList',
        openAddNewAddress: 'companyAddressesAdmin/openAddNewAddress'
      }),

      addNewAddress() {
        this.openAddNewAddress();
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-modal-component-header {
    margin-bottom : 10px;

    h3 {
      font-size      : $txt16;
      font-weight    : $semiBold;
      text-transform : capitalize;
    }
  }
</style>
