import { mapActions, mapMutations, mapState} from "vuex";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import GeneralInfo from "./manageUser/GeneralInfo";
import Role from "./manageUser/Role";
import SecurityLogin from "./manageUser/SecurityLogin";
import Bot from "./manageUser/Bot";
import PermissionMixin from "../../../mixins/PermissionsMixin";

export default {
  name: "ManageUser",
  components: {
    SecurityLogin,
    Role,
    GeneralInfo,
    OttButton,
    OttDialog,
    Bot
  },
  mixins: [PermissionMixin],
  data() {
    return {
      isEquipmentInstaller: false
    }
  },
  watch: {
    activeTab(val) {
      if (val == 2 && this.users.showRoles) {
        console.log('does not show bot in this tab');
        this.isEquipmentInstaller = false
      } else if (val == 0 || val == 1) {
        this.isEquipmentInstaller = false
      } else {
        this.isEquipmentInstaller = true
      }
    }
  },
  computed: {
    ...mapState({
      activeTab: state => state.users.activeTab,
      users: state => state.users,
      generalInfoUser: state => state.generalInfoUser,
      generalInfoValidation: state => state.generalInfoUser.isValidate,
      roleValidation: state => state.roleUser.isValidate,
      securityValidation: state => state.securityLogin.isValidate,
      roleUser: state => state.roleUser,
      securityLogin: state => state.securityLogin,
      auth: state => state.auth,
      isSaved: state => state.appModule.isSaved,
      isLoading: state => state.appModule.isLoading,
      userData: state => state.users.userData
    }),

    tabsList() {
      return this.users.showRoles ? this.users.showList : this.users.showList.filter(item => item.title !== 'roles');
    },

    filteredTabsList() {
      if (!this.userData?.rolesInfo?.equipmentInstaller) {
        return this.tabsList.filter(item => item.name !== 'Bot')
      }
      return this.tabsList
    },
  },
  async created() {
    this.setData({ disableButton: false })
    await this.getRoleList();
  },
  
  methods: {
    ...mapActions({
      getRoleList: 'roleUser/getRoleList',
      addUser: 'users/addUser',
      getUsersList:'users/getUsersList',
      updateUserAction: 'users/updateUser',
    }),

    ...mapMutations({
      setData: 'users/setData',
      setDataSecondLevels: 'users/setDataSecondLevels',
      toSaveData: 'users/toSaveData',
      nextTab: 'users/nextTab',
      generalInfoUserReset: 'generalInfoUser/resetData',
      roleUserReset: 'roleUser/resetData',
      securityLoginReset: 'securityLogin/resetData',
      closeModal: 'users/closeModal',
      updateAuthUser: 'auth/updateAuthUser'
    }),

    click() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    getRolesList() {
      const rolesInfo = {...this.roleUser.initialRoles};
      for (let role in rolesInfo) {
        this.roleUser.roleList.map(item => {
          if (item.value === null) item.value = false
          if (item.name === role) rolesInfo[role] = item.value 
        })  
      }

      return rolesInfo;
    },

    async createUser() {
      const rolesInfo = this.getRolesList();

      const body = {
        generalInfo: this.generalInfoUser.formData,
        role: this.roleUser.selectedRoleList,
        securityLogin: this.securityLogin.formData,
        userId: this.auth.user.provider.id,
        color: this.generalInfoUser.formData.color?.hex,
        rolesInfo
      }

      this.toSaveData(body)
      if (!this.users.userId) {
        await this.addUser().then(
          async () => {
            this.resetDataModal()
            this.setDataSecondLevels({
              filter: { page: 1 }
            })
            if (this.permissionIs('getRegistrationUsers'))
              await this.getUsersList(this.users.filter)
          })
      } else {
        await this.updateUserAction(this.users.userId).then(
          async () => {
            this.resetDataModal()
            if (this.permissionIs('getRegistrationUsers'))
              await this.getUsersList(this.users.filter)
          })
      }
    },
    async updateUser() {
      const rolesInfo = this.getRolesList()

      const body = { userId: this.auth.user.provider.id }
      let isValidTab = false;

      switch (this.activeTab) {
        case 0:
          body.generalInfo = this.generalInfoUser.formData
          await this.$refs['generalInfo'].validationInfo().then(r => {isValidTab = r})
          break;

        case 1:
          body.securityLogin = this.securityLogin.formData
          await this.$refs['securityLogin'].validateSecurityLogin().then(r => isValidTab = r)
          break;

        case 2:
          body.role = this.roleUser.selectedRoleList
          body.rolesInfo = rolesInfo
          isValidTab = this.$refs['roleUser'].isSelectedRole
          break;
      }

      if (isValidTab) {
        this.toSaveData(body)

        await this.updateUserAction(this.users.userId).then(
          async (data) => {
            if (this.users.userId === this.auth.user.id) {
              this.updateAuthUser(data)
            }
            if (this.permissionIs('getRegistrationUsers'))
              await this.getUsersList(this.users.filter)
          })
      }
    },

    async saveAndContinue() {
      this.$refs.generalInfo.showPhoneError = true
      switch (this.activeTab) {
        case 0:
          this.$refs['generalInfo'].validationInfo().then(data => {
            if (data) {
              this.setData({ activeTab: 1 })
            }
          })
          break

        case 1:
          this.$refs['securityLogin'].validateSecurityLogin().then(data => {
            if (data) {
              this.nextTab()
            }
          })
          break

        case 2:
          if (this.$refs['roleUser'].isSelectedRole) {
            await this.createUser()
          }
          break
      }
    },
    cancel() {
      this.resetDataModal();
    },

    validateAddProvider() {

    },
    resetDataModal() {
      this.generalInfoUserReset()
      this.roleUserReset()
      this.securityLoginReset()
      this.closeModal()
      this.setData({ showRoles: true })

    },
    changeTab(value) {
      if (
        this.users.disableTabs ||
        this.users.disableTabs === 0
      ) {
        if (value <= this.users.disableTabs) {
          this.setData({ activeTab: value });
        }
      } else {
        this.setData({ activeTab: value });
      }
    },
    clickList() {
      const [value] = arguments;

      if (value <= this.users.disableTabs && this.users.showRoles) {
        switch (this.activeTab) {
          case 0:
            this.$refs['generalInfo'].validationInfo().then(data => {
              if (data) {
                this.changeTab(value)
              } else {
                this.setData({ disableButton: true })
              }
            })
            break

          case 1:
            if(this.users.userId) {
              this.changeTab(value)
            } else {
              this.$refs['securityLogin'].validateSecurityLogin().then(data => {
                if (data) {
                  this.validateAllTabs()
                  this.changeTab(value)
                }
              })
            }
            break

          case 2:
            if (this.users.showRoles && this.$refs['roleUser'].isSelectedRole || !this.users.showRoles) {
              this.changeTab(value)
            } else {
              this.setData({ disableButton: true })
            }
            break
          case 3:
            this.changeTab(value)
            break
        }
      } else {
        this.changeTab(value)
      }
    },

    validateAllTabs() {
      let generalInfo = this.$refs['generalInfo'].validationInfo()
      let security = this.$refs['securityLogin'].validateSecurityLogin()
      let role = this.$refs['roleUser'].isSelectedRole
      return Promise.all([generalInfo, security, role]).then(data => {
        this.setData({ disableButton: !!data.some(x => !x) })
        return data.some(x => !x)
      })
    },
    saveAndCloseData() {
      if (!this.users.disableFlag) {
        this.setData({ disableFlag: true })
      }
      this.validateAllTabs().then(data=>{
        if(!data){
          this.createUser()
        }
      })
    }

  },
};