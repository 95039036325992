<template>
  <div class="P-manage-payments">
    <div class="P-payment-type G-align-start" v-if="!editMode">
      <ul>
        <li
          class="G-flex secondary--text"
          :class="{
            'P-active-payment': selectedPaymentType === paymentType.creditCard
          }"
          @click="selectPaymentType(paymentType.creditCard)"
        >
          <span class="icon icon-credit-card"></span>
          Credit card
        </li>
        <li
          class="G-flex secondary--text"
          :class="{
            'P-active-payment': selectedPaymentType === paymentType.bankAccount
          }"
          @click="selectPaymentType(paymentType.bankAccount)"
        >
          <span class="icon icon-bank-account"></span>
          Bank account
        </li>
      </ul>
    </div>
    <div class="P-payment-components">
      <CreditCard
        v-if="selectedPaymentType === paymentType.creditCard"
      />
      <BankAccount
        v-if="selectedPaymentType === paymentType.bankAccount"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import CreditCard from "./components/CreditCard";
import BankAccount from "./components/BankAccount";

export default {
  name: "ManagePayments",
  components: { BankAccount, CreditCard },
  computed: {
    ...mapState({
      paymentType: state => state.paymentMethodsAdmin.paymentType,
      selectedPaymentType: state => state.paymentMethodsAdmin.selectedPaymentType,
      editMode: state => state.paymentMethodsAdmin.editMode
    })
  },
  methods: {
    ...mapMutations({
      selectPaymentType: "paymentMethodsAdmin/selectPaymentType"
    }),
  }
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-payment-type {
  ul {
    margin: 0;
    padding: 1px;
    box-shadow: 0 0 5px #00000012;
    border-radius: 27px;
    display: flex;
    align-items: flex-start;

    li {
      svg {
        width: 22px;
        height: 22px;
        fill: $secondary-color;
        margin-right: 10px;
      }

      font-size: $txt14;
      font-weight: $semiBold;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 27px;
      transition: $transition;
      text-transform: capitalize;

      &.P-active-payment {
        background-color: $neutral-color25;
      }
      .icon {
        font-size: 20px;
        margin-right: 14px;
      }
    }
  }
}
  .theme--dark{
    .P-payment-type{
      ul{
        background-color: $darkMode43;

      }
      svg{
        filter: grayscale(1) invert(1);
      }
      .P-active-payment{
        background-color: rgba(255,255,255,0.15);
      }
    }
  }
</style>
