import { render, staticRenderFns } from "./ShippingProviders.vue?vue&type=template&id=bbf42e66&scoped=true"
import script from "./ShippingProviders.vue?vue&type=script&lang=js"
export * from "./ShippingProviders.vue?vue&type=script&lang=js"
import style0 from "./ShippingProviders.vue?vue&type=style&index=0&id=bbf42e66&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbf42e66",
  null
  
)

export default component.exports