<template>
  <div class="P-table P-addresses-list">
    <div class="P-table-block">
      <div class="P-table-header">
        <ul class="G-flex">
          <li class="secondary--text" v-for="(items,index) of header" :key="index">{{ items }}</li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <ul class="G-flex" v-for="(items, index) of addressesList" :key="index">
          <li><p :class="{'P-disable-list': items.inUse}" class="secondary--text" v-html="items.name" /></li>
          <li class="G-flex-center"><span v-if="items.inUse" class="mdi mdi-check checkTable ma-0" /></li>
          <li class="G-flex-center"><span v-if="items.isMain" class="mdi mdi-check checkTable ma-0" /></li>
          <li class="G-flex-center">
            <span v-if="items.isForShipping" class="mdi mdi-check checkTable ma-0" />
          </li>
          <li class="G-flex-center">
            <span v-if="items.isBilling" class="mdi mdi-check checkTable ma-0" />
          </li>
          <li class="G-flex-center">
            <span v-if="items.forInvoice" class="mdi mdi-check checkTable ma-0" />
          </li>
          <li class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item 
                    v-for="(action, index) of actions" 
                    :key="index" 
                    class="P-list" 
                    @click="action.name === 'Edit' ? action.clickFunc(items, index) : action.clickFunc(items)"
                  >
                    <v-list-item-title>{{ action.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false" 
      @btnFunction="deleteAddressFunc()"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import WarningModal from "@/components/WarningModal"

export default {
  name: "AddressList",
  components: {
    WarningModal
  },
  data() {
    return {
      header: [
        "Address",
        "Disable",
        "Main",
        "Shipping",
        "Billing",
        "invoice"
      ],
      showWarningModal: false,
      delectedAddressData: {},
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      delectedAddress: "",
      actions: [
        {
          name: 'Edit',
          clickFunc: this.editItem
        },
        {
          name: 'Delete',
          clickFunc: this.openDeleteModal
        }
      ]
    };
  },
  computed: {
    ...mapState({
      addressesList: state => state.companyAddressesAdmin.addressesList,
      manageAdminModule: state => state.manageAdminModule
    }),

    warningModalHeading() {
      return `Delete company address?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> company address <br> <ins>${this.delectedAddress}</ins>. If you're not sure, you can cancel this operation.`
    },
  },
  methods: {
    ...mapActions({
      deleteCompanyAddress: 'companyAddressesAdmin/deleteCompanyAddress',
      getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses'
    }),

    ...mapMutations({
      updateAddressList: 'companyAddressesAdmin/updateAddressList',
      deleteAddressMutation: 'companyAddressesAdmin/deleteAddress',
      editAddress: 'companyAddressesAdmin/editAddress',
    }),

    async deleteAddress(item) {
      await this.deleteCompanyAddress(item.id).then(async ()=>{
        await this.getCompanyAddresses(this.manageAdminModule.providerId).then(data => {
          this.updateAddressList(data)
          this.deleteAddressMutation();
        })
      })
    },
    async deleteAddressFunc() {
      this.warningModalIsLoading = true;
      await this.deleteAddress(this.delectedAddressData).finally(() => {
        this.warningModalIsLoading = false;
      });
      this.showWarningModal = false;
    },   
    openDeleteModal(e) {
      this.delectedAddressData = e
      this.delectedAddress = e.name
      this.warningModalIsLoading = false;
      this.warningModalBtnText = "Delete";
      this.showWarningModal = true;
    },
    editItem(item, index) {
      const body = {
        data: item,
        index
      };
      this.editAddress(body);
    }
  },

};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-table-block {
  width: 100%;
  .P-table-header {
    text-transform: capitalize;
  }

  .P-table-header,
  .P-table-body {
    ul {
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        min-width: 100px;
        max-width: 100%;
        width: 100%;
        padding: 10px;
        text-align: center;
      }
    }
  }
}

.P-addresses-list {
  ul {
    li {
      max-width: 100%;
      width: 100%;
      &:first-child {
        min-width: 300px;
        max-width: 300px;
        text-align: start;
        justify-content: flex-start;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(3) {
        max-width:75px;
        min-width:75px;
      }
      &:nth-child(2),
      &:nth-child(6) {
        max-width: 110px;
        min-width: 110px;
      }

      &:last-child {
        max-width: 40px;
        min-width: 40px;
        padding: 0;
      }
    }
  }
}

.P-table-block {
  box-shadow: 0 0 5px #284e6626;
  border-radius: 3px;

  .P-table-header {
    li {
      font-size: $txt12;
      font-weight: bold;
    }
  }

  .P-table-body {
    ul {
      &:hover {
        li {
          color: $primary-color;
        }
        .P-actions {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    li {
      border-top: 1px solid $neutral-color;
      font-size: $txt14;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        margin-bottom: 0;
      }

    }
  }
}

.checkTable {
  color: $green;
}

.P-addresses-list {
  margin-top: 20px;
}
.P-list {
  cursor: pointer;
}
.theme--dark {
  .P-table {
    background-color: rgba(48, 49, 52, 1);
  }
  &.v-list {
    background-color: #3c4043;
  }
  .v-menu__content {
    box-shadow: 0px 0px 20px #00000040;
  }
}
.P-actions {
  opacity: 0;
  visibility: hidden;
}
  .P-disable-list{
    opacity: 0.5;
  }
</style>
