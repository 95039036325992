var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-sidebar-block page-background-side-bar",class:{ 'P-minimize-sidebar': _vm.isMinimize, 'P-full-hide': _vm.isFullHide }},[_c('div',{staticClass:"P-sidebar-header secondary-66"},[_c('div',{staticClass:"P-short-logo",class:{ 'P-show-minimize-logo': _vm.isMinimize },style:({
        backgroundImage:
          'url(' + require('@/assets/images/main/shortLogo.svg') + ')'
      })}),_c('div',{staticClass:"P-main-logo",class:{ 'P-hide-logo': _vm.isMinimize },style:({
        backgroundImage:
          'url(' + require('@/assets/images/main/whiteLogo.svg') + ')'
      })})]),_c('div',{staticClass:"P-sidebar-menu-list"},[_c('div',{staticClass:"P-sidebar-menu"},[_c('ul',[_vm._l((_vm.sideBarList),function(list,index){return [(_vm.permissionIs(list.permissionKey))?_c('li',{key:index,ref:"sidebarList",refInFor:true,class:{ 'P-active-menu': _vm.isActiveRout(list, index) }},[_c('SidebarLink',{attrs:{"item":list,"isOpenSublist":_vm.activeIndex === index,"showTooltip":_vm.isMinimize && !_vm.isFullHide},nativeOn:{"click":function($event){return _vm.toggleShowSubList($event, list, index)}}}),(list.child && list.child.length)?_c('div',{staticClass:"P-menu-sub-list",class:{ 'P-open-sublist': _vm.activeIndex === index }},[_c('ul',[_vm._l((list.child),function(subList,index){return [(_vm.permissionIs(subList.permissionKey) || 
                  (_vm.permissionIs(subList.permissionKey) && subList.href === '/users/list' && !_vm.userData.roleInfo.cashier))?_c('li',{key:index},[(subList.href)?_c('router-link',{staticClass:"white--text",attrs:{"to":subList.href},nativeOn:{"click":function($event){return _vm.selectMenu(subList.href)}}},[_vm._v(_vm._s(subList.title)+" ")]):_vm._e()],1):_vm._e()]})],2)]):_vm._e()],1):_vm._e()]})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenToolbar),expression:"isOpenToolbar"}],ref:"toolbar",staticClass:"P-tooltip secondary-07"},[_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.sidebarTitle))])])]),_c('div',{staticClass:"P-sidebar-footer secondary-66 G-align-center",on:{"click":_vm.toggleMinimize}},[_c('span',{staticClass:"mdi mdi-chevron-left white--text P-arrow-minimize",class:_vm.isMinimize ? 'mdi-chevron-right' : 'mdi-chevron-left'}),_c('p',{staticClass:"white--text"},[_vm._v("Minimize menu")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }