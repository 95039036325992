<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3 class="secondary--text">Payment Gateway</h3>
    </div>
    <div class="P-modal-component-body P-merchant-block">
      <div class="P-full-line G-flex">
        <div class="P-padding-33">
          <OttSelect
            v-model="selectedData.selectAutoPay"
            @emitFunc="openWarningModal('autopay')"
            :items="autopayList"
            :clear="true"
            selected-text="name"
            label="Autopay"
          />
          
          <transition name="fade">
            <ott-input 
              v-if="selectedData.selectAutoPay"
              v-model="selectedData.autopayInterval" 
              :min="0" 
              customInputClass="my-2"
              type="number"
              label="Autopay Interval" 
            />
          </transition>

          <transition name="fade">
            <ott-input 
              v-if="selectedData.selectAutoPay"
              v-model="selectedData.autopayRetryCount" 
              :min="0" 
              type="number"
              label="Autopay Retry Count" 
            />
          </transition>
          
          <!--          @click="getSelectedData(selectedData.selectAutoPay)"-->
          <!--          @emmitClearData="(...args)=>clearData({type:2,...args})"-->
          <!--          @emitInputChange="(...args)=>selectDataValue({type:2,...args})"-->

          <OttSwitch label="Collect fee from client" v-model="isAutopay" />

          <transition name="slide">
            <CustomFee 
              v-if="isAutopay"
              :switchVal="isAutoPayCustom"
              :fixedVal="autoPayFixed"
              :percentVal="autoPayPercent"
              @switchChange="isAutoPayCustom = $event"
              @fixedInput="autoPayFixed = $event"
              @percentInput="autoPayPercent = $event"
              switchLabel="Custom Fee" 
              fixedLabel="Fixed" 
              percentLabel="Percent"
            />
          </transition>
        </div>
        <div class="P-padding-33">
          <OttSelect
            v-model="selectedData.selectCards"
            @emitFunc="openWarningModal('cards')"
            :items="cardList"
            :clear="true"
            label="Cards"
            selected-text="name"
          />
          <OttSwitch label="Collect fee from client" v-model="isCards" />

          <transition name="slide">
            <CustomFee 
              v-if="isCards"
              :switchVal="isCardsCustom"
              :fixedVal="cardsFixed"
              :percentVal="cardsPercent"
              @switchChange="isCardsCustom = $event"
              @fixedInput="cardsFixed = $event"
              @percentInput="cardsPercent = $event"
              switchLabel="Custom Fee" 
              fixedLabel="Fixed" 
              percentLabel="Percent"
            />
          </transition>
        </div>
        <div class="P-padding-33">
          <OttSelect
            v-model="selectedData.selectBank"
            @emitFunc="openWarningModal('bank')"
            :items="bankList"
            :clear="true"
            label="Bank"
            selected-text="name"
          />
          <OttSwitch label="Collect fee from client" v-model="isBank" />

          <transition name="slide">
            <CustomFee 
              v-if="isBank"
              :switchVal="isBankCustom"
              :fixedVal="bankFixed"
              :percentVal="bankPercent"
              @switchChange="isBankCustom = $event"
              @fixedInput="bankFixed = $event"
              @percentInput="bankPercent = $event"
              switchLabel="Custom Fee" 
              fixedLabel="Fixed" 
              percentLabel="Percent" 
            />
          </transition>
        </div>
      </div>

      <div class="G-flex">
        <ott-button-loader
          @onClick="updateData"
          :is-loading="isLoading"
          button-text="Save"
          class-name="primary-sm-btn"
        />
      </div>

      <div class="P-merchant-api">
        <div class="P-modal-component-header G-justify-between">
          <h3 class="secondary--text">API Integration</h3>
        </div>

        <div class="P-list-block">
          <v-expansion-panels accordion class="G-accordion" v-model="accordion">
            <v-expansion-panel 
              v-for="(api, index) of apiIntegrationsData" 
              :key="index"
              :disabled="api.disabled"
              :readonly="api.readonly"
            >
              <v-expansion-panel-header
                :disable-icon-rotate="true"
              >
                <div class="G-accordion-header G-flex G-align-center G-justify-between">
                  <div class="P-image-block G-flex G-align-center">
                    <div
                      v-if="api.logo"
                      class="P-image"
                      :style="{
                        backgroundImage:
                          `url(${require('@/assets/images/api/' + api.logo)})`,
                      }"
                    ></div>
                      
                    <svg v-if="index === 4" class="LogoLockup svelte-d866tb" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3967.7 995.7" width="95" height="28">
                      <path d="M828.4,0H166.2C74.4,0,0,74.4,0,166.2v662.2c0,91.8,74.4,166.2,166.2,166.2h662.2
    c91.8,0,166.2-74.4,166.2-166.2V166.2C994.6,74.4,920.2,0,828.4,0z M813.8,761.3c0,29-23.5,52.5-52.5,52.5h-528
    c-29,0-52.5-23.5-52.5-52.5v-528c0-29,23.5-52.5,52.5-52.5h528c29,0,52.5,23.5,52.5,52.5V761.3z M391.8,632.3
    c-16.7,0-30.1-13.5-30.1-30.2V391.3c0-16.7,13.4-30.3,30.1-30.3h211.1c16.6,0,30.1,13.5,30.1,30.3V602c0,16.7-13.5,30.2-30.1,30.2
    H391.8z M1258.3,617.9h108.6c5.4,61.5,47.1,109.5,131.2,109.5c75.1,0,121.3-37.1,121.3-93.2c0-52.5-36.2-76-101.4-91.4l-84.2-18.1
    c-91.4-19.9-160.2-78.7-160.2-174.7c0-105.9,94.1-178.3,216.3-178.3c129.4,0,212.7,67.9,219.9,168.3h-105
    C1592.3,293,1553.3,265,1490,265c-67,0-113.1,36.2-113.1,82.4s39.8,74.2,108.6,89.6l83.3,18.1c91.4,19.9,153.8,75.1,153.8,171.9
    c0,123.1-92.3,196.4-224.4,196.4C1349.7,823.3,1267.4,742.8,1258.3,617.9z M2111.1,994.6V814.5l7.1-79h-7.1
    c-24.9,56.8-77.2,87.9-148.2,87.9c-114.5,0-199.7-93.2-199.7-236.1c0-142.9,85.2-236.1,199.7-236.1c70.1,0,119.8,32.8,148.2,84.3
    h7.1V360h94.1v634.6H2111.1z M2114.6,587.2c0-91.4-55.9-144.7-124.3-144.7s-124.3,53.3-124.3,144.7c0,91.4,55.9,144.7,124.3,144.7
    S2114.6,678.7,2114.6,587.2z M2287.4,634.3V360h101.2v265.4c0,71.9,34.6,106.5,92.3,106.5c71,0,117.2-50.6,117.2-129.6V360h101.2
    v454.4h-94.1v-94.1h-7.1c-22.2,60.4-71,103-146.4,103C2343.3,823.3,2287.4,754.1,2287.4,634.3z M2760.9,687.5
    c0-85.2,59.5-134.9,165.1-141.1l125.1-8v-35.5c0-42.6-31.1-68.3-86.1-68.3c-50.6,0-80.8,25.7-88.8,62.1h-101.2
    c10.7-92.3,87-145.6,189.9-145.6c116.3,0,187.3,49.7,187.3,145.6v317.7h-94.1v-84.3h-7.1c-21.3,55.9-65.7,93.2-150.9,93.2
    C2818.6,823.3,2760.9,768.3,2760.9,687.5z M3051.2,631.6v-24l-102.1,7.1c-55,3.5-79.9,24-79.9,64.8c0,34.6,28.4,59.5,68.3,59.5
    C3009.5,739,3051.2,692.9,3051.2,631.6z M3227.7,814.5V360h94.1v87h7.1c13.3-59.5,58.6-87,126-87h46.2v91.4h-57.7
    c-65.7,0-114.5,42.6-114.5,123.4v239.6H3227.7z M3964.1,605.9h-345.3c5.3,83.4,63.9,130.5,128.7,130.5c55,0,89.6-22.2,109.2-59.5
    H3957c-27.5,92.3-108.3,146.4-210.4,146.4c-134,0-228.1-100.3-228.1-236.1c0-135.8,96.7-236.1,229-236.1
    c133.1,0,220.1,91.4,220.1,205C3967.7,578.4,3965.9,589.9,3964.1,605.9z M3867.4,535.8c-3.5-63-55.9-105.6-119.8-105.6
                        c-60.4,0-110.9,38.2-123.4,105.6H3867.4z" fill="black" class="svelte-d866tb"></path>
                    </svg>

                    <div class="P-title-block ms-8">
                      <p class="secondary--text">{{ api.title }}</p>
                    </div>
                  </div>
                
                  <div v-if="
                    (api.isValid == true && !api.disabled && index !== 4) || 
                    (api.isValid == true && !api.disabled && index === 4 && 
                    squareData?.isValidApplicationId && 
                    (squareApplicationId && squareSecretKey && squareLocationId))
                  " class="G-flex G-align-center me-6">
                    <span class="status me-2 text-uppercase secondary--text">Active</span>
                    <v-icon class="success--text" medium>mdi-checkbox-marked-circle-outline</v-icon>
                  </div>

                  <template v-if="showAlert">
                    <div 
                      v-if="(api.isValid == false && !api.disabled && index === 0 && (authorizeApiLoginId || authorizeTransactionKey || authorizeCurrentSignatureKey)) ||
                      (api.isValid == false && !api.disabled && index === 2 && (stripeSecretKey || stripePublicKey)) ||
                      (api.isValid == false && !api.disabled && index === 3 && (cloverMerchantId || cloverSecretKey)) ||
                      ((!api.isValid || !squareData.isValidApplicationId) && !api.disabled && index === 4 && (squareApplicationId || squareSecretKey || squareLocationId)) ||
                      ((api.isValid) && !api.disabled && index === 4 && (!squareApplicationId || !squareLocationId))"
                      class="G-flex G-align-center me-6"
                    >
                      <span class="secondary--text status me-2 text-uppercase">Failed</span>
                      <v-icon class="error--text" medium>mdi-cancel</v-icon>
                    </div>
                  </template>
                </div>
                <template v-slot:actions>
                  <span class="mdi mdi-plus neutral--text P-accordion-plus" />
                  <span class="mdi mdi-minus neutral--text P-accordion-minus" />
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <template  v-if="showAlert">
                  <v-alert
                    v-if="(api.isValid == false && !api.disabled && index === 0 && (authorizeApiLoginId || authorizeTransactionKey || authorizeCurrentSignatureKey)) ||
                      (api.isValid == false && !api.disabled && index === 2 && (stripeSecretKey || stripePublicKey)) ||
                      (api.isValid == false && !api.disabled && index === 3 && (cloverMerchantId || cloverSecretKey)) ||
                      ((!api.isValid || !squareData.isValidApplicationId) && !api.disabled && index === 4 && (squareApplicationId || squareSecretKey || squareLocationId)) ||
                      ((api.isValid) && !api.disabled && index === 4 && (!squareApplicationId || !squareLocationId))"
                    type="error"
                  >
                    The provided token is invalid
                  </v-alert>
                </template>
                <div v-if="api.flexInputs.length" class="P-full-line">
                  <div 
                    v-for="(flexInp, flexIndex) of api.flexInputs"
                    :key="flexIndex" 
                    :class="flexInp.class"
                    class="G-flex G-align-center P-input-block"
                  >
                    <div v-if="flexInp.label" class="P-title">
                      <h4>{{ flexInp.label }}</h4>
                    </div>
                    <component 
                      v-model="flexInp.value"
                      :is="flexInp.type === 'text' ? 'OttInput' : 'PasswordInput'"
                      @emitFunc="validateSpace(flexInp.name)"
                      v-bind="{
                        label: flexInp.label,
                      }"
                    />
                    <!-- error: getInpErrors(api.name, flexInp.value, flexInp.name) -->
                  </div>
                </div>
                
                <div v-if="api.inputs && api.inputs.length" class="P-full-line">
                  <div 
                    v-for="(inp, inpIndex) of api.inputs" 
                    :key="inpIndex" 
                    :class="inp.class"
                    class="G-flex G-align-center P-input-block"
                  >
                    <div v-if="inp.label" class="P-title">
                      <h4>{{ inp.label }}</h4>
                    </div>
                    <component 
                      v-model="inp.value"
                      :is="inp.type === 'text' ? 'OttInput' : 'PasswordInput'"
                      @emitFunc="validateSpace(inp.name)"
                      v-bind="{
                        label: inp.label,
                      }"
                    />
                    <!-- error: getInpErrors(api.name, inp.value, inp.name) -->
                  </div>
                </div>

                <div v-if="api.info">
                  <p>{{ api.info }}</p>
                </div>

                <div class="P-full-line P-buttons G-justify-between G-align-center">
                  <div class="P-button-boxes G-align-center">
                    <div class="P-checkbox">
                      <save-reset-buttons
                        :is-loading="isLoading"
                        :right-label="api.rightButtonLabel"
                        :right-click-func="() => api.rightButtonFunc()"
                        :left-click-func="() => api.leftButtonFunc()"
                      />       
                    </div>        
                  </div>
                  <v-tooltip top color="tooltipBgColor">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="G-link-api w-35">
                        <a
                          :href="api.link"
                          class="G-flex-center"
                          target="_blank"
                        >
                          <img
                            :src="require('@/assets/images/api/' + api.icon)"
                            :titile="api.title + ' icon'"
                            alt="svg"
                          />
                        </a>
                      </div>
                    </template>
                    <span>{{ api.linkTitle }}</span>
                  </v-tooltip>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
    <WarningModal 
      v-if="showWarningModal"
      :modalWidth="340"
      :isModalOpen="showWarningModal"
      :isDangerButton="true"
      :isLoading="warningModalIsLoading"
      modalHeading="Warning" 
      modalText="<p>Clients card validation may change on save.</p>"  
      @closeModal="closeWarningModal" 
      @btnFunction="showWarningModal = false"
      btnText="Continue"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import PaymentGatewayMixin from "@/mixins/providers/PaymentGatewayMixin";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import CustomFee from "@/components/CustomFee";
import OttInput from "@/components/vuetifyComponents/OttInput";
import PasswordInput from "@/components/PasswordInput";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import SaveResetButtons from "@/components/SaveResetButtons";
import WarningModal from "@/components/WarningModal";

export default {
  name: "PaymentGatewayAdmin",
  mixins: [ PaymentGatewayMixin ],
  components: { PasswordInput, CustomFee, OttInput, OttSwitch, OttSelect, OttButtonLoader, SaveResetButtons, WarningModal },
  computed: {
    ...mapState({
      merchant: state => state.paymentGatewayAdmin,
      autopayList: state => state.paymentGatewayAdmin.autopayList,
      cardList: state => state.paymentGatewayAdmin.cardList,
      bankList: state => state.paymentGatewayAdmin.bankList,
      selectedData: state => state.paymentGatewayAdmin.selectedData,
      isLoading: state => state.appModule.isLoading,
      addProviderModal: state => state.manageAdminModule,
    }),
  },

  methods: {
    ...mapActions({
      getPaymentSelectList: 'paymentGatewayAdmin/getPaymentSelectList',
      getPaymentGateway: 'paymentGatewayAdmin/getPaymentGateway',
      updatePaymentGateway: 'paymentGatewayAdmin/updatePaymentGateway',
    }),

    ...mapMutations({
      setData: 'paymentGatewayAdmin/setData',
      setDataSecondLevels: 'paymentGatewayAdmin/setDataSecondLevels',
      setIsAutopay: 'paymentGatewayAdmin/setIsAutopay',
      setIsCards: 'paymentGatewayAdmin/setIsCards',
      setIsBank: 'paymentGatewayAdmin/setIsBank',
      setIsEditAuthorize: 'paymentGatewayAdmin/setIsEditAuthorize',
      setIsEditPayPal: 'paymentGatewayAdmin/setIsEditPayPal',
      setIsEditStripe: 'paymentGatewayAdmin/setIsEditStripe',
      setApiLoginId: 'paymentGatewayAdmin/setApiLoginId',
      setTransactionKey: 'paymentGatewayAdmin/setTransactionKey',
      setCurrentSignatureKey: 'paymentGatewayAdmin/setCurrentSignatureKey',
      setUsername: 'paymentGatewayAdmin/setUsername',
      setPassword: 'paymentGatewayAdmin/setPassword',
      setSignature: 'paymentGatewayAdmin/setSignature',
      setPublicKey: 'paymentGatewayAdmin/setPublicKey',
      setSecretKey: 'paymentGatewayAdmin/setSecretKey',
      setCloverMerchantId: 'paymentGatewayAdmin/setCloverMerchantId',
      setCloverSecretKey: 'paymentGatewayAdmin/setCloverSecretKey',
      setSquareApplicationId: 'paymentGatewayAdmin/setSquareApplicationId', //new
      setSquareLocationId: 'paymentGatewayAdmin/setSquareLocationId', //new
      setSquareSecretKey: 'paymentGatewayAdmin/setSquareSecretKey', //new
      setSquareIsValidApplicationId: 'paymentGatewayAdmin/setSquareIsValidApplicationId', //new
      fetchData: 'paymentGatewayAdmin/fetchData',
      getMerchantListDataFromUpdater: 'paymentGatewayAdmin/getMerchantListDataFromUpdater',
      clearMerchantList: 'paymentGatewayAdmin/clearMerchantList',
      setSelectedDataFocus: 'paymentGatewayAdmin/setSelectedDataFocus',
      saveAuthorizeData: 'paymentGatewayAdmin/saveAuthorizeData',
      savePayPalData: 'paymentGatewayAdmin/savePayPalData',
      saveStripeData: 'paymentGatewayAdmin/saveStripeData',
      saveCloverData: 'paymentGatewayAdmin/saveCloverData',
      cancelCloverData: 'paymentGatewayAdmin/cancelCloverData',
      saveSquareData: 'paymentGatewayAdmin/saveSquareData', //new
      cancelSquareData: 'paymentGatewayAdmin/cancelSquareData', //new
      cancelAuthorizeData: 'paymentGatewayAdmin/cancelAuthorizeData',
      cancelPayPalData: 'paymentGatewayAdmin/cancelPayPalData',
      cancelStripeData: 'paymentGatewayAdmin/cancelStripeData',
      toSaveData: 'paymentGatewayAdmin/toSaveData',
      updateMerchant: 'paymentGatewayAdmin/updateMerchant'
    }),
  }
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-merchant-block {
  margin-top: 15px;
}

.P-full-line {
  margin: 0 -10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-padding-33 {
  width: 33.333%;
  padding: 10px;
}

.P-edit-merchant::v-deep,
.P-merchant-block::v-deep {
  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 10px;
  }
}

.P-merchant-api {
  margin-top: 15px;
}

.P-list-block {
  margin-top: 20px;
}

.P-checkbox {
  padding: 10px;
  display: inline-block;
}

.P-buttons {
  margin-top: 10px;
}

.w-35 {
  width: 35px;
  height: 35px;

  & img {
    width: 95%;
    object-fit: contain;
  }
}

::v-deep {
  .P-save-and-reset-block {
    margin-top: 0;
  }
}

.P-input-block {
  .P-title {
    width: 25%;
  }

  .ott-input-cont {
    width: 50%;
  }
}
</style>
