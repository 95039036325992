<template>
  <div class="content-phone-section">
    <div>
      <ott-phone-number
        v-model="phone"
        :error="(validateEmail  || isValidateNumber|| validPhoneNumber  || $v.phone.$error) && showPhoneError"
        @onInput="validate"
        @onChange="showPhoneError = true"
      />
    </div>
    <div class="content-email-checkboxes">
      <ott-checkbox :disabled="inMain || !contactAdminModule.phones.length" label="Disable" v-model="inUse"/>
      <ott-checkbox label="Is main" :disabled="inUse ||isCheckPhone" v-model="inMain"/>
      <ott-checkbox label="Is Mobile" v-model="isMobile"/>
      <ott-checkbox label="Is support" v-model="isSupport"/>
      <ott-checkbox label="For Invoice" v-model="forInvoice"/>
    </div>
    <ErrorMessageContainer
      v-if="contactAdminModule.isErrorPhone || contactAdminModule.isCheckErrorPhone"
      :message="contactAdminModule.errorMessagePhone"
      :styles="{ width: 'max-content' }"
    />
    <save-reset-buttons
      v-if="contactAdminModule.isAddPhone || contactAdminModule.isEditPhone"
      :right-label="!contactAdminModule.isAddEmail ? 'Save' : 'Add'"
      :right-click-func="() => save()"
      :left-click-func="() => cancel()"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import { ContactInfoModel } from "@/models/providers/contactInfoModel";
  import { AddProviderModel } from "@/models/providers/addProviderModel";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
  import allCountry from "@/constants/all-countries";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import SaveResetButtons from "@/components/SaveResetButtons";

  export default {
    name: "PhoneContent",
    components: {ErrorMessageContainer, OttPhoneNumber, OttCheckbox, SaveResetButtons},
    data() {
      return {
        showPhoneError: false,
        validPhoneNumber: false,
        validateEmail: false,
        isCheckPhone: false,
        isValidateNumber: false,
      };
    },

    validations: {
      phone: { required }
    },

    computed: {
      ...mapState({
        contactAdminModule: state => state.contactAdminModule,
        companyInfoAdminModule: state => state.companyInfoAdminModule,
        adminPersonalInfoModule: state => state.personalInfoAdminModule,
        userData: state => state.auth.user,
        addProviderModal: state => state.manageAdminModule,
        isLoading: state => state.appModule.isLoading
      }),

      editablePhoneDataIndex() {
        return this.contactAdminModule.editablePhoneDataIndex
      },

      phone: {
        get() {
          return this.contactAdminModule.phoneForm.phone;
        },
        set(value) {
          this.updatePhoneFormPhone(value);
        }
      },
      inUse: {
        get() {
          return this.contactAdminModule.phoneForm.inUse;
        },
        set(value) {
          this.updatePhoneFormInUse(value);
        }
      },
      inMain: {
        get() {
          return this.contactAdminModule.phoneForm.inMain;
        },
        set(value) {
          this.updatePhoneFormInMain(value);
        }
      },
      isMobile: {
        get() {
          return this.contactAdminModule.phoneForm.isMobile;
        },
        set(value) {
          this.updatePhoneFormIsInfo(value);
        }
      },
      isSupport: {
        get() {
          return this.contactAdminModule.phoneForm.isSupport;
        },
        set(value) {
          this.updatePhoneFormIsSupport(value);
        }
      },

      forInvoice: {
        get() {
          return this.contactAdminModule.phoneForm.forInvoice;
        },
        set(value) {
          this.updatePhoneFormForInvoice(value);
        }
      }
    },

    mounted() {
      if (!this.phone) {
        allCountry.forEach(item => {
          if (item.iso2 === this.userData?.geoInfo?.countryCode?.toUpperCase()) {
            this.updatePhoneFormPhone("+" + item.dialCode);
          }
        });
      }
      this.isCheckPhone = this.getCurrentData()
    },

    methods: {
      ...mapActions({
        validatePhone: 'addProviderModal/validatePhone',
        managePhone: 'contactAdminModule/managePhone',
        addPhone: 'contactAdminModule/addPhone',
        editAdmin: 'manageAdminModule/editAdmin',
        getAdminData: 'manageAdminModule/getAdminData',
      }),

      ...mapMutations({
        updatePhoneFormPhone: 'contactAdminModule/updatePhoneFormPhone',
        updatePhoneFormInUse: 'contactAdminModule/updatePhoneFormInUse',
        updatePhoneFormInMain: 'contactAdminModule/updatePhoneFormInMain',
        updatePhoneFormIsInfo: 'contactAdminModule/updatePhoneFormIsInfo',
        updatePhoneFormIsSupport: 'contactAdminModule/updatePhoneFormIsSupport',
        updatePhoneFormForInvoice: 'contactAdminModule/updatePhoneFormForInvoice',
        setErrorPhone: 'contactAdminModule/setErrorPhone',
        setCheckValidatePhone: 'contactAdminModule/setCheckValidatePhone',
        checkIsMainPhone: 'contactAdminModule/checkIsMainPhone',
        saveEditPhoneForm: 'contactAdminModule/saveEditPhoneForm',
        addPhoneSave: 'contactAdminModule/addPhoneSave',
        cancelEditPhoneForm: 'contactAdminModule/cancelEditPhoneForm',
        addPhoneCancel: 'contactAdminModule/addPhoneCancel',
        setProviderId: 'manageAdminModule/setProviderId',
        setProviderData: 'manageAdminModule/setProviderData',
        updateContactData: 'contactAdminModule/updateContactData',
        updateCompanyInfo: 'companyInfoAdminModule/updateCompanyInfo',
        updatePersonalInfo: 'personalInfoAdminModule/updatePersonalInfo',
      }),

      validate() {
        const [phone, obj] = arguments;
        this.validPhoneNumber = !obj.isValid;
        this.$v.phone.$touch();
        this.validateEmail = false;
        let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
        this.isValidateNumber = !Number(str)
        this.setErrorPhone({
          value: false,
          message: ""
        });
      },
      async validationPhoneNumber() {
        this.$v.phone.$touch();
        let isValidate = false;
        this.validationCheckList();
        isValidate = !this.$v.phone.$error;
        this.setCheckValidatePhone(isValidate);
      },
      validationCheckList() {
        if (!this.contactAdminModule.phones.length) {
          this.updatePhoneFormInMain(true);
        }
      },

      async save() {
        this.showPhoneError = true;
        let isDuplicatedPhoneNumber = false;

        if (this.contactAdminModule.isEditPhone) {
          isDuplicatedPhoneNumber = this.contactAdminModule.phones.some(
              (x, index) =>
                x.phone === this.phone && 
                index !== this.editablePhoneDataIndex
          );
        } else {
          isDuplicatedPhoneNumber = this.contactAdminModule.phones.some((x) => x.phone === this.phone);
        }

        this.validationPhoneNumber()
        this.checkIsMainPhone(this.contactAdminModule.phoneForm);
        if (this.contactAdminModule.validatePhone) {
          if (isDuplicatedPhoneNumber) {
            this.setErrorPhone({
              value: true,
              message: "Duplicate Phone Number"
            });
            this.validateEmail = true

          } else {
            this.validateEmail = false

            if (!this.contactAdminModule.isAddPhone) {
              if (this.addProviderModal.providerId) {
                const phone = new ContactInfoModel()
                let x = phone.managePhone(this.contactAdminModule.phoneForm, this.addProviderModal.providerId, this.contactAdminModule.isAddPhone)
                const body = {
                  id: this.contactAdminModule.phoneForm.id,
                  data: x
                }
                await this.managePhone(body).then(() => {
                  this.saveEditPhoneForm();

                  })
              } else {
                this.saveEditPhoneForm();
              }

            } else {
              if (this.addProviderModal.providerId) {
                const phone = new ContactInfoModel()

                await this.addPhone(phone.managePhone(this.contactAdminModule.phoneForm, this.addProviderModal.providerId, this.contactAdminModule.isAddPhone)).then(async data => {
                  this.addPhoneSave(phone.fetchPhoneNumber(data));
                  await this.updateProviderData()

                  })
              } else {
                this.addPhoneSave();
              }
            }
          }
        }

        const response = await this.getAdminData()
        if (response) {
          this.updateContactData(response);
        }
      },
      async cancel() {
        if (!this.contactAdminModule.isAddPhone) {
          this.cancelEditPhoneForm();
        } else {
          this.addPhoneCancel();
        }

        const response = await this.getAdminData()
        if (response) {
          this.updateContactData(response);
        }
      },

      getCurrentData() {
        let currentIndex = false
        if (!this.contactAdminModule.isAddPhone && this.contactAdminModule.phones) {
          this.contactAdminModule.phones.forEach((item, index) => {
            if (item.phone === this.phone) {
              currentIndex = item.inMain
            }
          })
        }
        return currentIndex
      },
      async updateProviderData() {
        const provider = new AddProviderModel();
        const data = provider.updateData(
          this.companyInfoAdminModule.formData,
          this.contactAdminModule,
          this.adminPersonalInfoModule.companyAddressesForm,
          this.addProviderModal.providerId
        );
        await this.editAdmin(data).then(data => {
          if (data) {
            this.updateData(data);
          }
        });
      },
      updateData(data) {
        this.setProviderId(data.id);
        this.setProviderData(data)
        this.updateContactData(data);
        this.updateCompanyInfo(data);
        this.updatePersonalInfo(data);
      },

    },  
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .content-phone-section {
    & > :nth-child(1) {
      width : 47%;
    }
  }

  .title-cont {
    color         : $secondary-color;
    margin-bottom : 20px;
  }

  .content-email-checkboxes {
    display         : flex;
    justify-content : space-between;
    width           : 63%;
  }

  .content-phone-section::v-deep {
    .v-text-field__details {
      margin-bottom : 0;
    }
  }
</style>
