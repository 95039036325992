<template>
  <ott-dialog
    :width="814"
    :is-open="balanceRefill.isOpenBalanceRefillModal"
    :show-footer="false"
    modal-title="Balance Refill / Refund"
    @resetDataModal="closeBalanceRefillModal"
    @outside="closeBalanceRefillModal"
  >
    <template #modalBody>
      <div class="P-balance-refill-modal" v-if="balanceRefill.activeStep === 1">
        <div class="P-balance-refill-modal__content">
          <title-component title-text="Balance" />
          <v-radio-group v-model="balanceType" @change="changeBalanceType(balanceType)">
            <div class="G-flex P-full-line">
              <div class="P-radio-box">
                <ott-radio
                  label="Refill"
                  :value="balanceRefill.balanceType.refill"
                />
              </div>
              <div v-if="balance > 0" class="P-radio-box">
                <ott-radio
                  label="Refund"
                  :value="balanceRefill.balanceType.refund"
                />
              </div>
            </div>
          </v-radio-group>
          <div class="P-amount-input">
            <ott-input
              v-model.number="amount"
              :error="$v.amount.$error"
              :min="0.1"
              type="number"
              is-percent
              label="Amount"
            />
          </div>
          <title-component title-text="Payment Methods" />
          <div class="P-payment">
            <div class="G-flex">
              <div
                v-for="(item, index) in paymentMethods"
                :key="index"
                :class="
                  selectedPaymentMethod === item.value ? 'active-payment'
                  : paymentMethodsAdmin.disableCheckTab && item.value === 3 && balanceType === 2  || balanceType === 2 && item.value === 3  ? 'disable-payment'
                  : ''
                "
                @click="changePaymentMethod(item.value)"
                class="P-payment__type"
              >
                <span :class="'P-payment__type-icon ' + item.icon"></span>
                <p class="P-payment__type-name">{{ item.name }}</p>
              </div>
            </div>
            <SavedPayment
              v-show="selectedPaymentMethod === 0"   
              ref="savedPayment"
            />
            <CreditCard
              v-if="selectedPaymentMethod === 1"
              :pageIsPayBalance="true"
              ref="creditCard"
            />
            <BankAccount
              v-if="selectedPaymentMethod === 2"
              :pageIsPayBalance="true"
              ref="bankAccount"
            />
            <Check v-show="selectedPaymentMethod === 3" ref="check" />
          </div>
          <!-- <div class="G-grid-column-2">-->
          <!--    <ErrorMessageContainer message="Please choose one payment!" />-->
          <!-- </div>-->
        </div>
        <div class="P-balance-refill-modal__bottom-block G-justify-end">
          <ott-button
            :click="closeBalanceRefillModal"
            text="Cancel"
            class="neutral-md-btn"
            custom-class="P-cancel-btn"
          />
          <ott-button
            v-if="selectedPaymentMethod === 0 && amount > 0 && paymentList.length ||
               amount > 0 && selectedPaymentMethod !== 0
            "
            :click="payFunc"
            :text="payBtnText"
            class="primary-md-btn P-pay-btn"
            icon-after
          />
        </div>

      </div>
      <Confirm v-else />
    </template>
  </ott-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import BalanceRefillAndRefundMixin from "./BalanceRefillAndRefundMixin";
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import TitleComponent from "@/views/clients/components/TitleComponent";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import SavedPayment from "./paymentMethods/SavedPayment";
import CreditCard from "@/components/sidebar/components/manageAdmin/paymentMethodsAdmin/components/CreditCard";
import BankAccount from "@/components/sidebar/components/manageAdmin/paymentMethodsAdmin/components/BankAccount";
import Check from "./paymentMethods/Check";
import Confirm from "./Confrm";

export default {
  name: "BalanceRefillAndRefundModal",
  components: {
    OttDialog,
    TitleComponent,
    OttRadio,
    OttInput,
    OttButton,
    ErrorMessageContainer,
    SavedPayment,
    CreditCard,
    BankAccount,
    Check,
    Confirm,
  },
  mixins: [BalanceRefillAndRefundMixin],
  validations: {
    amount: {
      required,
    },
  },
  computed: {
    ...mapState({
      authProviderId: state => state.auth.user.provider.id,
      selectedPaymentMethod: state =>
        state.balanceRefill.selectedPaymentMethod,
      paymentMethods: state => state.balanceRefill.paymentMethods,
      paymentMethodsAdmin: state => state.paymentMethodsAdmin,
      addProviderModal: state => state.manageAdminModule
    }),

    creditCard() {
      return this.paymentMethodsAdmin.creditCard;
    },

    bankTransfer() {
      return this.paymentMethodsAdmin.bankTransfer;
    },

    anExistingAddress() {
      return this.paymentMethodsAdmin.anExistingAddress;
    },

    bankAccountType() {
      return this.paymentMethodsAdmin.bankAccountType;
    },

    payBtnText() {
      if (this.balanceType === 1) {
        return `Pay ${
          this.amount > 0
            ? "$" + this.amount.toFixed(2)
            : "$" + 0
        }`;
      } else {
        return `Refund ${
          this.amount > 0
            ? "$" + this.amount.toFixed(2)
            : "$" + 0
        }`;
      }
    },
  },

  async created() {
    if(this.authProviderId){
      await Promise.all([
        this.getPaymentDataList(this.authProviderId),
        this.getAdminData(),
        this.getCompanyAddresses(this.authProviderId)
      ])
    }

    if (!this.paymentList.length) {
      this.setData({ disableCheckTab: true })
    }

    this.checkPaymentListExists()
  },

  methods: {
    ...mapActions({
      getPaymentDataList: 'paymentMethodsAdmin/getPaymentDataList',
      getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses',   
      payBalance: "balanceRefill/payBalance",
      getAdminDataAction: "manageAdminModule/getAdminData",
    }),

    ...mapMutations({
      selectPaymentMethod: "balanceRefill/selectPaymentMethod",
      setData: "paymentMethodsAdmin/setData",
      updateCompanyContactData: "contactAdminModule/updateContactData"
    }),

    checkPaymentListExists() {
      if (this.paymentList.length && this.balanceType === 2) {
        let bankAccountExists = this.paymentList.some(item => item.paymentMethod === 1)
        if (bankAccountExists) {
          this.setData({ disableCheckTab: false })
        } else {
          this.setData({ disableCheckTab: true })
        }
      }
    },

    changeBalanceType(type) {
      if (type === 2) {
        this.setData({ disableCheckTab: true })
      } else {
        this.checkPaymentListExists()
      }

      if (type === 2 && this.selectedPaymentMethod === 3) {
        this.selectPaymentMethod(0)
      }
    },

    changePaymentMethod(type) {
      if (
         !this.paymentMethodsAdmin.disableCheckTab ||
         type === 0 || type === 1 || type === 2 ||
         type === 3 && this.balanceType === 1
      ) {
        this.selectPaymentMethod(type);
      }
    },

    async payFunc() {
      this.$v.amount.$touch();

      if (this.selectedPaymentMethod === 0 && !this.$v.amount.$error) {
        await this.payRefund();
      } else if (this.selectedPaymentMethod === 1 && !this.$v.amount.$error) {
        this.$refs.creditCard.$v.creditCard.$touch();

        if (!this.$refs.creditCard.$v.creditCard.$error) {
          await this.payRefund();
        }
      } else if (this.selectedPaymentMethod === 2 && !this.$v.amount.$error) {
        this.$refs.bankAccount.$v.bankTransfer.$touch();

        if (!this.$refs.bankAccount.$v.bankTransfer.$error) {
          await this.payRefund();
        }
      } else if (this.selectedPaymentMethod === 3 && !this.$v.amount.$error) {
        this.$refs.check.$v.checkNumber.$touch();
        if (!this.$refs.check.$v.checkNumber.$error) {
          await this.payRefund();
        }
      }
    },

    async payRefund() {
      // get data for pay balance
      const payData = {};
      let cardCreditExpireDate = JSON.parse(
        JSON.stringify(this.creditCard.expireDate)
      );

      payData.action = this.balanceType;
      payData.amount = this.amount;
      payData.paymentMethod = this.balanceRefill.selectedPaymentMethod;
     
      switch (this.balanceRefill.selectedPaymentMethod) {
        case 0:
          payData.paymentMethodId = this.$refs.savedPayment.$refs.paymentList.selectedCreditCard;
        break;

        case 1:
          payData.creditCard = {
            cardNumber: this.creditCard.cardNumber,
            cardholderName: this.creditCard.cardholderName,
            cvc: this.creditCard.cvc,
            month: cardCreditExpireDate.split(" / ")[0],
            save: this.creditCard.save ? this.creditCard.save : false,
            year: cardCreditExpireDate.split(" / ")[1],
            brand: this.creditCard.brand,
            anExistingAddress: this.anExistingAddress,
          };

          if (this.anExistingAddress) {
            payData.creditCard.existingAddress = this.creditCard.selectedAddress;
          } else {
            payData.creditCard.billingAddress = {
              phone: this.creditCard.phone,
              address: this.creditCard.address,
              country: this.creditCard.country,
              city: this.creditCard.city,
              suite: this.creditCard.suite,
              zip: this.creditCard.zip,
              state: this.creditCard.state,
            };
          }

          break;
        case 2:
          payData.bankTransfer = {
            save: this.bankTransfer.save ? this.bankTransfer.save : false,
            bankName: this.bankTransfer.bankName,
            routingNumber: this.bankTransfer.routingNumber,
            accountNumber: this.bankTransfer.accountNumber,
            account: this.bankTransfer.accountNumber,
          };

          if (this.bankTransfer.personal) {
            payData.bankTransfer.firstname = this.bankTransfer.firsname;
            payData.bankTransfer.lastname = this.bankTransfer.lastname;
            payData.bankTransfer.nickname = this.bankTransfer.nickname;
          } else {
            payData.bankTransfer.companyName = this.bankTransfer.companyName;
          }
          break;
        case 3:
          payData.checkNumber = this.balanceRefill.checkNumber;
          payData.mailByCheckeeper = this.balanceRefill.mailByCheckeeper;
          break;
      }

      await this.payBalance(payData).then(async (r) => {
        if (r !== undefined) {
          if (r.state === 1) {
            await this.getAdminData();
            this.$refs.check.changeActiveStep(2);
          }  
        }        
      });
    },

    async getAdminData() {
      await this.getAdminDataAction().then((data) => {
        //get company's data
        if (Object.keys(data).length) {
          this.updateCompanyContactData(data)
          this.companyContactSetData({
            emails: data.emails
          })
          if (data.credits) {
            this.credit = data.credits.amount;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "balanceRefillAndRefund";
.P-balance-refill-modal {
  &__content {
    min-height: 758px;
    padding: 20px 24px;
    .P-amount-input {
      max-width: 240px;
      margin: 16px 0 16px;
    }
  }
  &__bottom-block {
    border: 1px solid $neutral-color;
    padding: 24px;
    .P-pay-btn {
      margin-left: 22px;
    }
  }
}
.P-payment {
  margin-top: 16px;
  &__type {
    background-color: $white;
    box-shadow: 0 0 5px #00000012;
    border-radius: 4px;
    max-width: 127px;
    width: 100%;
    padding: 16px 14px;
    margin: 0 10px 16px;
    text-align: center;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &.active-payment {
      border: 1px solid $primary-color;
      .P-payment__type-icon {
        color: $primary-color;
      }
    }
    &.disable-payment {
      cursor: not-allowed;
      background-color: #F8F8FA;
      p {
        color: $neutral-color;
      }
    }
    &-icon {
      font-size: $txt25;
      color: $neutral-color;
    }
    &-name {
      font-size: $txt14;
      font-weight: $semiBold;
      color: $secondary-color;
      margin: 5px 0 0;
    }
  }
}
.error-massage-cont {
  margin-top: 22px;
}
</style>
