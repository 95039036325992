import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      isOpenColorPicker: false,
      selectedColor: "#0A3C68",
      errorLogo: false
    };
  },

  validations: {
    designData: {
      template: {required},
      invoiceTitle: {required},
      quoteTitle: {required},
      creditNoteTitle: {required},
    }
  },

  computed: {
    ...mapState({
      invoice: state => state.invoiceAdmin,
      designData: state => state.invoiceAdmin.designData,
      addProviderModal: state => state.manageAdminModule,
      isLoading: state => state.appModule.isLoading
    }),

    color: {
      get() {
        return this.invoice.designData.color;
      },
      set(value) {
        this.setColor(value);
      }
    },
    
    template: {
      get() {
        return this.invoice.designData.template;
      },
      set(value) {
        this.setInvoiceTemplate(value);
      }
    },
    
    invoiceTitle: {
      get() {
        return this.invoice.designData.invoiceTitle;
      },
      set(value) {
        this.setInvoiceTitle( value);
      }
    },
    
    quoteTitle: {
      get() {
        return this.invoice.designData.quoteTitle;
      },
      set(value) {
        this.setQuoteTitle(value);
      }
    },
    
    creditNoteTitle: {
      get() {
        return this.invoice.designData.creditNoteTitle;
      },
      set(value) {
        this.setCreditNoteTitle(value);
      }
    },
    
    imageFile: {
      get() {
        return this.invoice.imageFile;
      },
      set(value) {
        this.setImageFile(value);
      }
    }
  },
  mounted() {
    if (this.invoice.imagePath) {
      this.setIsEditInvoiceDesign();
    }
  },
  methods: {
    ...mapActions({
      uploadImage: 'fileUpload/uploadImage',
      deleteImage: "fileUpload/deleteImage",
      manageInvoiceData: 'invoiceAdmin/manageInvoiceData',
    }),

    ...mapMutations({
      setIsEditInvoiceDesign: 'invoiceAdmin/setIsEditInvoiceDesign',
      setColor: 'invoiceAdmin/setColor',
      validationLogoPath: 'invoiceAdmin/validationLogoPath',
      generateInvoiceImage: 'invoiceAdmin/generateInvoiceImage',
      setLogo: 'invoiceAdmin/setLogo',
      resetDataDesign: 'invoiceAdmin/resetDataDesign',
      setImageFile: 'invoiceAdmin/setImageFile',
      setImagePath: 'invoiceAdmin/setImagePath',
      setInvoiceTemplate: 'invoiceAdmin/setInvoiceTemplate',
      setInvoiceTitle: 'invoiceAdmin/setInvoiceTitle',
      setQuoteTitle: 'invoiceAdmin/setQuoteTitle',
      setCreditNoteTitle: 'invoiceAdmin/setCreditNoteTitle',
    }),

    changeColor(color) {
      this.setColor(color);
    },
    closePicker() {
      this.isOpenColorPicker = false;
    },
    openColorPicker(e) {
      this.isOpenColorPicker = true;
      e.stopPropagation();
    },

    updateColorData(value) {
      this.setColor(value.hex);
      this.selectedColor = value.hex
    },
    async saveSettings() {
      this.validationLogoPath();
      this.generateInvoiceImage(this.addProviderModal.providerId)
      this.$v.designData.$touch();

      if (!this.$v.designData.$error && !this.invoice.validationLogo) {
        this.errorLogo = false
        this.setIsEditInvoiceDesign();
       
        const body = {
          providerId: this.addProviderModal.providerId,
        }

        if (this.imageFile) {
          const imageData = await this.uploadImage(this.imageFile)
          if (imageData) {
            this.setLogo(imageData.id)
            body.data = {
              design: {...this.designData}
            }
            await this.manageInvoiceData(body).then(() => {
              this.setIsEditInvoiceDesign();
              this.generateInvoiceImage(this.addProviderModal.providerId)
            })
          }
          
        } else {
          this.setLogo(this.invoice.designData.logo)
          body.data = {
            design: {...this.designData}
          }
          await this.manageInvoiceData(body).then(()=>{
            this.setIsEditInvoiceDesign();
            this.generateInvoiceImage(this.addProviderModal.providerId)
          })
        }

      } else {
        if (!this.$v.designData.$error && !this.invoice.imagePath) {
          this.errorLogo = true
        }
      }
    },
    resetData() {
      this.selectedColor = "#0A3C68";
      this.resetDataDesign();
      this.setColor(this.selectedColor);
    },
    openUploadFile() {
      this.$refs["uploadFile"].click();
    },
    async uploadFile(data) {
      this.setImageFile(data.target.files[0]);
      let reader = new FileReader();
      reader.onload = e => {
        this.setImagePath(e.target.result);
      };
      reader.readAsDataURL(data.target.files[0]);
    }
  },
};
