<template>
  <div class="inv-wrap">
    <div class=" inv-wrap_actions">
      <div class="inv-wrap_actions_in">
        <div class="inv-wrap_title secondary--text">Logo upload</div>
        <div class="inv-wrap_upload">
          <div class="G-flex flex-column align-start">
            <div class="inv-wrap_upload_left" :class="{'mb-3': !invoice.imagePath && errorLogo}">
            <label>
              <input
                @change="uploadFile"
                type="file"
                ref="uploadFile"
                accept="image/png, image/gif, image/jpeg"
              />
              <ott-button
                :click="openUploadFile"
                icon-before
                text="Choose a file"
                icon-before-class="P-icon-printer icon-tray-arrow-up"
                custom-class="neutral-sm-btn"
              />
            </label>
            </div>
            <error-message-container v-if="!invoice.imagePath && errorLogo" message="You must upload a logo"/>
          </div>
          <div class="inv-wrap_upload_right" v-if="invoice.imagePath">
            <img :src="invoice.imagePath" alt="Logo"/>
          </div>
        </div>
      </div>
      <div class="inv-wrap_actions_in">
        <div class="inv-wrap_title secondary--text">Color scheme</div>
        <div class="inv-wrap_colors">
          <div
            @click="changeColor('#FF9100')"
            class="inv-wrap_colors_in"
            style="color: #FF9100"
          >
            <span
              v-if="invoice.designData.color === '#FF9100'"
              class="mdi mdi-check"
            />
          </div>
          <div
            @click="changeColor('#5FC788')"
            class="inv-wrap_colors_in"
            style="color: #5FC788"
          >
            <span
              v-if="invoice.designData.color === '#5FC788'"
              class="mdi mdi-check"
            />
          </div>
          <div
            @click="changeColor('#FF5B5B')"
            class="inv-wrap_colors_in"
            style="color: #FF5B5B"
          >
            <span
              v-if="invoice.designData.color === '#FF5B5B'"
              class="mdi mdi-check"
            />
          </div>
          <div
            @click="changeColor('#5BB9FF')"
            class="inv-wrap_colors_in"
            style="color: #5BB9FF"
          >
            <span
              v-if="invoice.designData.color === '#5BB9FF'"
              class="mdi mdi-check"
            />
          </div>
          <div
            v-click-outside="closePicker"
            :style="{ color: selectedColor }"
            @click="openColorPicker($event)"
            class="inv-wrap_colors_in P-color-picker"
            style="color: #0A3C68"
          >
            <span
              v-if="selectedColor === invoice.designData.color"
              class="mdi mdi-check"
            />
            <div class="inv-wrap_colors_text secondary--text">
              {{ selectedColor }}
            </div>

            <OttColorPickerNew
              v-show="isOpenColorPicker"
              :height="230" :width="180"
              :hide-alpha="true"
              :hide-hex="true"
              :hide-preview="true"
              :hide-colors-list="true"
              :color="selectedColor"
              @changeColor="updateColorData"
            />

          </div>
        </div>
      </div>
      <div class="inv-wrap_actions_in">
        <div class="inv-wrap_form">
          <ott-select
            v-model="designData.template"
            :items="invoice.templates"
            :error="$v.designData.template.$error"
            selected-text="name"
            label="Invoice Template*"
          />
          <ott-input
            v-model="designData.invoiceTitle"
            :error="$v.designData.invoiceTitle.$error"
            label="Invoice title*"
          />
          <ott-input
            v-model="designData.quoteTitle"
            :error="$v.designData.quoteTitle.$error"
            label="Quote title*"
          />
          <ott-input
            v-model="designData.creditNoteTitle"
            :error="$v.designData.creditNoteTitle.$error"
            label="Credit note title*"
          />

          <div class="G-flex">
            <ott-button-loader
              v-model="color"
              :is-loading="isLoading"
              :button-text="invoice.isEditDesign ? 'Save' : 'Add'"
              @onClick="saveSettings"
              class-name="primary-sm-btn"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttColorPicker from "@/components/vuetifyComponents/OttColorPicker";
import invoiceDesignMixin from "./invoiceDesignMixin";
import OttColorPickerNew from "@/components/vuetifyComponents/OttColorPicker/color/Index";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader"

export default {
  name: "InvoiceDesignManage",
  components: {
      OttColorPickerNew,
      OttColorPicker,
      OttButton,
      OttInput,
      OttSelect,
      ErrorMessageContainer,
      OttButtonLoader
  },
  mixins: [invoiceDesignMixin]
};
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-color-picker {
    position : relative;

    span {
      margin : -1px;
    }
  }

  .inv-wrap {
    position      : relative;
    padding-right : 15px;

    &_buttons {
      position      : absolute;
      bottom        : 100%;
      right         : 0;
      margin-bottom : 20px;
      z-index       : 1;
    }

    &_actions {
      width : 100%;

      &_in {
        margin-bottom : 24px;
      }
    }

    &_title {
      font-size      : $txt14;
      line-height    : 1;
      margin-bottom  : 20px;
      font-weight    : $semiBold;
      text-transform : capitalize;
    }

    &_upload {
      display         : flex;
      justify-content : space-between;
      align-items     : stretch;

      &_left {
        flex : 0 1 auto;
      }

      &_right {
        display         : flex;
        justify-content : center;
        align-items     : center;

        img {
          display    : block;
          width      : auto;
          max-height : 38px;
        }
      }
    }

    &_colors {
      display         : flex;
      justify-content : space-between;
      align-items     : center;

      &_in {
        position      : relative;
        --inv-color-w : 28px;
        min-width     : var(--inv-color-w);
        flex          : 0 1 var(--inv-color-w);
        border-radius : $border-radius;
        cursor        : pointer;

        &:before {
          content       : "";
          display       : block;
          width         : var(--inv-color-w);
          height        : var(--inv-color-w);
          flex          : 0 1 var(--inv-color-w);
          border-radius : inherit;
          background    : currentColor;
        }

        .mdi-check {
          position    : absolute;
          left        : 0;
          top         : 0;
          width       : var(--inv-color-w);
          height      : var(--inv-color-w);
          line-height : var(--inv-color-w);
          text-align  : center;
          color       : #fff;
          font-size   : 20px;
          z-index     : 1;
        }

        &.P-color-picker {
          --inv-color-w   : 28px;
          flex            : 0 1 138px;
          display         : flex;
          justify-content : flex-start;
          align-items     : center;
          border          : 1px solid $neutral-color;

          &:before {
            border-radius : $border-radius - 1px;
          }
        }
      }

      &_text {
        padding-left : 10px;
        flex         : 1;
      }
    }

    width         : calc(100% - 455px);
  }

  .inv-wrap_upload_left {
    input {
      display : none;
    }
  }

  .P-color-picker:before {
    margin : -1px;
  }
</style>
