<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header">
      <h3 class="secondary--text">User Roles</h3>
    </div>
    <div class="P-modal-component-body">
      <div class="P-label-list">
        <div class="P-table-header">
          <ul class="G-flex">
            <li class="secondary--text">Roles</li>
            <li class="secondary--text G-justify-center">Off/On</li>
          </ul>
        </div>
        <div class="P-table-body">
          <ul
            v-for="(item, index) of roleUser.roleList"
            :key="index"
            class="G-flex"
          >
            <li class="secondary--text P-role-name" v-if="item.name === 'advancedCashier'">{{ 'Advanced Cashier' }}</li>
            <li class="secondary--text P-role-name" v-else-if="item.name === 'equipmentInstaller'">{{ 'Equipment Installer' }}</li>
            <li class="secondary--text P-role-name" v-else>{{ item.name }}</li>
            <li class="secondary--text G-justify-center">             
              <ott-switch v-model="item.value" />
              <!-- @emitFunction="selectRole(item,index)" :value="roleUser.selectedRoleList.includes(item.id)" -->
            </li>
          </ul>
        </div>
      </div>

      <div class="P-error-message">
        <ErrorMessageContainer
          message="You must select some roles for user"
          v-if="!isSelectedRole"
        />
      </div>
    </div>

    <div class="P-save-btn-block G-flex" v-if="users.userId">
      <ott-button-loader
          @onClick="$emit('editUser')"
          :isLoading="isLoading"
          button-text="Save"
          class-name="primary-sm-btn"
      />
    </div>
  </div>
</template>
<script>
import RoleMixin from "./RoleMixin";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "Role",
  components: { ErrorMessageContainer, OttSwitch, OttButtonLoader },
  data(){
    return {
      inp: false
    }
  },
  mixins: [RoleMixin],
};
</script>

<style lang="scss" scoped>
@import "./index";
.P-error-message {
  display: flex;
  justify-content: flex-start;
}
.P-table-header {
  ul {
    li {
      font-size: $txt12;
      font-weight: bold;
      padding: 10px 17px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  }
}
.P-table-body {
  ul {
    li {
      font-size: $txt14;
      word-break: break-all;
      padding: 12px 17px;
      transition: $transition;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }
    border-top: 1px solid $neutral-color45;
  }
}
.P-label-list {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid $neutral-color45;
  margin-bottom: 20px;

  ul {
    padding: 0;
    li {
      max-width: 100%;
      width: 100%;
      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }
  }

  li {
    &:first-child {
      min-width: 280px;
    }
    &:last-child {
      max-width: 150px;
    }
  }
}
.P-table-body::v-deep {
  .v-input--switch {
    margin-top: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-messages {
    display: none;
  }
}

.P-role-name {
  text-transform: capitalize;
}
</style>