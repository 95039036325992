import { mapActions, mapMutations, mapState } from "vuex";
import { AddProviderModel } from "@/models/providers/addProviderModel";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";

export default {
  components: {
    OttButton,
    OttDialog
  },
  computed: {
    ...mapState({
      manageAdminModule: state => state.manageAdminModule,
      companyInfo: state => state.companyInfoAdminModule,
      contactModule: state => state.contactAdminModule,
      adminPersonalInfoModule: state => state.personalInfoAdminModule,
      isValidatePersonalInfo: state =>
        state.personalInfoAdminModule.isValidatePersonalInfo,
      auth: state => state.auth,
      addProviderModalFilter: state => state.addProviderModal.filter,
      usersActivityFilter: state => state.usersActivityModule.filterData,
      clientsFilter: state => state.clientsModule.filterData,
      billsFilter: state => state.clientBillsModule.filterData,
      transactionFilter: state => state.transactionsModule.filterData,
      discountFilter: state => state.discountModule.filterData,
      isSaved: state => state.appModule.isSaved,
      authProviderId: state => state.auth.user.provider.id,
    })
  },
  data() {
    return {
      isLoading: false
    }
  },
  created() {
    this.getAdminData()
  },
  methods: {
    ...mapMutations({
      changeActiveTab: "manageAdminModule/changeActiveTab",
      toggleOpenModal: "manageAdminModule/toggleOpenModal",
      setProviderData: "manageAdminModule/setProviderData",
      setProviderId: "manageAdminModule/setProviderId",   
      updateContactData: "contactAdminModule/updateContactData",
      updateCompanyInfo: "companyInfoAdminModule/updateCompanyInfo",
      updatePersonalInfo: "personalInfoAdminModule/updatePersonalInfo",
      updateAddressList: "companyAddressesAdmin/updateAddressList", 
      manageAdminSetData: "manageAdminModule/setData",
      //resets     
      companyInfoResetData: "companyInfoAdminModule/resetData",
      setData: "companyInfoAdminModule/setData",
      resetActiveTabs: "manageAdminModule/resetActiveTabs",
      contactReset: "contactAdminModule/resetContactForm",
      personalInfoReset: "personalInfoAdminModule/resetData",
      addressessReset: "companyAddressesAdmin/resetAllData",
      infoForClientsReset: "infoForClientsAppsAdmin/resetData",
      invoicesReset: "invoiceAdmin/resetAllData",
      labelPrintersReset: "labelPrintersAdmin/resetDataClose",
      otherApiIntegrationReset: "otherApiIntegrationAdmin/resetData",
      paymentGatewayReset: "paymentGatewayAdmin/resetData",
      paymentMethodsReset: "paymentMethodsAdmin/resetData",
      priceGroupResetData: "priceGroupAdmin/closeData",
      saleTaxesResetData: "saleTaxesAdmin/resetDataClose",
      shippingProvidersReset: "shippingProvidersAdmin/resetData",
      uiAndAccessReset: "uiAndAccessAdmin/resetData",
      changePriceGroupTab: "priceGroupAdmin/ChangePriceGroup"
    }),
    ...mapActions({
      getAdmin: 'manageAdminModule/getAdminData',
      getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses',
      editAdmin: 'manageAdminModule/editAdmin',
      getProvidersList: 'addProviderModal/getProvidersList',
      getUsersList: 'users/getUsersList',
      getUsersActivityList: "usersActivityModule/getUsersActivityList",
      getClientList: 'clientsModule/getClientList',
      getBillsList: 'clientBillsModule/getBillsList',
      getTransactionList: 'transactionsModule/getTransactionList',
      getDiscountList: 'discountModule/getDiscountList',

    }),

    async clickList(index) {
      // Before changing the tab, getting admin data, to show correct data, and auto typed all removed and not saved inputs
      await this.getAdminData(index)
      this.changeActiveTab(index)
    },

    /**
     * Close Modal
     * **/

    async closeModal() {
      this.companyInfoResetData();
      this.resetActiveTabs();
      this.contactReset()
      this.personalInfoReset();
      this.addressessReset();
      this.infoForClientsReset();
      this.invoicesReset();
      this.labelPrintersReset();
      this.otherApiIntegrationReset();
      this.paymentGatewayReset();
      this.paymentMethodsReset();
      this.priceGroupResetData();
      this.saleTaxesResetData();
      this.shippingProvidersReset();
      this.uiAndAccessReset();
      this.changePriceGroupTab(1);
      this.manageAdminSetData({ parentId: null })
      this.toggleOpenModal(false)
    },

    async getAdminData(tabIndex = null) {
      this.setProviderId(this.auth.user.provider.id)
      await this.getAdmin(this.auth.user.provider.id).then(data => {
        if (data) {
          this.updateData(data)
          if (tabIndex === 4 || tabIndex === 5 || tabIndex === 8 || tabIndex === 10 || tabIndex === 12)
          this.getCompanyAddresses(this.manageAdminModule.providerId).then(data => {
            this.updateAddressList(data)
          })
        }
      })
    },

    updateData(data) {
      this.setProviderData(data);
      this.updateContactData(data);
      this.updateCompanyInfo(data);
      this.updatePersonalInfo(data);
    },

    async updateAdminMainTabs() {
      const provider = new AddProviderModel();
      const data = provider.updateData(
        this.companyInfo.formData,
        this.contactModule,
        this.adminPersonalInfoModule.companyAddressesForm,
        this.manageAdminModule.providerId
      );

     await this.editAdmin(data).then(data => {
        if (data) {
          this.updateData(data);
          this.setData({ isLoading: false })
        }
      });
    },

    async saveCompanyInfo() {
      this.setData({ isLoading: true })
      await this.updateAdminMainTabs()
      await this.getAdmin(this.auth.user.provider.id)
    }
  },
}
