<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header">
      <h3 class="secondary--text">Profile Photo</h3>
    </div>
    <div class="P-modal-component-body">
      <div class="P-upload-image-block G-flex G-align-center">
        <div
          class="P-image-crop-value"
          :class="{'P-active-image':generalInfoUser.imagePath}"
          :style="{backgroundImage: `url('${generalInfoUser.imagePath ? generalInfoUser.imagePath : ''}')`}"
        >
          <svg v-if="!generalInfoUser.imagePath" viewBox="0 0 100 100" >
            <circle cx="50" cy="50" r="48" stroke-dasharray="8, 10" fill="transparent" stroke-width="1"/>
          </svg>
          <EmptyImage v-if="!generalInfoUser.imagePath" />
          <ImageSettings v-else @changeImage="selectImage" @removeImage="removeImage" />
        </div>
        <div class="P-upload-image-label" >
          <label>
            <input
              @change="uploadFile"
              type="file"
              ref="uploadFile"
              accept=".png, .jpeg, .jpg"
            />
            <ott-button
              v-if="!generalInfoUser.imagePath"
              :click="selectImage"
              icon-before
              text="Upload A Photo"
              icon-before-class="P-icon-printer icon-tray-arrow-up"
              custom-class="neutral-sm-btn P-upload-photo-btn"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="P-modal-component-header G-align-center">
      <h3 class="secondary--text me-3">Basic Information</h3>
      <div class="position-relative color-cont G-justify-end">
        <div
          @click.stop="colorClick"
          :style="{ backgroundColor:
                typeof color === 'string' ? color : color?.hexa, }"
          class="color-cont__color-icon d-flex cursor-pointer"
        >
          <img
            v-if="!color"
            src="@/assets/images/empty.png"
            width="100"
          />
        </div>
        <transition name="fade">
          <div v-if="openColorPicker" @mouseleave="openColorPicker = false" class="color-cont__color-picker">
            <v-color-picker
              v-model="color"
              elevation="10"
              dot-size="15"
              mode="hexa"
              show-swatches
              swatches-max-height="120px"
            />
          </div>
        </transition> 
      </div>
    </div>
    <div class="P-full-line G-flex G-flex-wrap">
      <div class="P-padding-50">
        <OttInput
          v-model="firstname"
          :error="$v.formData.firstname.$error"
          label="First Name*"
        />
      </div>
      <div class="P-padding-50">
        <OttInput
          v-model="lastname"
          :error="$v.formData.lastname.$error"
          label="Last Name*"
        />
      </div>
      <div class="P-padding-50">
        <ott-phone-number
          v-model="phone"
          @onInput="validationPhoneNumber"
          @onChange="showPhoneError = true"
          :error="(validPhoneNumber|| isValidateNumber  || $v.formData.phone.$error) && showPhoneError"
        />
      </div>
      <div v-if="users.showRoles" class="P-padding-50">
        <ott-select
          v-model="address"
          :items="addressLists"
          :error="$v.formData.address.$error"
          label="Office/Address*"
          selected-text="name"
        />
      </div>
    </div>
    <div v-if="users.showRoles" class="P-switch">
      <OttSwitch
        v-model="isAccessEnable"
        label="Access Enable"
      />
      <OttSwitch
        v-model="isShowUnsuccessfulTransactions"
        label="Show Unsuccessful Transactions"
      />
    </div>
    <CropAndUploadImage v-if="uploadImageModal.modal.isOpen" @removeImage="removeImage" />
    <div class="P-error-message">
      <ErrorMessageContainer v-if="isValidateEmail" :message="errorMessage" />
    </div>
    <div v-if="users.userId" class="P-save-btn-block G-flex">
      <ott-button-loader
        @onClick="$emit('editUser')"
        :isLoading="isLoading"
        button-text="Save"
        class-name="primary-sm-btn"
      />
    </div>
  </div>
</template>
<script>
  import GeneralInfoMixin from "./GeneralInfoMixin";
  // components
  import CropAndUploadImage from "../uploadImageModal/UploadImageModal";
  import EmptyImage from "./EmptyImage";
  import ImageSettings from "./ImageSettings";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

  export default {
    name: 'GeneralInfo',
    components: { ErrorMessageContainer, ImageSettings, EmptyImage, CropAndUploadImage },
    mixins: [GeneralInfoMixin]
  }
</script>

<style lang="scss" scoped>
  @import "./index";
  @import "src/assets/scss/variables";
  .P-error-message{
    display: flex;
    justify-content: flex-start;
  }

  .color-cont {
   &__color-icon {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid $neutral-color;
  }

  &__color-picker {
    position: absolute;
    z-index: 9;
    left: 35px;
    top: 0;
  }
}
</style>