<template>
  <div class="P-table-block">
    <div class="P-table-header">
      <ul class="G-flex">
        <li
          class="secondary--text"
          v-for="(item, key) of tableHeaders"
          :key="key"
        >
          {{ item }}
        </li>
        <li></li>
      </ul>
    </div>
    <div class="P-table-body">
      <ul v-for="(list, key) of listData" :key="key" class="G-flex">
        <template v-for="(item, keyName, index) of list">
          <li v-if="keyName !== 'id'" :key="index" v-html="item" @click="$emit('listItemClick', keyName)" class="text-capitalize cursor-normal" />
          <li v-if="keyName === 'id'" :key="index" class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(action, key) of actionsList"
                    :key="key"
                    @click="actionClick(action, item)"
                    class="P-list"
                  >
                    <v-list-item-title>{{ action }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListComponent",
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    listData: {
      type: Array,
      default: () => [],
    },
    actionsList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    actionClick(action, id) {
      switch (action) {
        case "Edit":
          this.$emit("editFunc", id);
          break;
        case "Delete":
          this.$emit("deleteFunc", id);
          break;
        case "Return":
          this.$emit("returnFunc", id);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/views/clients/modals/manageClient/simpleTable";

.cursor-normal {
  cursor: default!important;
}
</style>