<template>
  <div class="contact-parent">
    <contact-form
      v-if="
        (contactAdminModule.isSave &&
          !contactAdminModule.isEditEmail &&
          !contactAdminModule.isEditPhone) && (!contactAdminModule.emails.length && !contactAdminModule.phones.length)
      "
    />
    <div
      v-else-if="!contactAdminModule.isEditEmail && !contactAdminModule.isEditPhone && (contactAdminModule.emails.length || contactAdminModule.phones.length)"
      class="all-tables"
    >
      <title-contact
        :is-add-icon="true"
        :click="addEmail"
        text="Email Address"
      />
      <table-content
        v-if="contactAdminModule.emails.length"
        :tableData="contactAdminModule.emails"
        :header="headerEmail"
        name="email"
      />

      <div class="P-table-phone">
        <title-contact
          :is-add-icon="true"
          :click="addPhone"
          text="Phone number"
        />
        <table-content
          v-if="contactAdminModule.phones.length"
          :tableData="contactAdminModule.phones"
          :header="headerPhone"
          name="phone"
        />
      </div>
    </div>
    <div v-if="contactAdminModule.isEditEmail">
      <title-contact
        :text="
          contactAdminModule.isAddEmail ? 'Add Email Address' : 'Edit Email Address'
        "
      />
      <email-content ref="emailForm" />
    </div>
    <div v-if="contactAdminModule.isEditPhone">
      <title-contact
        :text="
          contactAdminModule.isAddPhone ? 'Add Phone Number' : 'Edit Phone Number'
        "
      />
      <phone-content ref="phoneNumberForm" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import TableContent from './TableContent';
import EmailContent from './email/EmailContent';
import ContactForm from './contactForm/ContactForm';
import TitleContact from './components/TitleContact';
import PhoneContent from './phone/PhoneContent';
import OttButton from '@/components/vuetifyComponents/OttButton';

export default {
  name: "ContactAdmin",
  components: {
    TableContent,
    EmailContent,
    ContactForm,
    PhoneContent,
    TitleContact,
    OttButton,
  },

  data() {
    return {
      headerEmail: [
        "Email Addresses",
        "Disable",
        "Main",
        "Info",
        "Support",
        "For invoice"
      ],
      headerPhone: [
        "Phone Numbers",
        "Disable",
        "Main",
        "Mobile",
        "Support",
        "For invoice"
      ],
    };
  },

  computed: {
    ...mapState({
      contactAdminModule: state => state.contactAdminModule,
    })
  },

  methods: {
    ...mapMutations({
      setError: 'contactAdminModule/setError',
      addEmailMutation: 'contactAdminModule/addEmail',
      addPhoneMutation: 'contactAdminModule/addPhone',
    }),

    addEmail() {
      this.setError({
        value: false,
        message: ""
      });
      this.addEmailMutation();
    },
    addPhone() {
      this.addPhoneMutation();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-table-phone {
  margin-top: 25px;
}
</style>
