import { mapState, mapMutations } from "vuex";
import { email, helpers, minLength, required, sameAs, requiredIf } from "vuelidate/lib/validators";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

const letterNumSymbol = helpers.regex(
  "letterNumSymbol",
  /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
);
export default {
  name: 'GeneralInfo',
  components: {OttSwitch, OttPhoneNumber, OttInput, OttButton, OttButtonLoader},

  validations: {
    formData: {
      email: {required, email},
      password: {
        required,
        minLength: minLength(8),
        letterNumSymbol
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
    },
  },
  
  data() {
    return {
      isValidateEmail: false,
      errorMessage: ''
    }
  },

  computed: {
    ...mapState({
      securityLogin: state => state.securityLogin,
      formData: state => state.securityLogin.formData,
      users: state => state.users,
      isLoading: state => state.appModule.isLoading
    }),

    /**
     *  getters and setters for update data security and login
     * **/
    email: {
      get() {
        return this.securityLogin.formData.email;
      },
      set(email) {
        this.isValidateEmail = false
        this.setDataSecondLevels({ 
          formData: { email }
        });
      }
    },
    password: {
      get() {
        return this.securityLogin.formData.password;
      },
      set(value) {
        this.isValidateEmail = false
        this.setDataSecondLevels({ 
          formData: { password: value.trim() }
        });
      }
    },
    confirmPassword: {
      get() {
        return this.securityLogin.formData.confirmPassword;
      },
      set(value) {
        this.isValidateEmail = false
        this.setDataSecondLevels({ 
          formData: { confirmPassword: value.trim() }
        });
      }
    },
  },

  methods: {
    ...mapMutations({
      setDataSecondLevels: 'securityLogin/setDataSecondLevels',
    }),

    async validateSecurityLogin() {
      this.$v.formData.$touch()
      if (!this.$v.formData.$error) {
        const body = {
          email: this.formData.email
        };
        if (this.users.userId) {
          body.userId = this.users.userId
        }

        let x = await this.$store
          .dispatch("users/checkUsersEmail", body).then(data => {
            if (!data) {
              this.isValidateEmail = false;
              return Promise.resolve(true)
            } else {
              this.isValidateEmail = true;
              this.errorMessage = 'The user with the current E-mail is already created.'
              return Promise.resolve(false)
            }
          }, error => {
            this.isValidateEmail = true;
            this.errorMessage = 'Incorrect Email Address'
            return Promise.resolve(false)
          });

        return x
      } else {
        return Promise.resolve(false)
      }
    },
    changePassword() {
      const [value] = arguments
      this.setDataSecondLevels({ 
        formData: { confirmPassword: value.trim() }
      });
    },
  },

}