<template>
  <div class="G-image-invoice">
    <div class="iframe-wrapper">
      <iframe
        id="iframe"
        :srcdoc="invoiceHtml"
        type="application/pdf"
        frameborder="0"
      ></iframe>
    </div>
    <!--    <div class=" inv-wrap_drawer secondary&#45;&#45;text">-->
    <!--      <div class="inv-drawer">-->
    <!--        <div class="inv-drawer_header">-->
    <!--          <div class="inv-drawer_header_left">-->
    <!--            <span>{{ companyInfo.companyName }}</span>-->
    <!--            <span>899 Roy Alley</span>-->
    <!--            <span>Memphis ak 38148</span>-->
    <!--          </div>-->
    <!--          <div class="inv-drawer_header_middle">-->
    <!--            <ul class="G-flex G-align-start">-->
    <!--              <li>-->
    <!--                <span />-->
    <!--                <div class="inv-drawer_header_middle_info">-->
    <!--                  <p>+1 (888) 800-0607</p>-->
    <!--                  <p>MON-SUN 24/7</p>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <span />-->
    <!--                <div class="inv-drawer_header_middle_info">-->
    <!--                  <p>support@ottstream.net</p>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <span />-->
    <!--                <div class="inv-drawer_header_middle_info">-->
    <!--                  <p>http://ottstream.net/</p>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="inv-drawer_header_right">-->
    <!--            <span-->
    <!--              v-if="!invoice.imagePath"-->
    <!--              class="icon-image-multiple-outline"-->
    <!--              :class="{ 'P-error-image': invoice.validationLogo }"-->
    <!--            />-->
    <!--            <img v-else :src="invoice.imagePath" alt="Logo" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="inv-drawer_bill">-->
    <!--          <div class="inv-drawer_bill_left">-->
    <!--            <div-->
    <!--              class="inv-drawer_bill_title inv&#45;&#45;color"-->
    <!--              :style="{ color: invoice.designData.color }"-->
    <!--            >-->
    <!--              Bill To-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_bill_text">-->
    <!--              <span>100 Sample Street</span>-->
    <!--              <span>London</span>-->
    <!--              <span>W1 1AB</span>-->
    <!--              <span>United Kingdom</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="inv-drawer_bill_right">-->
    <!--            <div-->
    <!--              class="inv-drawer_bill_title inv&#45;&#45;color"-->
    <!--              :style="{ color: invoice.designData.color }"-->
    <!--            >-->
    <!--              Invoice-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_bill_table">-->
    <!--              <span>Reference</span>-->
    <!--              <span>0021</span>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_bill_table">-->
    <!--              <span>Date</span>-->
    <!--              <span>30/12/2020</span>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_bill_table">-->
    <!--              <span>Due date</span>-->
    <!--              <span>30/12/2020</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="inv-drawer_table">-->
    <!--          <div-->
    <!--            class="inv-drawer_table_header inv&#45;&#45;color"-->
    <!--            :style="{ borderBottom: '1px solid ' + invoice.designData.color }"-->
    <!--          >-->
    <!--            <div class="inv-drawer_table_row">-->
    <!--              <div :style="{ color: invoice.designData.color }">-->
    <!--                Description-->
    <!--              </div>-->
    <!--              <div :style="{ color: invoice.designData.color }">Quantity</div>-->
    <!--              <div :style="{ color: invoice.designData.color }">Unit price</div>-->
    <!--              <div :style="{ color: invoice.designData.color }">Amount</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div-->
    <!--            class="inv-drawer_table_body"-->
    <!--            :style="{ borderBottom: '1px solid ' + invoice.designData.color }"-->
    <!--          >-->
    <!--            <div class="inv-drawer_table_row">-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_title">Sample Product</div>-->
    <!--                <div class="inv-drawer_table_text">-->
    <!--                  This is a sample product.-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">5</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_table_row">-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_title">Sample Product</div>-->
    <!--                <div class="inv-drawer_table_text">-->
    <!--                  This is a sample product.-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">5</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_table_row">-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_title">Sample Product</div>-->
    <!--                <div class="inv-drawer_table_text">-->
    <!--                  This is a sample product.-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">5</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_table_row">-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_title">Sample Product</div>-->
    <!--                <div class="inv-drawer_table_text">-->
    <!--                  This is a sample product.-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">5</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <div class="inv-drawer_table_text">$99,999,999.00</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="inv-drawer_payment">-->
    <!--          &lt;!&ndash;          <div class="inv-drawer_payment_left">&ndash;&gt;-->
    <!--          &lt;!&ndash;            <div class="inv-drawer_payment_title inv&#45;&#45;color">&ndash;&gt;-->
    <!--          &lt;!&ndash;              Payment Method&ndash;&gt;-->
    <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--          &lt;!&ndash;            <div class="inv-drawer_payment_text">&ndash;&gt;-->
    <!--          &lt;!&ndash;              <span>Credit card</span>&ndash;&gt;-->
    <!--          &lt;!&ndash;              <span>**** **** **** 1234</span>&ndash;&gt;-->
    <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--          <div class="inv-drawer_payment_right">-->
    <!--            <div class="inv-drawer_payment_table">-->
    <!--              <span>Tax: Vat(15%) </span>-->
    <!--              <span>$99,999,999.00</span>-->
    <!--            </div>-->

    <!--            <div class="inv-drawer_payment_table">-->
    <!--              <span>Tax: Vat(15%)</span>-->
    <!--              <span>$99,999,999.00</span>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_payment_table discount">-->
    <!--              <span>Discount (20%)</span>-->
    <!--              <span>$99,999,999.00</span>-->
    <!--            </div>-->
    <!--            <div class="inv-drawer_payment_table total">-->
    <!--              <span>Subtotal</span>-->
    <!--              <span>$99,999,999.00</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "InvoiceDesignImg",
  data() {
    return {
      changeColorCount: 0,
    };
  },
  computed: {
    ...mapState({
      invoice: (state) => state.invoiceAdmin,
      companyInfo: (state) => state.companyInfoAdminModule.companyInfo,
      invoiceHtml: (state) => state.invoiceAdmin.invoiceHtml,
      providerId: (state) => state.auth.user.provider.id,
      designData: (state) => state.invoiceAdmin.designData,
    }),
  },
  mounted() {
    this.getHtml(this.providerId);
  },
  watch: {
    "invoice.designData.color"(newVal, oldVal) {
      this.changeColorCount += 1;
      if (this.changeColorCount === 1) {
        this.replaceHtml({
          type: /#edeef4/gi,
          payload: `${this.invoice.designData.color}`,
        });
      } else {
        this.replaceHtml({
          type: new RegExp(oldVal, "gi"),
          payload: `${this.invoice.designData.color}`,
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getHtml: "invoiceAdmin/getHtml",
    }),
    ...mapMutations({
      replaceHtml: "invoiceAdmin/replaceHtml",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
.iframe-wrapper {
  width: 100%;
  height: 100%;
  iframe {
    width: inherit;
    height: inherit;
  }
}

.G-image-invoice {
  max-width: 450px;
  min-width: 450px;
  padding: 18px;
  min-height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.inv-drawer {
  max-width: 450px;
  min-width: 450px;
  padding: 18px;
  box-shadow: 0 0 5px #284e6629;
  border-radius: $border-radius;
  margin: 0 0 50px 4px;
  --inv-color: #5bb9ff; //here must be selected color
  font-size: 8px;
  line-height: 11px;

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;

    &_left {
      span {
        display: block;
        margin-top: 2px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &_right {
      --inv-img-size: 60px;

      span {
        border: 2px solid transparent;
        display: block;
        width: var(--inv-img-size);
        height: var(--inv-img-size);
        line-height: var(--inv-img-size);
        text-align: center;
        border-radius: $border-radius;
        color: $neutral-color;
        font-size: 28px;
        background: rgba($neutral-color, 0.25);
      }

      img {
        display: block;
        width: auto;
        max-height: var(--inv-img-size);
      }
    }
  }

  &_bill {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &_title {
      font-weight: 700;
      margin-bottom: 3px;
    }

    &_text {
      span {
        display: block;
      }
    }

    &_table {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 5px;

      span {
        display: block;

        &:nth-child(1) {
          width: 46px;
          flex: 0 1 46px;
          margin-right: 5px;
          font-weight: 600;
        }

        &:nth-child(2) {
          flex: 1;
        }
      }
    }
  }

  &_table {
    $self: &;
    margin-bottom: 20px;
    overflow: hidden;

    &_header {
      border-top: 1px solid rgba($neutral-color, 0.45);
      border-bottom: 1px solid var(--inv-color);
      font-weight: 700;

      #{$self}_row {
        > div {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }

    &_body {
      border-bottom: 1px solid var(--inv-color);

      #{$self}_row {
        > div {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }

    &_row {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      margin-left: -($grid-gutter-width/2);
      margin-right: -($grid-gutter-width/2);
      flex-wrap: nowrap;
      border-top: 1px solid rgba($neutral-color, 0.45);

      &:first-child {
        border-top: none;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding-left: 10px;
        padding-right: 10px;

        &:nth-child(1) {
          width: 39.4%;
          flex: 0 1 41.4%;
        }

        &:nth-child(2) {
          width: 19.7%;
          flex: 0 1 19.7%;
        }

        &:nth-child(3) {
          width: 24%;
          flex: 0 1 24%;
        }

        &:nth-child(4) {
          width: 16.9%;
          flex: 0 1 16.9%;
        }
      }
    }

    &_title {
      font-weight: 600;
      margin-bottom: 4px;
    }
  }

  &_payment {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;

    &_title {
      font-weight: 700;
      margin-bottom: 4px;
    }

    &_text {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        display: block;

        &:nth-child(1) {
          font-weight: 600;
          margin-right: 15px;
          flex: 0 1 auto;
        }
      }
    }

    &_table {
      display: flex;
      justify-content: space-between;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }

      &.discount {
        color: $danger-color-light;
      }

      &.total {
        margin-top: 7px;
        padding: 5px 8px;
        background: rgba($neutral-color, 0.25);
        border-radius: $border-radius;
        font-weight: 700;
      }

      span {
        display: block;

        &:nth-child(1) {
          font-weight: 600;
          margin-right: 30px;
        }
      }
    }
  }

  &_terms {
    margin-bottom: 30px;
    &_title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  &_footer {
    color: $danger-color-light;
    font-weight: 700;
  }
}
.P-error-image {
  border: 2px solid $red !important;
}
.inv-drawer_header_middle_info {
  p {
    margin-bottom: 0;
  }
}
.inv-drawer_header_middle {
  ul {
    padding: 0;
    li {
      padding: 0 5px;
    }
  }
}

.theme--dark {
  .inv-drawer {
    background-color: $white;
  }
  .inv-drawer_header_left,
  .inv-drawer_header_middle,
  .inv-drawer_bill,
  .inv-drawer_payment_table {
    span,
    p {
      color: $secondary-color !important;
    }
  }
  .inv-drawer_table_title,
  .inv-drawer_table_text {
    color: $secondary-color !important;
  }
  .inv-drawer_payment_table.discount {
    span {
      color: $danger-color-light !important;
    }
  }
}
</style>
