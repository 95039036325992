<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3
        v-if="!paymentList.length || (addMode && !editMode)"
        class="secondary--text"
      >
        Payment methods
      </h3>
      <h3
        v-if="paymentList.length && !addMode && !editMode"
        class="secondary--text"
      >
        Saved payments
      </h3>
      <h3
        v-if="
          editMode && selectedPaymentType=== paymentType.creditCard
        "
        class="secondary--text"
      >
        Edit credit card
      </h3>
      <h3
        v-if="
          editMode && selectedPaymentType=== paymentType.bankAccount
        "
        class="secondary--text"
      >
        Edit bank account
      </h3>
      <div
        v-if="paymentList.length && !addMode  && !editMode"
        @click="addNewPayment"
        class="icon-cont"
      >
        <span class="mdi mdi-plus neutral--text" />
      </div>
    </div>
    <div class="P-modal-component-body">
      <ManagePayments v-if="!paymentList.length || addMode || editMode" />
      <PaymentList v-else />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ManagePayments from "./ManagePayments";
import PaymentList from "./PaymentList";
export default {
  name: "PaymentMethodsAdmin",
  components: { PaymentList, ManagePayments },
  async created() {
    if(this.addProviderModal.providerId){
      await this.getPaymentDataList(this.addProviderModal.providerId)
      await this.getCompanyAddresses(this.addProviderModal.providerId)
    }
  },

  computed: {
    ...mapState({
      paymentList: state => state.paymentMethodsAdmin.paymentList,
      addMode: state => state.paymentMethodsAdmin.addMode,
      editMode: state => state.paymentMethodsAdmin.editMode,
      paymentType: state => state.paymentMethodsAdmin.paymentType,
      selectedPaymentType: state => state.paymentMethodsAdmin.selectedPaymentType,
      addProviderModal: state => state.manageAdminModule
    })
  },

  methods: {
    ...mapActions({
      getPaymentDataList: 'paymentMethodsAdmin/getPaymentDataList',
      getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses'
    }),

    ...mapMutations({
      addNewPaymentMethod: 'paymentMethodsAdmin/addNewPaymentMethod'
    }),

    addNewPayment() {
      this.addNewPaymentMethod();
    }
  },

};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}
</style>
