<template>
  <div class="title-cont">
    <h4 class="secondary--text P-title">{{ text }}</h4>
    <div v-if="isAddIcon" class="icon-cont" @click="click">
      <span :class="iconClass" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleContact",
  props: {
    iconClass: {
      type: String,
      default: "mdi mdi-plus neutral--text"
    },
    click: {
      type: Function,
      default: () => null
    },
    isAddIcon: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.title-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: $secondary-color;
}
.P-title {
  font-size: $txt16;
  font-weight: $semiBold;
  text-transform: capitalize;
}
</style>
