<template>
  <div id="sidebarHeader" class="P-sidebar-header page-background">
    <div class="P-header-top d-flex align-center justify-space-between">
      <div class="P-header-left G-align-center w-90">
        <div
          class="P-toggle-sidebar mdi mdi-menu"
          @click="toggleHideMenu"
        ></div>
        <div v-if="showBalanceCredit" class="P-credit-block G-flex G-align-center">
          <!-- <div class="P-credit-box P-red">
            <p>Credit - ${{ credit !== 0 ? credit.toFixed(2) : 0 }}</p>
          </div> TODO -->

          <div class="P-credit-box G-flex G-align-center P-green">
            <p>
              Balance {{ numberToFinanceNumber(auth.user.provider.balance) }}
            </p>
            <ott-button
              v-if="permissionIs('payBalance') && userData.provider.type === 1"
              text="Pay"
              class="green-sm-btn"
              :click="openBalanceRefillRefundModal"
            />
          </div>
          <BalanceRefillAndRefundModal v-if="isOpenBalanceRefillModal" />
        </div>

        <!-- <div v-if="!userData.rolesInfo.admin" class="P-general-search ms-20 w-100">
          <ott-input
            v-model="generalSearchVal"
            @timeoutInput="headerSearch"
            @emitFunc="showGeneralSearch = true"
            :timeoutInput="true"
            :hideDetails="true"
            label="Search"
            prependInnerIcon="mdi mdi-magnify  neutral--text"
            @click="handleSearch"
          ></ott-input>

          <div
            v-if="generalSearchResult.length && showGeneralSearch"
            :class="$vuetify.theme.dark ? 'dark' : 'light'"
            v-click-outside="generalSearchOutside"
            class="P-general-search__result P-menu-list position-absolute"
          >
            <v-list>
              <v-list-item
                v-for="(item, key) of generalSearchResult"
                @click="searchItemClick(item.id)"
                :key="key"
                :ripple="false"
              >
                <div class="list-item">
                  <v-list-item-title v-html="item.name" />
                </div>
              </v-list-item>
            </v-list>
          </div>
        </div>-->

        <div v-if="userData.rolesInfo.admin" class="ms-20">
          <ott-button
            v-if="permissionIs('createClient') && $route.name !== 'ClientsList'"
            :click="openClientsModalFunc"
            class="primary-sm-btn"
            custom-class="approve d-flex"
            icon-before
            text="Add Client"
            icon-before-class="mdi mdi-plus d-inline"
          />
        </div> 
      </div>

      <div class="G-flex">
        <div class="P-user-information G-flex-center flex-column">
          <div class="G-flex">
            <div class="G-flex P-top-buttons">
              <div class="P-top-buttons__item">
                <router-link to="/chat" class="mx-2 sidebar-big-icon G-flex-center">
                  <v-icon>
                    mdi-chat-outline
                  </v-icon>
                </router-link>
                <div v-if="unreadMessages.length" class="P-notifications-count white--text">
                  {{ unreadMessages.length }}
                </div>
              </div>
              
              <div class="P-top-buttons__item">
                <v-menu
                  v-model="showNotifications"
                  class="notifications-part"
                  :close-on-content-click="false"
                  :nudge-width="300"
                  max-width="300"
                  bottom
                  left
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="mx-2 sidebar-big-icon G-flex-center">
                        <v-icon>
                          mdi-bell-outline
                        </v-icon>
                      </div>
                      <div v-if="notifications.length" class="P-notifications-count white--text">
                        {{ notifications.length }}
                      </div>
                    </div>
                  </template>

                  <v-card
                    class="mx-auto P-notifications-card"
                    max-width="440"
                    tile
                  >

                    <v-subheader>Notifications</v-subheader>
                    <v-list dense>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, index) in notifications"
                          :key="index"
                          @click="editNotification(item)"
                          class="comment-cont"
                          :class="{ 'P-private-notification': item?.isPrivate }"
                        >
                          <v-list-item-avatar v-if="item.type === 1">
                            <v-img :src="baseApiUrl + 'files/icon/' + item.commentWritter.avatar"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <p class="mb-1" v-if="item.type === 1"><b>From:</b> <ins>{{ item.commentWritter.fullName }}</ins></p>
                            <p class="mb-1" v-if="item.type === 1"><b>To:</b> <ins>{{ item.commentReader.fullName }}</ins></p>
                            <p class="text-wrap mb-1" v-html="`<b>Content:</b> ${item.content}`" />
                            <p class="mb-0"><b>Date:</b> <i>{{ item.date }}</i></p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>

              <div class="P-top-buttons__item">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="100"
                  max-width="100"
                  bottom
                  left
                  offset-y
                  transition="scale-transition"
                  class="mt-4"
                >
                  <template v-slot:activator="{ on, attrs }">
                      <div
                        class="mx-2 sidebar-big-icon G-flex-center"
                        v-bind="attrs" 
                        v-on="on"
                      >
                      <v-icon>
                        {{ isMuted ? 'mdi-volume-off' : 'mdi-volume-high' }}
                      </v-icon>
                    </div>
                  </template>

                  <v-card
                    class="mx-auto"
                    max-width="440"
                    tile
                  >
                    <div class="G-flex-center" style="height: 50px">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <span  
                            v-bind="attrs"
                            v-on="on" 
                            @click="volumeUp" 
                            class="volume-icon G-flex-center mdi mdi-volume-plus me-1"
                          ></span>
                        </template>
                        <span class="P-volume-tooltip">{{ notificationVolume * 10 }}</span>
                      </v-tooltip>  

                      <span 
                        v-if="isMuted"
                        @click="toggleMute"
                        class="volume-icon G-flex-center mdi mdi-volume-off me-1"
                      ></span>

                      <span
                        v-else
                        @click="toggleMute"
                        class="volume-icon G-flex-center mdi mdi-volume-high me-1"
                      ></span>
                    
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <span  
                            v-bind="attrs"
                            v-on="on" 
                            @click="volumeDown" 
                            class="volume-icon G-flex-center mdi mdi-volume-minus"
                          ></span>
                        </template>
                        <span class="P-volume-tooltip">{{ notificationVolume * 10 }}</span>
                      </v-tooltip>
                    </div>
                  </v-card>
                </v-menu>
              </div>
            </div>
            
            <div class="P-avatar-cont">
              <v-menu
                :close-on-content-click="false"
                bottom
                left
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="G-flex">
                    <div class="text-right mr-3">
                      <p class="secondary--text font-weight-bold mb-0">
                        {{ userData.firstname }} {{ userData.lastname }}
                      </p>
                      
                      <template v-for="(role, roleKey, index) of userData.rolesInfo">
                        <span v-if="role" class="neutral--text text-capitalize mb-0" :key="index">
                          {{ roleKey }}
                        </span>
                      </template>
                    </div>
                    <div class="P-avatar">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        :nudge-width="350"
                        class="P-profile-image"
                        :style="{
                          backgroundImage: userData.avatar
                            ? `url(${baseApiUrl + 'files/icon/' + userData.avatar})`
                            : '',
                        }"
                      >
                        <span v-if="!userData.avatar">
                          {{ userData.firstname ? userData.firstname.substring(0, 1) : ""}}
                        </span>
                      </div>
                      <div
                        class="P-user-status"
                        :style="{ 'background-color': connectionStateColor }"
                      ></div>
                    </div>
                  </div>
                </template>

                <v-card>
                  <div class="page-background P-user-info-menu">
                    <div
                      class="P-user-info-image"
                      :style="{
                        backgroundImage: userData.avatar
                          ? `url(${baseApiUrl + 'files/icon/' + userData.avatar})`
                          : '',
                      }"
                    >
                      <span v-if="!userData.avatar">
                        {{ userData.firstname ? userData.firstname.substring(0, 1) : "" }}
                      </span>
                    </div>
                    <div class="P-personal-info">
                      <h3 class="secondary--text">
                        {{ userData.firstname }} {{ userData.lastname }}
                      </h3>
                      <template v-for="(role, roleKey, index) of userData.rolesInfo">
                        <p v-if="role" class="neutral--text text-capitalize" :key="index">
                          {{ roleKey }}
                        </p>
                      </template>

                      <p class="neutral--text">Ottstream</p>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div class="page-background P-menu-list">
                    <v-list>
                      <v-list-item dense @click="openMySettings">
                        <v-list-item-title>
                          <span
                            class="P-icon icon-manage-account neutral--text"
                          ></span>
                          <p>My Settings</p>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="permissionIs('getOttProviderSettings')"
                        dense
                        @click="openModalManageAdmin"
                      >
                        <v-list-item-title>
                          <span class="mdi mdi-settings-outline neutral--text"></span>
                          <p>Company Settings</p>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item dense>
                        <v-list-item-title>
                          <span class="mdi moon-icon neutral--text"></span>
                          <p>Theme Dark</p>
                        </v-list-item-title>
                        <v-list-item-action>
                          <ott-switch v-model="themeDark" @emitFunction="toggleDarkTheme" />
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item dense @click="logOut">
                        <v-list-item-title class="G-cursor-pointer">
                          <span class="mdi mdi-logout neutral--text"></span>
                          <p>Logout</p>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card>
              </v-menu>
            </div>
          </div>
          <!-- <div class="G-flex-center">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <span  
                  v-bind="attrs"
                  v-on="on" 
                  @click="volumeUp" 
                  class="volume-icon G-flex-center mdi mdi-volume-plus me-1"
                ></span>
              </template>
              <span>{{ notificationVolume * 10 }}</span>
            </v-tooltip>  

            <span 
              v-if="isMuted"
              @click="toggleMute"
              class="volume-icon G-flex-center mdi mdi-volume-off me-1"
            ></span>

            <span
              v-else
              @click="toggleMute"
              class="volume-icon G-flex-center mdi mdi-volume-high me-1"
            ></span>
          
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <span  
                  v-bind="attrs"
                  v-on="on" 
                  @click="volumeDown" 
                  class="volume-icon G-flex-center mdi mdi-volume-minus"
                ></span>
              </template>
              <span>{{ notificationVolume * 10 }}</span>
            </v-tooltip>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="userData.rolesInfo.admin" class="G-flex-center">
      <div class="P-general-search P-admin-search w-100 mb-1">
        <ott-input
          v-model="generalSearchVal"
          @timeoutInput="headerSearch"
          @emitFunc="showGeneralSearch = true"
          :timeoutInput="true"
          :hideDetails="true"
          label="Search"
          prependInnerIcon="mdi mdi-magnify  neutral--text"
          @click="handleSearch"
        ></ott-input>

        <div
          v-if="generalSearchResult.length && showGeneralSearch"
          :class="$vuetify.theme.dark ? 'dark' : 'light'"
          v-click-outside="generalSearchOutside"
          class="P-general-search__result P-menu-list"
        >
          <v-list>
            <v-list-item
              v-for="(item, key) of generalSearchResult"
              @click="searchItemClick(item.id)"
              :key="key"
              :ripple="false"
            >
              <div class="list-item">
                <v-list-item-title v-html="item.name" />
              </div>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>

    <div class="G-flex P-header-search" v-if="!userData.rolesInfo.admin">
      <div v-if="!userData.rolesInfo.admin" class="P-general-search w-100">
        <ott-input
          v-model="generalSearchVal"
          @timeoutInput="headerSearch"
          @emitFunc="showGeneralSearch = true"
          :timeoutInput="true"
          :hideDetails="true"
          label="Search"
          prependInnerIcon="mdi mdi-magnify  neutral--text"
          @click="handleSearch"
        ></ott-input>

        <div
          v-if="generalSearchResult.length && showGeneralSearch"
          :class="$vuetify.theme.dark ? 'dark' : 'light'"
          v-click-outside="generalSearchOutside"
          class="P-general-search__result P-menu-list position-absolute"
        >
          <v-list>
            <v-list-item
              v-for="(item, key) of generalSearchResult"
              @click="searchItemClick(item.id)"
              :key="key"
              :ripple="false"
            >
              <div class="list-item">
                <v-list-item-title v-html="item.name" />
              </div>
            </v-list-item>
          </v-list>
        </div>
      </div>

      <div class="ms-20">
        <ott-button
          v-if="permissionIs('createClient') && $route.name !== 'ClientsList'"
          :click="openClientsModalFunc"
          class="primary-sm-btn"
          custom-class="approve d-flex"
          icon-before
          text="Add Client"
          icon-before-class="mdi mdi-plus d-inline"
        />
      </div>
    </div>

    <div class="P-header-bottom position-relative d-flex align-center">
      <div class="d-flex flex-column align-center justify-center">
        <ul class="d-inline-flex">
          <li
            class="neutral-color-link--text"
            v-for="(list, index) of routerList"
            :key="index"
          >
            {{ list.text }}
          </li>
        </ul>

        <div v-if="!isOpenClientModal" class="G-flex-center flex-column align-center mt-1">
          <div class="me-1">
            <v-tooltip v-if="$route.name === 'Search' && showSearchComponents" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  @click="lockDragsClick"
                  class="G-cursor-pointer"
                >
                <v-icon v-if="lockDrags" class="neutral--text">mdi-lock</v-icon>
                <v-icon v-else class="neutral--text">mdi-lock-open-variant</v-icon>
                </span>
              </template>
              <span>{{ blockUnblockDragTxt }}</span>
            </v-tooltip>
          </div>

          <v-tooltip v-if="$route.name === 'Search' && showSearchComponents" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                @click="resetDragStatuses"
                class="G-cursor-pointer"
              >
              <v-icon class="neutral--text">mdi-refresh</v-icon>
              </span>
            </template>
            <span>Reset Drag Statuses</span>
          </v-tooltip>
        </div>
      </div>
    
      <ListComponent 
        v-show="$route.name === 'Search' && showSearchComponents && !isOpenClientModal"
        :tableHeaders="filteredHeaders"
        :listData="filteredClientListData"
        :actionsList="clientActionsList"
        @editFunc="openClientEditTab"
        @listItemClick="searchTopItemClick($event)"
      />
      <transition name="fade">
        <div v-if="$route.name === 'Search' && isOpenSmsCont" class="P-sms-cont py-2 px-2 G-align-end position-absolute">
          <!-- <div class="w-100"> -->
            <!-- <v-progress-linear v-model="smsProgress" class="mb-3"></v-progress-linear>
            <ott-input 
              v-model="smsText" 
              label="Type Your message..."
              @keyup="sendSms"
            /> -->

            <chat :clientId="$route.name === 'Search' ? $route.params['id'] : null" :phones="phoneList" :client="client" :locations="allLocations" :addresses="allCompanyAddresses" />
          <!-- </div> -->
        </div>
      </transition>  

      <div v-if="!isOpenClientModal" class="G-align-center flex-column ms-2 align-center">
        <v-tooltip v-if="$route.name === 'Search' && showSearchComponents" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="seachTabsFullSize()"
              class="G-cursor-pointer"
            >
            <v-icon class="neutral--text">mdi-format-list-bulleted</v-icon>
            </span>
          </template>
          <span>Full Sizes</span>
        </v-tooltip>

        <v-tooltip v-if="$route.name === 'Search' && showSearchComponents" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="searchTabsMiddleSize()"
              class="G-cursor-pointer"
            >
            <v-icon class="neutral--text">mdi-apps</v-icon>
            </span>
          </template>
          <span>Middle Sizes</span>
        </v-tooltip>
      </div>
    </div>
    <ManageAdmin v-if="manageAdminModule.modal.isOpen" />
    <ManageUser v-if="isOpenUserModal" />
    <ManageClientModal v-if="isOpenClientModal" ref="clientModal" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import PermissionsMixin from "@/mixins/PermissionsMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ManageUser from "@/views/users/components/ManageUser"
import CreditCardsMixin from "@/mixins/CreditCardsMixin";
import ManageAdmin from "./components/manageAdmin/ManageAdmin";
import ManageClientModal from "@/views/clients/modals/manageClient/ManageClient";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttButton from "@/components/vuetifyComponents/OttButton";
import BalanceRefillAndRefundModal from "./BalanceRefillAndRefundModal";
import OttInput from "@/components/vuetifyComponents/OttInput";
import ListComponent from "@/components/ListComponent";
import Chat from "@/components/Chat";

export default {
  mixins: [ PermissionsMixin, DateFormatMixin, CreditCardsMixin ],
  components: {
    ManageUser,
    ManageAdmin,
    ManageClientModal,
    BalanceRefillAndRefundModal,
    OttButton,
    OttSwitch,
    OttInput,
    ListComponent,
    Chat
  },
  data: () => ({
    // volume variables
    generalSearchVal: "",
    showGeneralSearch: true,
    searchType: "client",
    hubBalance: 0,
    routerList: [],
    credit: 0,
    connectionStateColor: "green",
    clientHeaders: [
      "Full Name",
      "Date Of Birth",
      "Gender",
      'Currency',
      'Price Group',
      'Recurring Payment',
      'Paperless Billing',
      'Provider Name',
      'Balance',
      'Credit',
      'Messages'
    ],
    clientActionsList: [ "Edit" ],
    clientListData: [{
      fullName: '-',
      dateOfBirth: '-',
      gender: '-',
      currency: 'USD',
      priceGroup: 'Default',
      recurringPayment: `<span class="G-badge G-reject-badge status-badge">Off</span>`,
      paperlessBilling: `<span class="G-badge G-reject-badge status-badge">Off</span>`,
      providerName: '-',
      balance: '$0.00',
      credit: '$0.00',
      message: '<span class="mdi mdi-chat-outline G-cursor-pointer P-sms-icon"></span>',
      id: ''
    }],
    
    showNotifications: false,
    notificationsAlertInterval: null,
    isOpenSmsCont: false,
    smsText: '',
    smsProgress: 0,
    showProgress: false,
    phoneList: [],
    allLocations: [],
    allCompanyAddresses: [],
    isShowAllChats: false
  }),

  computed: {
    ...mapState({
      userData: (state) => state.auth.user,
      manageAdminModule: (state) => state.manageAdminModule,
      auth: (state) => state.auth,
      sidebar: (state) => state.sidebar,
      isOpenBalanceRefillModal: (state) =>
        state.balanceRefill.isOpenBalanceRefillModal,
      hubState: (state) => state.appModule.hubState,
      isOpenUserModal: (state) => state.users.modal.isOpen,
      client: (state) => state.clientsModule.client,
      isOpenClientModal: (state) => state.clientsModule.modal.isOpen,
      personalInfoClient: (state) => state.personalInfoClient,
      personalInfoClientFormData: (state) => state.personalInfoClient.formData,
      clientFinanceForm: (state) => state.financeClient.formData,
      clientFinanceCurrencyList: (state) => state.financeClient.currencyList,
      paymentForPackagesObj: (state) => state.financeClient.paymentForPackagesObj,
      clientsPriceGroups: (state) => state.priceGroupAdmin.clientsPriceGroups,
      balanceCreditData: (state) => state.balanceCreditClient.balanceCreditData,
      notifications: (state) => state.notifications.notifications,
      unreadMessages: (state) => state.mainChatModule.unreadMessages,
      notificationAlert: (state) => state.sidebar.notificationAlert,
      notificationAudio: (state) => state.sidebar.notificationAudio,
      generalSearchResult: (state) => state.generalSearch.generalSearchResult,
      searchedClientId: (state) => state.generalSearch.searchedClientId,
      showSearchComponents: (state) => state.generalSearch.showSearchComponents,
      lockDragsVal: (state) => state.generalSearch.lockDrags,
      authProviderId: (state) => state.auth.user.provider.id,
      hasValidTwilioSearchClient: (state) => state.generalSearch.hasValidTwilio,
      clientProviderId: (state) => state.clientsModule.clientProviderId,
      allPhones: state => state.contactClient.allPhones,
      locationList: state => state.locationClient.locationList,
      companyAddressList: (state) => state.companyAddressesAdmin.fullAddressList,
      companyId: (state) => state.auth.user.provider.id,
    }),

    notificationVolume: {
      set(value) {
        this.notificationAudio.volume = +value
        this.notificationAlert.volume = +value
        this.setData({ notificationVolume: parseFloat(+value.toFixed(1)) })
        if (value === 0) {
          this.notificationAudio.muted = true
          this.notificationAlert.muted = true
          this.setData({ isMuted: true })
        } else {
          this.notificationAudio.muted = false
          this.notificationAlert.muted = false
          this.setData({ isMuted: false })
        }
      },
      get() {
        return this.sidebar.notificationVolume
      }
    },

    isMuted: {
      set(isMuted) {
        this.notificationAudio.muted = isMuted
        this.notificationAlert.muted = isMuted
        if (!isMuted) {
          this.notificationVolume = 1
        }else {
          this.notificationVolume = 0
        }
        this.setData({ isMuted })
      },
      get() {
        return this.sidebar.isMuted
      }
    },

    themeDark: {
      get() {
        return this.sidebar.themeDark
      },
      set(value) {
        this.setData({ themeDark: value ? value : false })
      }
    },

    lockDrags: {
      get() {
        return this.lockDragsVal
      },
      set(lockDrags) {
        this.generalSearchSetData({ lockDrags })
      }
    },

    blockUnblockDragTxt() {
      return !this.lockDrags ? 'Lock Drags' : 'Unlock Drags'
    },

    showBalanceCredit() {
      if (
        this.userData?.rolesInfo?.admin || 
        ((this.userData?.rolesInfo?.cashier || 
          this.userData?.rolesInfo?.advancedCashier || 
          this.userData?.rolesInfo?.support) && 
        this.userData?.rolesInfo?.admin)
      ) {
        return true
      } else {
        return false
      }
    },

    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },

    items() {
      const routes = this.$route.matched.filter((route) => {
        return route.name || (route.meta && route.meta.label);
      });
      return routes.map((route) => {
        const meta = route.meta || {};
        return {
          to: route,
          text: meta.label || route.name,
        };
      });
    },

    // Client infos
    clientCurrencyName() {
      let currency = 'USD'
      this.clientFinanceCurrencyList.map((item) => {
        if (item.value === this.clientFinanceForm.currency) currency = item.name[0].name
      })
      return currency
    },

    clientPriceGroupName() {
      let priceGroup = 'Default'

      if (this.clientFinanceForm.priceGroup !== 'default') {
        this.clientsPriceGroups.map((item) => {
          if (item.value === this.clientFinanceForm.priceGroup) {
            priceGroup = item.name.map(name => name.lang === 'en' ? name.name : item.name[0].name)[0]
          }
        })
      }

      if (!this.clientFinanceForm.priceGroup) {
        priceGroup = 'Default'
      }

      return priceGroup
    },

    clientPaymentForPackagesName() {
      let name = '<span class="G-badge G-reject-badge status-badge">Off</span>'

      if (this.paymentForPackagesObj?.id && !this.isOpenClientModal) {
        // name = item.name
        if (this.paymentForPackagesObj?.bankTransfer) { // getting bank name with bank icon
          name = `
            <div class="G-align-center">
              <svg class="P-bank-account-icon me-1">
                <use xlink:href="/sprite.svg#bank-icon"></use>
              </svg>

              <span>${ this.paymentForPackagesObj.bankTransfer.accountNumber.slice(-4) }</span>
            </div>
          `
        } 
        
        if (this.paymentForPackagesObj?.creditCard) {  // getting card name with card icon
          name = `
              <div class="G-align-center">
                <div class="P-credit-card-image me-1" style="background-image: url(${ this.getCardBrand(this.paymentForPackagesObj.creditCard.brand) })"></div>
                <span>${
                  this.paymentForPackagesObj.creditCard.cardNumber.slice(-4)
                }</span>
              </div>
            `
        }
      } else {
        name = '<span class="G-badge G-reject-badge status-badge">Off</span>'
      }
      
      return name
    },

    filteredHeaders() {
      // Filter headers based on hasValidTwilioSearchClient value
      return this.hasValidTwilioSearchClient
        ? this.clientHeaders
        : this.clientHeaders.filter(header => header !== 'Messages');
    },

    filteredClientListData() {
      // Filter clientListData based on hasValidTwilioSearchClient value
      return this.hasValidTwilioSearchClient
        ? this.clientListData
        : this.clientListData.map(client => {
            // Omit 'message' property when hasValidTwilioSearchClient is false
            const { message, ...clientWithoutMessage } = client;
            return clientWithoutMessage;
          });
    }
  },

  watch: {
    isOpenSmsCont(newVal) {
      if (!newVal) {
        this.smsProgress = 0;
        this.smsText = ''
      }
    },
    themeDark(newVal) {
      this.$vuetify.theme.dark = newVal
    },

    items(data) {
      this.routerList = data;
      this.routerList.shift();  
    },

    async clientProviderId(newVal){
      if (newVal) {
        await this.getPriceGroupList()
      }
    },

    clientsPriceGroups(priceGroups = []) {
      if (priceGroups.length) {
        this.clientListData[0].priceGroup = this.clientPriceGroupName;
        if (this.clientFinanceForm.isPaperlessBilling) {
          this.clientListData[0].paperlessBilling = `
            <span class="G-badge G-approve-badge status-badge">On</span>
          `
        } else {
          this.clientListData[0].paperlessBilling = `
            <span class="G-badge G-reject-badge status-badge">Off</span>
          `
        }
      }
    },  

    hubState(val) {
      this.changeHubColor();
    },

    personalInfoClientFormData: { // if updated client personal info, get data for show personal info in top part
      handler(formData) {
        if (formData.firstname || formData.lastname) {
          this.clientListData[0].fullName = formData.firstname + ' ' + formData.lastname
        }
        if (formData.dateOfBirth) {
          this.clientListData[0].dateOfBirth = this.getDate(formData.dateOfBirth); // TODO use user counttry and time-zone-list.js to get real format for each user
        }
        if (formData.gender)
          this.clientListData[0].gender = formData.gender
        if (formData.providerName)
          this.clientListData[0].providerName = formData.providerName
        this.clientListData[0].id = this.searchedClientId
      },
      deep: true,
    },

    paymentForPackagesObj(){
      this.clientListData[0].currency = this.clientCurrencyName
      this.clientListData[0].recurringPayment = this.clientPaymentForPackagesName
    },

    balanceCreditData:  {
      handler(balanCredit) {
        //Add client balance in top sidebar
        if (typeof balanCredit?.balance === 'number') {

          this.clientListData[0].balance = this.numberToFinanceNumber(balanCredit?.balance)
        }

        //Add client credit in top sidebar
        if (typeof balanCredit?.credit?.creditAmount === 'number') {
          this.clientListData[0].credit = this.numberToFinanceNumber(balanCredit?.credit?.creditAmount)
        }

        if (balanCredit?.credit === null) {
          this.clientListData[0].credit = '$0.00'
        }
      },
      deep: true
    },

    notifications(data) {
      const notifications = data.filter(item => !item.isViewed)
      if (notifications.length) {
        // play notification sound if there are not exist viewed notification
        this.playNotificationAlert()
      } else {
        clearInterval(this.notificationsAlertInterval)
      }
    },

    'allPhones'(allPhones) {
      if (allPhones.length) {
        this.phoneList = []
        allPhones.filter(r=>r.forMessages).map(item => {
          this.phoneList.push({
            name: item.phone,
            value: item.phone
          })
        })
      }
    },

    'locationList'(locationList) {
      if (locationList.length) {
        this.allLocations = []
        locationList.map(item => {
          this.allLocations.push({
            name: item.login,
            value: item.login,
            login: item.login,
            password: item.password,
          })
        })
      }
    },
    'companyAddressList'(companyAddressList) {
      if (companyAddressList.length) {
        this.allCompanyAddresses = []
        companyAddressList.map(item => {
          this.allCompanyAddresses.push({
            name: item.name,
            value: item.name,
          })
        })
      }
    },
  },

  async created() {
    await this.getNotifications()
  },
  async mounted() {
    this.$hubEventBus.$on(`provider-chat`, this.handleProviderChatSocket); // if loggedIn get permissions with WS
    await this.fetchUnreadMessages({ provider: this.authProviderId});
    this.changeHubColor();
    this.$hubEventBus.$on("balance", this.handleMessage); // TODO added for event bus balance show
    this.routerList = this.items;
    this.routerList.shift();
    // await this.getAdminData();
    await this.getCompanyAddresses(this.companyId)

    console.log(this.notifications, 'notifications')
  },
beforeMount() {

  this.$hubEventBus.$off(`provider-chat`, this.handleProviderChatSocket); // if loggedIn get permissions with WS
},
  methods: {
    ...mapActions({
      updateSidebarSettings: "sidebar/updateSettings",
      getPaymentDataList: "paymentMethodsAdmin/getPaymentDataList",
      getCompanyAddresses: "companyAddressesAdmin/getCompanyAddresses",
      getUserById: "users/getUserById",
      logoutAction: "auth/logout",
      fetchUnreadMessages: 'mainChatModule/fetchUnreadMessages',
      generalSearch: "generalSearch/search",
      getDragStatuses: "generalSearch/getDragStatuses",
      searchResetDragStatuses: "generalSearch/resetDragStatuses",
      getNotifications: "notifications/getNotifications",
      updateNotification: "notifications/updateNotification",
      updateGeneralSearchSettings: "generalSearch/updateSettings",
      getOtherApiIntegration: "otherApiIntegrationAdmin/getOtherApiIntegration",
      getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
    }),

    ...mapMutations({
      setData: "sidebar/setData",
      generalSearchSetData: "generalSearch/setData",
      toggleOpenModal: "manageAdminModule/toggleOpenModal",
      toggleFullHide: "sidebar/toggleFullHide",
      openBalanceRefillModal: "balanceRefill/openBalanceRefillModal",
      setProviderId: "manageAdminModule/setProviderId",
      generalInfoUserFetch: "generalInfoUser/fetchData",
      securityLoginFetchData: "securityLogin/fetchData",
      fetchDataRole: "roleUser/fetchDataRole",
      openUsersModal: "users/openModal",
      usersSetData: "users/setData",
      usersDisableSaveCloseButton: "users/disableSaveCloseButton",
      openClientsModal: "clientsModule/openModal",
      clientsSetData: "clientsModule/setData",
      adminSetData: "manageAdminModule/setData",
      setDisableTabs: "clientsModule/setDisableTabs",
      generateAllPriceGroups: "priceGroupAdmin/generateAllPriceGroups",
    }),

    async handleProviderChatSocket() {
      await this.fetchUnreadMessages({ provider: this.authProviderId});
    },
    getPriceGroupList() {
      if (this.clientProviderId) {
        this.getPriceGroupByTypeList({ 
          type: 1,
          provider: this.clientProviderId
        }).then(data => {
          this.generateAllPriceGroups(data)
        })
      }
    },

    sendSms() {
      this.showProgress = true;

      setTimeout(() => {
        this.smsProgress = 100;
      },500)

    },

    searchTopItemClick(keyName) {
      if (keyName === 'message')
        this.isOpenSmsCont = !this.isOpenSmsCont;
    },

    numberToFinanceNumber(number) {
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(number);

      if (number === 0) {
        return '$0.00'
      } else {
        return formattedNumber;
      }
    },

    async toggleMute() {
      this.isMuted = !this.isMuted
      this.notificationAudio.muted = this.isMuted // Set notification audio muted
      this.notificationAlert.muted = this.isMuted // Set notification alert muted

      await this.updateSidebarSettings()
    },

    async volumeUp() {
      if (this.notificationVolume < 1) {
        this.notificationVolume += 0.1
      }

      await this.updateSidebarSettings()
    },

    async volumeDown() {
      if (this.notificationVolume > 0 && this.notificationVolume <= 1) {
        this.notificationVolume -= 0.1
      }

      await this.updateSidebarSettings()
    },

    generalSearchOutside() {
      this.showGeneralSearch = false
    },

    async lockDragsClick() {
      this.lockDrags = !this.lockDrags
      await this.updateGeneralSearchSettings()
    },

    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },

    openClientEditTab(id) {
      this.setDisableTabs(9)

      this.clientsSetData({ 
        isAddClient: false,
      });

      this.openClientsModal();
    },

    headerSearch() {
      this.generalSearch(this.generalSearchVal.trim());
    },

    async resetDragStatuses() {
      await this.searchResetDragStatuses({searchType: this.$route.params.type}).finally(async () => {
        await this.getDragStatuses()
      })
    },

    async seachTabsFullSize() {
      this.generalSearchSetData({ tabsWidth: 100 })
      await this.updateGeneralSearchSettings()
    },

    async searchTabsMiddleSize() {
      this.generalSearchSetData({ tabsWidth: 48.4 })
      await this.updateGeneralSearchSettings()
    },


    changeHubColor() {
      let color = "red";
      if (this.hubState === 5) {
        color = "green";
      } else if (this.hubState === 2) {
        color = "yellow";
      }
      this.connectionStateColor = color;
    },

    handleMessage(data) {
      if (data && data.amount) {
        console.log(
          `provider balance updated from ws: ${data.amount.toFixed(2)}`
        );
        this.setData({
          balance: data.amount,
        });
      }
    },

    async openModalManageAdmin() {
      this.toggleOpenModal(true);
      if (this.manageAdminModule.providerId) {
        await this.getPaymentDataList(this.manageAdminModule.providerId);
        await this.getCompanyAddresses(this.manageAdminModule.providerId);
      }
    },

    async toggleHideMenu() {
      this.toggleFullHide();

      await this.updateSidebarSettings()
    },

    logOut() {
      this.logoutAction();
      this.$router.go("/auth/sign-in");
    },
    async toggleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      await this.updateSidebarSettings()
    },

    // Open balance refill modal
    openBalanceRefillRefundModal() {
      this.openBalanceRefillModal();
    },

    async openMySettings() {
      await this.getUserById(this.userData.id).then((data) => {
        this.generalInfoUserFetch(data);
        this.fetchDataRole(data.rolesInfo);
        this.securityLoginFetchData(data);
        this.usersSetData({
          userId: data.id,
          disableButton: false,
          disableFlag: true,
          showRoles: false,
        });
        this.usersDisableSaveCloseButton();
        this.openUsersModal();
      });
    },

    searchItemClick(id) {
      if (this.$route.params.id !== id) {
        this.$router.push(`/search/${this.searchType}/${id}`);
      }
      this.showGeneralSearch = false;
      this.generalSearchVal = "";
    },

    openClientsModalFunc() {
      this.clientsSetData({ 
        isAddClient: true,
        clientId: null
      }); 
      
      if (this.$route.name === 'Search') {
        this.clientsSetData({
          isAddClientFromSearch: true,
        })

        this.generalSearchSetData({
          showSearchComponents: false
        })
      }
      setTimeout(() => {
        this.openClientsModal()
      }, 1000)
    },

    async editNotification({ client = {}, id = null }) {
      if (client?.id) {
        if (this.$route.params.id !== client?.id) {
          this.$router.push(`/search/client/${client.id}`);
          this.showNotifications = false;
        }
      }

      if (id) {
        await this.updateNotification(id)
      }
      if (!this.notifications.length) {
        this.showNotifications = false;
      }
    },

    playNotificationAlert() {
      clearInterval(this.notificationsAlertInterval)
      this.notificationsAlertInterval = setInterval(() => {
        this.notificationAlert.play();    
      }, 60000)
    },

    handleSearch() {
      this.isOpenSmsCont = false
    },
    openChat() {
      this.isShowAllChats = !this.isShowAllChats
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";
@import "./src/assets/scss/simpleTable";

.P-header-top, .P-admin-search {
  padding: 6px 30px;
}
.P-header-search {
  padding: 10px 30px;
}

.P-header-bottom {
  border-top: 2px solid $primary-color;
  padding: 6px 12px;
}

.P-sidebar-header {
  @include box-shadow-bottom;
  margin-bottom: 2px;
}

.P-toggle-sidebar {
  font-size: 25px;
  color: $neutral-color;
  cursor: pointer;
}

.P-header-bottom {
  ul {
    padding: 0;

    li {
      margin: 0 5px;
      position: relative;
      font-size: 14px;
      border-bottom: 1px solid transparent;
      text-transform: capitalize;

      &:last-child {
        color: $primary-color !important;
        text-decoration: underline;
        font-weight: $semiBold;
      }
    }

    li + li {
      padding-left: 10px;

      &:before {
        position: absolute;
        left: 0;
        content: "";
        border-left: 1px solid $neutral-color;
        height: 60%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .P-table-block{
    margin-top: 0;
    margin-left: 10px;  
  }
}

.P-profile-image {
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3a200;
  color: $white;
  font-weight: $semiBold;
  font-size: $txt22;
}

.P-info-block {
  margin-top: 0 !important;
  top: 6px !important;
}

.P-user-info-image {
  width: 65px;
  height: 65px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  font-size: 35px;
  align-items: center;
  justify-content: center;
  background-color: #f3a200;
  color: $white;
}

.P-user-info-menu {
  padding: 25px;
  display: flex;
  align-items: center;
  min-width: 320px;
}

.P-menu-list {
  .mdi {
    font-size: 20px;
    margin-right: 14px;
  }
}

.P-personal-info {
  width: calc(100% - 65px);
  padding-left: 15px;

  h3 {
    font-size: $txt14;
    font-weight: 600;
  }

  p {
    font-size: $txt14;
    margin-bottom: 0;
  }
}

.P-menu-list::v-deep {
  .v-list-item {
    height: 40px;
    min-height: 30px;

    &:hover {
      background-color: $neutral-color15;
      cursor: pointer;
    }

    .v-list-item__title {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.moon-icon {
  background-image: url(/icons/light-moon.svg);
  width: 20px;
  height: 20px;
}

.theme--dark {
  .P-menu-list::v-deep {
    .v-list {
      background-color: $secondary-color-dark;
    }

    .v-list-item {
      &:hover {
        background-color: $neutralHoverDM;
        cursor: pointer;
      }
    }
  }

  .moon-icon {
    background-image: url(/icons/dark-moon.svg);
  }

  li {
    &:last-child {
      color: $primary-colorDM !important;
    }
  }
}

.P-credit-block {
  margin-left: 25px;
}

.P-credit-box {
  border-radius: 4px;
  overflow: hidden;

  & + .P-credit-box {
    margin-left: 20px;
  }

  p {
    margin-bottom: 0;
    font-size: $txt16;
    font-weight: 600;
    padding: 7px 30px;
    position: relative;
    border-radius: 4px;

    &:before {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 9px;
      border-radius: 50%;
      content: "";
    }
  }

  span {
    color: $white;
    padding: 7px 10px;
    background-color: $green-new;
    cursor: pointer;
    border-radius: 4px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $txt16;
  }

  &.P-red {
    background-color: $danger-new-light;

    p {
      color: $danger-new;

      &:before {
        background-color: $danger-new;
      }
    }
  }

  &.P-green {
    background-color: $green-new-light;

    p {
      color: $green-new;

      &:before {
        background-color: $green-new;
      }
    }
  }
}

.P-icon {
  font-size: 20px;
  margin-right: 14px;
}

.P-avatar {
  position: relative;
  .P-user-status {
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-30 {
  width: 30%;
}

.ms-20 {
  margin-left: 20px;
}

.P-general-search {
  position: relative;

  &__input {
    width: 60px;
  }
  &__result {
    z-index: 6;
    width: 100%;
    box-shadow: 0 1px 3px 0px #a5aac6;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 180px;
    // & .v-list-item {
    //   border-bottom: 1px solid $neutral-color;
    // }
  }
  
  &__result.dark {
    background-color: $darkBgLighterDM !important;
  }

  &__result.light {
    background-color: white !important;
  }
}

.P-top-buttons {
  margin-right: 56px;
  &__item {
    position: relative;
    .v-btn--fab.v-size--default {
      width: 48px;
      height: 48px;
      box-shadow: none;
    }

    .P-notifications-count {
      position: absolute;
      right: 4px;
      top: -4px;
      border-radius: 10px;
      display: inline-block;
      font-size: 12px;
      min-width: 20px;
      height: 20px;
      letter-spacing: 0;
      line-height: 1;
      padding: 4px 6px;
      pointer-events: auto;
      position: absolute;
      text-align: center;
      text-indent: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      white-space: nowrap;
      background-color: $secondary-color;
      z-index: 1;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.v-menu__content:has(.P-notifications-card) {
  top: 55px !important;
}

.P-avatar-cont {
  width: max-content;
  position: relative;

  .P-notifications-count {
    border-radius: 10px;
    color: #FFFFFF;
    display: inline-block;
    font-size: 12px;
    height: 20px;
    letter-spacing: 0;
    line-height: 1;
    min-width: 20px;
    padding: 4px 6px;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    text-indent: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
    background-color: #ff6969;
    border-color: #ff6969;
    z-index: 1;
    top: -4px;
    left: -10px;
    font-weight: bold;
    cursor: pointer;
  }
}

.P-private-notification {
  background-color: $orange-lighter!important;
}

.volume-icon {
  font-size: 22px;
  cursor: pointer;
  color: $neutral-color75;
  transition: all 0.2s;
  height: 22px;
  font-weight: bold;
  position: relative;

  &:hover {
    color: $primary-color;
  }
}

.P-sms-cont {
  width: 386px;
  max-height: 600px;
  right: 3.8%;
  top: 100px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgb(172, 172, 172);
}

.theme--dark {
  .P-sms-cont {
    background: #36393C;
  }

  .P-top-buttons {
    &__item {
      .v-btn--fab.v-size--default:hover {
        background-color: #5B5B5B;
      }
    }
  }
}

.w-100 {
  width: 100%;
}

.comment-cont {
  box-shadow: 0 0 3px grey;
}

.v-application.theme--dark .P-volume-tooltip {
  color: $dark_light;
}

.sidebar-big-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $neutral-color25;
  transition: all 0.3s;

  & i {
    color: $secondary-color;
  }

  &:hover {
    background-color: $neutral-color45;
  }
}

.theme--dark {
  .sidebar-big-icon {
    background-color: $dark-bold;

    & i {
      color: $white;
    }

    &:hover {
      background-color: $white;

      & i {
        color: $secondary-color;
      }
    }
  }
}
</style>

<style lang="scss">
  .P-sidebar-header {
    .P-table-block ul li {
      padding: 6px 0;
      width: 100%;
      min-width: unset;
    }
  }

  .status-badge {
    padding: 0.5px 10px !important;
  }

  .P-sms-icon {
    font-size: 25px;
  }
</style>
