<template>
  <div
    :class="{ 'P-minimize-sidebar': isMinimize, 'P-full-hide': isFullHide }"
    class="P-sidebar-block page-background-side-bar"
  >
    <div class="P-sidebar-header secondary-66">
      <div
        :class="{ 'P-show-minimize-logo': isMinimize }"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/images/main/shortLogo.svg') + ')'
        }"
        class="P-short-logo"
      ></div>
      <div
        :class="{ 'P-hide-logo': isMinimize }"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/images/main/whiteLogo.svg') + ')'
        }"
        class="P-main-logo"
      ></div>
    </div>
    <div class="P-sidebar-menu-list">
      <div class="P-sidebar-menu">
        <ul>
          <template v-for="(list, index) of sideBarList">
            <li
              v-if="permissionIs(list.permissionKey)"
              :key="index"
              :class="{ 'P-active-menu': isActiveRout(list, index) }"
              ref="sidebarList"
            >
              <SidebarLink
                :item="list"
                :isOpenSublist="activeIndex === index"
                :showTooltip="isMinimize && !isFullHide"
                @click.native="toggleShowSubList($event, list, index)"
              />
              <!-- @mouseOver="setItemTitle($event, list, index)"
              @mouseLeave="removeToolbar($event)" -->
              <div
                v-if="list.child && list.child.length"
                class="P-menu-sub-list "
                :class="{ 'P-open-sublist': activeIndex === index }"
              >
                <ul>
                  <template v-for="(subList, index) of list.child">
                    <li v-if="permissionIs(subList.permissionKey) || 
                    (permissionIs(subList.permissionKey) && subList.href === '/users/list' && !userData.roleInfo.cashier)" :key="index">
                      <router-link
                        v-if="subList.href"
                        :to="subList.href"
                        @click.native="selectMenu(subList.href)"
                        class="white--text"
                        >{{ subList.title }}
                      </router-link>
                    </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="P-tooltip secondary-07" ref="toolbar" v-show="isOpenToolbar">
        <p class="white--text">{{ sidebarTitle }}</p>
      </div>
    </div>
    <div
      @click="toggleMinimize"
      class="P-sidebar-footer secondary-66 G-align-center "
    >
      <span class="mdi mdi-chevron-left white--text P-arrow-minimize" :class="isMinimize ? 'mdi-chevron-right' : 'mdi-chevron-left'" />
      <p class="white--text">Minimize menu</p>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { eventBus } from '@/main'

// components
import SidebarMenuModel from "@/models/sidebar-menu/SidebarMenuModel";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import SidebarLink from "./SidebarLink";

export default {
  components: { SidebarLink, OttSelect },
  data() {
    return {
      sideBarList: [],
      matchedPath: null,
      flagChangeIndex: false,
      sidebarTitle: "",
      isOpenToolbar: false
    }
  },
  computed: {
    ...mapState({
      isMinimize: state => state.sidebar.isMinimize,
      isFullHide: state => state.sidebar.isFullHide,
      activeIndex: state => state.sidebar.activeIndex,
      userData: state => state.auth.user,
    })
  },
  async created() {
    if (this.$route.name !== 'Search') {
      await this.getSidebarSettings()
    }
    this.getSidebarList(this.roles);
    this.isOpenToolbar = false;

    if (this.userData.rolesInfo.cashier && !this.userData.rolesInfo.admin) {
      await this.toggleMinimize()
    } 
  },
  methods: {
    ...mapActions({
      updateSidebarSettings: 'sidebar/updateSettings',
      getSidebarSettings: 'sidebar/getSettings'
    }),

    ...mapMutations({
      setActiveIndex: 'sidebar/setActiveIndex',
      toggleMinimizeMutation: 'sidebar/toggleMinimize'
    }),

    getSidebarList(roles) {
      this.sideBarList = new SidebarMenuModel(roles).menu;
    },

    isActiveRout(list, index) {
      if (list.child && list.child.length) {
        let path = window.location.pathname;
        let arr = list.child.map(x => x.href);
        if (arr.includes(this.matchedPath || path)) {
          if (!this.flagChangeIndex) {
            if (this.isMinimize) {
              this.setActiveIndex(null)
            } else {
              this.setActiveIndex(index)
            }
          }
        }
        return arr.includes(this.matchedPath || path);
      }
    },

    toggleShowSubList(event, list, index) {
      if (this.isMinimize) {
        this.toggleMinimizeMutation(false);
        this.isOpenToolbar = false;
      }
      if (list.child && list.child.length) {
      } else {
        this.matchedPath = list.href;
      }
      this.flagChangeIndex = true;
      if (this.activeIndex === index) {
        this.setActiveIndex(null)
      } else {
        this.setActiveIndex(index)
      }
      event.stopPropagation();
      event.preventDefault();
    },

    selectMenu(href) {
      this.matchedPath = href;
    },

    async toggleMinimize() {
      this.setActiveIndex(null)
      this.toggleMinimizeMutation(!this.isMinimize);
      eventBus.$emit("sidebarChanged");

      await this.updateSidebarSettings()
    },
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";

.P-sidebar-menu {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 110px);
  &::-webkit-scrollbar {
    display: none;
  }
}
.P-sidebar-menu-list {
  position: relative;
}
.P-main-logo {
  width: 150px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: $transition;

  &.P-hide-logo {
    opacity: 0;
    visibility: hidden;
  }
}
.P-short-logo {
  width: 150px;

  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: $transition;
  &.P-show-minimize-logo {
    opacity: 1;
    visibility: visible;
  }
}

.P-sidebar-block {
  width: $sidebar;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: $z-index-sidebar;
  transition: $transition;
  &.P-minimize-sidebar {
    width: $minimize;

    .P-sidebar-footer {
      p {
        opacity: 0;
        visibility: hidden;
      }
    }

    .P-active-menu {
      &:before {
        background-color: transparent;
      }
    }
  }
  &.P-minimize-sidebar::v-deep {
    .P-icon-down {
      opacity: 0;
      visibility: hidden;
    }
    .P-menu-list-header {
      &:hover,
      &.router-link-active {
        &:before {
          background-color: transparent;
          height: 0 !important;
        }
      }
      p {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &.P-full-hide {
    width: 0 !important;
    overflow: hidden;

    .P-sidebar-footer {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.P-sidebar-header {
  padding: 15px 5px;
  position: relative;
  overflow: hidden;
  transition: $transition;
}

.P-sidebar-menu {
  ul {
    padding: 0;
  }
}

.P-menu-sub-list {
  transition: $transition;
  overflow: hidden;
  max-height: 0;

  &.P-open-sublist {
    max-height: 1000px;
    transition: 1s ease;
  }

  ul {
    li {
      width: 100%;

      a {
        display: block;
        font-size: $txt12;
        position: relative;
        padding: 12px 22px;
        padding-left: 55px;
        width: 100%;
        transition: $transition;
        text-transform: capitalize;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          height: 0;
          width: 3px;
          background-color: $primary-color;
          transition: $transition;
          content: "";
        }

        &:hover,
        &.router-link-active {
          &:before {
            height: 100%;
          }

          &.white--text {
            color: $primary-color !important;
          }
        }
      }
    }
  }
}

.P-active-menu {
  position: relative;
  background-color: $secondary-dark-66;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: $primary-color;
    transition: $transition;
    content: "";
  }
}
.P-sidebar-footer {
  height: 50px;
  padding: 0 15px;
  cursor: pointer;
  overflow: hidden;
  transition: $transition;
  opacity: 1;
  visibility: visible;
  p {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: $txt14;
    white-space: nowrap;
    transition: $transition;
    text-transform: capitalize;
  }
  .P-arrow-minimize {
    font-size: 20px;
  }
}

.theme--dark {
  .P-active-menu {
    background-color: $secondary-dark-66-dark;
  }
  .P-tooltip {
    background-color: rgba(255, 255, 255, 0.9) !important;
    p {
      color: $black !important;
    }
  }
}
</style>
