<template>
  <ott-dialog
      v-if="manageAdminModule.modal.isOpen"
      :is-open="manageAdminModule.modal.isOpen"
      :show-footer="false"
      :modal-title="'Company Settings'"
      close-value="addProvider"
      @outside="closeModal"
      @resetDataModal="closeModal"
      :width="1120"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <ul class="list">
              <li v-for="(i, index) in manageAdminModule.showList"
                  @click="clickList(index)"
                  :key="index"
                  :class="{
                  listActive: index === manageAdminModule.activeTab,
                }"
              >
                {{ i.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="main">
            <GeneralInfoAdmin v-if="manageAdminModule.activeTab===0"/>
            <CompanyInfoAdmin ref="companyInfo" @updateData="saveCompanyInfo" v-if="manageAdminModule.activeTab===1"/>
            <CompanyAddressesAdmin v-if="manageAdminModule.activeTab === 2 && permissionIs('getOttProviderAddresses')"/>
            <ContactAdmin @updateData="updateAdminMainTabs" v-if="manageAdminModule.activeTab===3 && ( permissionIs('getOttProviderEmails') || permissionIs('getOttProviderPhones') )"/>
            <PersonalInfoAdmin ref="companyPersonalInfo" @updateData="updateAdminMainTabs" v-if="manageAdminModule.activeTab===4"/>
            <InfoForClientsAppsAdmin v-if="manageAdminModule.activeTab === 5 && permissionIs('getOttProviderInfo')"/>
            <PriceGroupAdmin v-if="manageAdminModule.activeTab === 6 && permissionIs('getPriceGroups')"/>
            <PaymentMethodsAdmin v-if="manageAdminModule.activeTab===7 && permissionIs('getOttProviderPaymentMethods')"/>
            <InvoiceGenerationAdmin v-if="manageAdminModule.activeTab === 8 && permissionIs('getOttProviderInvoice')"/>
            <LabelPrintersAdmin v-if="manageAdminModule.activeTab === 9 && permissionIs('getOttProviderPrinters')"/>
            <SaleTaxesAdmin v-if="manageAdminModule.activeTab === 10 && permissionIs('getOttProviderSalesTax')"/>
            <PaymentGatewayAdmin v-if="manageAdminModule.activeTab === 11 && permissionIs('getOttProviderPaymentGateway')"/>
            <ShippingProvidersAdmin v-if="manageAdminModule.activeTab === 12 && permissionIs('getOttProviderShippingProvider')"/>
            <OtherApiIntegrationAdmin v-if="manageAdminModule.activeTab === 13 && permissionIs('getOttProviderOtherApi')"/>
            <ConversationApiAdmin v-if="manageAdminModule.activeTab === 14" :id="authProviderId" />
            <UiAndAccessAdmin v-if="manageAdminModule.activeTab === 15 && permissionIs('getOttProviderUi')"/>
            <CheckeeperLayout v-if="manageAdminModule.activeTab === 16" />

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div class="footer">
            <ott-button
                custom-class="neutral-sm-btn P-cancel"
                :text="'Exit'"
                :click="closeModal"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>

  import ManageAdminMixin from "./ManageAdminMixin";
  import PermissionsMixin from "@/mixins/PermissionsMixin";
  import GeneralInfoAdmin from "./generalInfo/GeneralInfo";
  import CompanyInfoAdmin from "./companyInfoAdmin/CompanyInfoAdmin";
  import ContactAdmin from "./contactAdmin/ContactAdmin";
  import PersonalInfoAdmin from "./personalInfoAdmin/PersonalInfoAdmin";
  import CompanyAddressesAdmin from "./companyAddressesAdmin/CompanyAddreses";
  import PaymentMethodsAdmin from "./paymentMethodsAdmin/PymentMethods";
  import PaymentGatewayAdmin from "./paymentGatewayAdmin/MerchantsList";
  import ShippingProvidersAdmin from "./shippingProvidersAdmin/ShippingProviders";
  import LabelPrintersAdmin from "./labelPrintersAdmin/label-printers";
  import UiAndAccessAdmin from "./uiAndAccessAdmin/UiAndAccess";
  import InfoForClientsAppsAdmin from "./InfoForClientsApps/ContactInfoForClientsApps";
  import SaleTaxesAdmin from "./saleTaxesAdmin/SaleTaxes";
  import InvoiceGenerationAdmin from "./invoiceGenerationAdmin/InvoiceGeneration";
  import OtherApiIntegrationAdmin from "./otherApiIntegrationAdmin/OtherApiIntegration";
  import PriceGroupAdmin from "./priceGroupAdmin/PriceGroup";
  import ConversationApiAdmin from "./conversationApi/ConversationApi"
  import CheckeeperLayout from "./checkeeperLayout/CheckeeperLayout";

  export default {
    name: "ManageAdmin",
    components: {
      PriceGroupAdmin,
      OtherApiIntegrationAdmin,
      InvoiceGenerationAdmin,
      SaleTaxesAdmin,
      InfoForClientsAppsAdmin,
      UiAndAccessAdmin,
      LabelPrintersAdmin,
      ShippingProvidersAdmin,
      PaymentGatewayAdmin,
      PaymentMethodsAdmin,
      CompanyAddressesAdmin, PersonalInfoAdmin, ContactAdmin, CompanyInfoAdmin, GeneralInfoAdmin, ConversationApiAdmin,
      CheckeeperLayout
    },
    mixins: [ ManageAdminMixin, PermissionsMixin ]
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";
  @import "./index";
</style>
