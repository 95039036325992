<template>
  <div class="P-personal-info">
    <h4 class="title-cont secondary--text">Admin Personal Info</h4>
    <div style="width: 50%">
      <div class="P-inputs">
        <div class="P-padding-100">
          <ott-input
            v-model="companyAddressesForm.firstName"
            :error="$v.companyAddressesForm.firstName.$error"
            label="First name*"
          />
        </div>
        <div class="P-padding-100">
          <ott-input
            v-model="companyAddressesForm.lastName"
            :error="$v.companyAddressesForm.lastName.$error"
            label="Last name*"
          />
        </div>
        <div class="P-padding-100">
          <ott-input
            v-model="companyAddressesForm.email"
            :error="isValidateEmail || $v.companyAddressesForm.email.$error"
            @emitFunc="validateEmail"
            label="Email Address*"
          />
        </div>
        <div class="P-padding-100">
          <ott-phone-number
            v-model="companyAddressesForm.phone"
            :error="(validPhoneNumber|| isValidateNumber || isValidatePhone || $v.companyAddressesForm.phone.$error) && showPhoneError"
            @onInput="validationPhoneNumber"
            @onChange="showPhoneError = true"
          />
        </div>
        <div class="P-padding-100">
          <password-input
            v-model="companyAddressesForm.password"
            :error="$v.companyAddressesForm.password.$error"
            label="Password"
          />
        </div>
        <div class="P-padding-100">
          <password-input
          v-model="companyAddressesForm.confirmPassword"
          :error="$v.companyAddressesForm.confirmPassword.$error"
          label="Confirm Password"
        />
        </div>
      </div>

      <div class="G-flex mt-2">
        <ott-button-loader
          @onClick="updateData()"
          :is-loading="isLoading"
          button-text="Save"
          class-name="primary-sm-btn"
        />
      </div>
      <div v-if="isValidateEmail || isValidatePhone" class="mt-2">
        <ErrorMessageContainer
          v-if="isValidateEmail"
          :message="errorMessage"
        />
        <ErrorMessageContainer
          v-if="isValidatePhone"
          message='The user with the current Phone is already created.'
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
  import PasswordInput from "@/components/PasswordInput";
  import allCountry from "@/constants/all-countries";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader"

  import {
    helpers,
    minLength,
    required,
    sameAs,
    email, requiredIf
  } from "vuelidate/lib/validators";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

  const letterAndNumber = helpers.regex(
    "letterAndNumber",
    /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/
  );
  export default {
    name: "PersonalInfoAdmin",
    components: {
      ErrorMessageContainer,
      PasswordInput,
      OttPhoneNumber,
      OttInput,
      OttButtonLoader
    },
    computed: {
      ...mapState({
        companyAddresses: state => state.personalInfoAdminModule,
        userData: state => state.auth.user,
        contactAdminModule: state => state.contactAdminModule,
        flagForEmail: state => state.personalInfoAdminModule.flagForEmail,
        personalInfoAdminModule: state => state.personalInfoAdminModule,
        manageAdminModule: state => state.manageAdminModule,
        flagForPhone: state => state.personalInfoAdminModule.flagForPhone,
        isLoading: state => state.appModule.isLoading,
      })
    },
    validations: {
      companyAddressesForm: {
        firstName: {required},
        lastName: {required},
        email: {required, email},
        phone: {required},
        password: {
          required: requiredIf(function () {
            return !this.manageAdminModule.providerId;
          }),
          minLength: minLength(8),
          letterAndNumber
        },
        confirmPassword: {
          required: requiredIf(function () {
            return !this.manageAdminModule.providerId;
          }), sameAsPassword: sameAs("password")
        }
      }
    },
    mounted() {
      this.companyAddressesForm = this.companyAddresses.companyAddressesForm;
      if (!this.companyAddressesForm.phone) {
        allCountry.forEach(item => {
          if (item.iso2 === this.userData?.geoInfo?.countryCode?.toUpperCase()) {
            this.companyAddressesForm.phone = "+" + item.dialCode;
          }
        });
      }
      if (this.contactAdminModule.phones.length && !this.flagForPhone && !this.manageAdminModule.providerId) {
        this.companyAddressesForm.phone = this.contactAdminModule.phones[0].phone;
      }
      if (this.contactAdminModule.emails.length && !this.flagForEmail && !this.manageAdminModule.providerId) {
        this.companyAddressesForm.email = this.contactAdminModule.emails[0].emailValue;
      }
      this.email = JSON.parse(JSON.stringify(this.companyAddresses.companyAddressesForm.email))
    },
    data() {
      return {
        companyAddressesForm: {},
        validPhoneNumber: false,
        isValidateEmail: false,
        isValidatePhone: false,
        isValidateNumber: false,
        errorMessage: '',
        email: null,
        showPhoneError: false
      };
    },
    methods: {
      ...mapActions({
        validatePhone: 'manageAdminModule/validatePhone',
        validateEmailUser: 'registration/validateEmailUser'
      }),

      ...mapMutations({
        validateEmailMutation: 'personalInfoAdminModule/validateEmail',
        setFlagEmail: 'personalInfoAdminModule/setFlagEmail',
        setCountryCode: 'personalInfoAdminModule/setCountryCode',
        setFlagPhone: 'personalInfoAdminModule/setFlagPhone',
        resetDataMutation: 'personalInfoAdminModule/resetData',
      }),

      async validationForm() {
        this.$v.companyAddressesForm.$touch();
        const body = {
          email: this.companyAddressesForm.email
        };
        const bodyPhone = {}
        bodyPhone.phone = this.companyAddressesForm.phone;
        if (this.manageAdminModule.providerId) {
          bodyPhone.providerId = this.manageAdminModule.providerId;
        }
        if (!this.$v.companyAddressesForm.$error && !this.validPhoneNumber && !this.isValidateNumber) {
          return await this.validatePhone(bodyPhone).then(async data => {
            if (!data) {
              if (this.email !== this.companyAddressesForm.email) {
                return await this.validateEmailUser(body)
                  .then(data => {
                    if (!data.result) {
                      this.isValidateEmail = false;
                      this.validateEmailMutation(!this.$v.companyAddressesForm.$error && !this.validPhoneNumber && !this.isValidateNumber);
                      return Promise.resolve(true)
                    } else {
                      this.isValidateEmail = true;
                      this.errorMessage = 'The user with the current E-mail is already created.'
                      this.validateEmailMutation(false);
                      return Promise.resolve(false)

                    }
                  }, error => {
                    this.isValidateEmail = true;
                    this.errorMessage = 'Incorrect Email Address'
                    this.validateEmailMutation(false);
                    return Promise.resolve(false)

                  });
              } else {
                this.validateEmailMutation(!this.$v.companyAddressesForm.$error && !this.validPhoneNumber);
                this.isValidatePhone = false;
                return Promise.resolve(true)
              }
            } else {
              this.isValidatePhone = true;
              this.validateEmailMutation(false);
              return Promise.resolve(false)
            }
          }, error => {
            this.isValidatePhone = true;
            this.validateEmailMutation(false);
            return Promise.resolve(false)
          })
        } else {
          return Promise.resolve(false)
        }

        // return !this.$v.companyAddressesForm.$error && !this.validPhoneNumber;
      },
      validateEmail() {
        this.$v.companyAddressesForm.email.$touch();
        this.setFlagEmail();
        this.validateEmailMutation(false);
        this.isValidateEmail = false
      },
      validationPhoneNumber() {
        const [phone, obj] = arguments;
        this.validPhoneNumber = !obj.isValid;
        this.$v.companyAddressesForm["phone"].$touch();
        this.setCountryCode(obj.regionCode);

        this.isValidatePhone = false
        let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
        this.isValidateNumber = !Number(str)
        this.setFlagPhone();
      },

      async updateData() {
        await this.validationForm().then(() => {
          if (this.personalInfoAdminModule.isValidatePersonalInfo) {
            this.$emit('updateData');
          }
        })

      },
      resetData() {
        this.resetDataMutation();
        this.companyAddressesForm = this.companyAddresses.companyAddressesForm;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .title-cont {
    color         : $secondary-color;
    margin-bottom : 20px;
    font-size     : $txt16;
    font-weight   : $semiBold;
  }

  .P-personal-info::v-deep {
    .v-text-field__details {
      margin-bottom : 0;
    }
  }

  .P-inputs {
    margin: 0 -10px;
  }
</style>
