<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header">
      <h3 class="secondary--text">Bot</h3>
    </div>
    <div class="P-modal-component-body">
      <div class="P-inputs">
        <div class="P-padding-50">
          <ott-input
            v-model="userData.telegramLogin"
            label="Login"
            :readonly="true"
          />
        </div>
        <div class="P-padding-50">
          <password-input
            v-model="userData.telegramPassword"
            label="Password"
            read-only
          />
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="P-settings" v-bind="attrs" v-on="on" @click="resetBotLoginPass">
              <span class="mdi mdi-refresh-circle neutral--text P-icon" />
            </div>
          </template>
          <span>Change Server</span>
        </v-tooltip>
        <div class="P-padding-50">
          <ott-button
            :click="getLink"
            text="Send"
            class="primary-sm-btn"
          />
        </div>
      </div>
    </div>

    <WarningModal
      v-if="isShowModal"
      :is-modal-open="isShowModal"
      modal-heading="Send BOT"
      btn-text="Send"
      :is-danger-button="false"
      :is-loading="isLoading"
      @closeModal="closeModal" 
      @btnFunction="sendBot"
    >
  
      <div slot="body">
        <ott-select
          v-model="selectedType"
          :items="allTypes"
          selected-text="name"
          label="Send Type"
          :error="$v.selectedType.$error"
        />
      </div>
    </WarningModal>
  </div>
</template>

<script>
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttButton from "@/components/vuetifyComponents/OttButton";
import PasswordInput from "@/components/PasswordInput";
import WarningModal from "@/components/WarningModal";

import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Bot",
  components: {
    OttInput,
    OttButton,
    PasswordInput,
    WarningModal,
    OttSelect
  },
  data() {
    return {
      formData: {
        login: '',
        password: ''
      },
      selectedType: '',
      allTypes: [],
      isLoading: false
    }
  },
  validations: {
    selectedType: { required },
  },
  mounted() {
    if (this.userData) {
      const phoneNumber = this.userData.phone?.phoneNumber
      if (phoneNumber) {
        this.allTypes.push({
          name: `Phone ${phoneNumber}`,
          value: `phone ${phoneNumber}`,
        })
      }

      const email = this.userData?.email
      if (email) {
        this.allTypes.push({
          name: `Email ${email}`,
          value: `email ${email}`,
        })
      }
    }
  },
  computed: {
    ...mapState({
      userData: state => state.users.userData,
      isShowModal: state => state.botUserModule.isShowModal
    }),
  },
  methods: {
    async resetBotLoginPass() {
        await this.generatePasswordTG({ id: this.userData.id, data: {} }).then(data => {
          if (data) {
            console.log(`reset bot login pass done`);
          }
        })
    },
    ...mapActions({
      getUserById: "users/getUserById",
      generatePasswordTG: "botUserModule/generatePasswordTG",
      sendPasswordTG: "botUserModule/sendPasswordTG"
    }),
    ...mapMutations({
      setData: "botUserModule/setData",
    }),
    getLink() {
      this.setData({ isShowModal: true })
    },
    closeModal() {
      this.setData({ isShowModal: false })
      this.resetData()
    },
    async sendBot() {
      this.$v.selectedType.$touch()

      if (!this.$v.selectedType.$error) {
        this.isLoading = true

        let type = ''
        if (this.selectedType.includes('phone')) {
          type = 'phone'
        } else {
          type = 'email'
        }

        let sendType = this.selectedType.trim().replace(/\bphone\b|\bemail\b/g, '')
        sendType = sendType.trim()

        const data = {
          type,
          value: sendType
        }

        await this.sendPasswordTG({ id: this.userData.id, data }).then(data => {
          if (data) {
            this.resetData()
          }
        })
      }
    },
    resetData() {
      this.selectedType = ''
      this.isLoading = false
      this.$v.selectedType.$reset()
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../../../../src/assets/scss/variables";
@import "./index";
.P-inputs {
  margin: 0 -10px;
}
.get-link-btn {
  width: 50%;
}
</style>