<template>
  <div class="P-modal-components">
    <div class="P-modal-component-body P-tax-block">
      <div class="P-modal-component-header G-justify-between">
        <h3 class="secondary--text">Service tax</h3>
      </div>
      <div class="P-tax-percent-block G-flex G-align-center">
        <div class="P-switch-box">
          <ott-switch
            v-model="saleTaxes.isServiceTax"
            label="By taxjar API"
          />
        </div>
        <div class="P-tax-input">
          <ott-input
            v-if="!saleTaxes.isServiceTax"
            v-model="serviceTax"
            :maxLength="5"
            :customInputClass="'P-percent'"
            :focus="focusServiceTax"
            :error="errorServiceTax"
            :blur="blurServiceTax"
            label="Percent"
          />
        </div>
      </div>

      <div class="P-modal-component-header">
        <h3 class="secondary--text">Product tax</h3>
      </div>
      <div class="P-tax-percent-block G-flex G-align-center">
        <div class="P-switch-box">
          <ott-switch
            v-model="saleTaxes.isProductsTax"
            label="By taxjar API"
          />
        </div>
        <div class="P-tax-input">
          <ott-input
            v-if="!saleTaxes.isProductsTax"
            v-model="productTax"
            :is-percent="true"
            :maxLength="5"
            :customInputClass="'P-percent-product'"
            :focus="focusProductTax"
            :blur="blurProductTax"
            :error="errorProductTax"
            label="Percent"
            class="P-product-tax"
          />
        </div>
      </div>

      <div class="G-flex">
        <ott-button-loader
          @onClick="saveServieTaxes()"
          button-text="Save"
          class-name="primary-sm-btn"
        />
      </div>
      <br />

      <div class="P-modal-component-header G-justify-between G-align-center">
        <h3 class="secondary--text">Country tax</h3>
        <div
          v-if="!saleTaxes.isAddMode && saleTaxes.countryTaxList.length"
          @click="addNewCountryTax"
          class="icon-cont"
        >
          <span class="mdi mdi-plus neutral--text" />
        </div>
      </div>
      <div class="P-country-tax-block">
        <manage-country-tax
          v-if="saleTaxes.isAddMode || !saleTaxes.countryTaxList.length"
          ref="manageCountryTax"
        />
        <country-tax-list v-else />
      </div>
    </div>
    <template v-if="!saleTaxes.countryTaxList.length">
      <div class="G-flex">
        <ott-button-loader
          :is-loading="isLoading"
          @onClick="addServieTaxes()"
          button-text="Add"
          class-name="primary-sm-btn"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import ManageCountryTax from "./ManageCountryTax";
import CountryTaxList from "./CountryTaxList";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "SaleTaxesAdmin",
  components: {
    CountryTaxList,
    ManageCountryTax,
    OttSelect,
    OttInput,
    OttSwitch,
    OttButtonLoader,
  },
  data() {
    return {
      errorProductTax: false,
      errorServiceTax: false,
    };
  },
  computed: {
    ...mapState({
      saleTaxes: (state) => state.saleTaxesAdmin,
      companyId: (state) => state.auth.user.provider.id,
      isLoading: (state) => state.appModule.isLoading
    }),

    productTax: {
      get() {
        return this.saleTaxes.productTax;
      },
      set(value) {
        this.errorProductTax = +value < 0;
        let x = value;
        if (value > 100) {
          x = 100;
        }
        this.setProductTax(x);
      },
    },
    serviceTax: {
      get() {
        return this.saleTaxes.serviceTax;
      },
      set(value) {
        let x = value;
        if (value > 100) {
          x = 100;
        }
        this.errorServiceTax = +value < 0;
        this.setServiceTax(x);
      },
    },
  },

  async mounted() {
    if (this.companyId) {
      await this.getSaleTaxData(this.companyId);
    }
  },

  methods: {
    ...mapActions({
      getSaleTaxData: 'saleTaxesAdmin/getSaleTaxData',
      updateSaleTaxData: 'saleTaxesAdmin/updateSaleTaxData'
    }),

    ...mapMutations({
      setProductTax: 'saleTaxesAdmin/setProductTax',
      setServiceTax: 'saleTaxesAdmin/setServiceTax',
      addNewCountryTax: 'saleTaxesAdmin/addNewCountryTax',
      resetServiceTax: 'saleTaxesAdmin/resetServiceTax',
      resetProductTax: 'saleTaxesAdmin/resetProductTax',
      toSaveData: 'saleTaxesAdmin/toSaveData',
    }),

    focusProductTax() {
      if (this.productTax || this.productTax == 0) {
        let x = this.productTax.toString().split("%")[0];
        this.setProductTax(+x);
      }
    },

    blurProductTax() {
      if (this.productTax || this.productTax == 0) {
        let x = +this.productTax + "%";
        this.setProductTax(x);
      }
    },

    focusServiceTax() {
      if (this.serviceTax || this.serviceTax == 0) {
        let x = this.serviceTax.toString().split("%")[0];
        this.setServiceTax(+x);
      }
    },

    blurServiceTax() {
      if (this.serviceTax || this.serviceTax == 0) {
        let x = +this.serviceTax + "%";
        this.setServiceTax(x);
      }
    },

    async saveServieTaxes() {
      let resetServiceApi = false;
      let serviceTaxApi = false;

      if (this.productTax && this.serviceTax) {
        this.focusServiceTax();
        this.focusProductTax();
        serviceTaxApi = true;
      }

      if (this.productTax && !this.serviceTax) {
        this.focusProductTax();
        serviceTaxApi = true;
      }

      if (!this.productTax && this.serviceTax) {
        this.focusServiceTax();
        serviceTaxApi = true;
      }

      //resets
      if (this.saleTaxes.isServiceTax && this.saleTaxes.isProductsTax) {
        this.resetServiceTax();
        this.resetProductTax();
        resetServiceApi = true;
      }

      if (!this.saleTaxes.isServiceTax && this.saleTaxes.isProductsTax) {
        this.resetProductTax();
        resetServiceApi = true;
      }

      if (this.saleTaxes.isServiceTax && !this.saleTaxes.isProductsTax) {
        this.resetServiceTax();
        resetServiceApi = true;
      }

      if (!this.saleTaxes.isServiceTax && !this.saleTaxes.isProductsTax) {
        resetServiceApi = true;
      }

      if (resetServiceApi || serviceTaxApi) {
        this.toSaveData();
        await this.updateSaleTaxData(this.companyId);
      }
    },

    async addServieTaxes() {
      await this.$refs["manageCountryTax"].addTaxData();
    },
  },  
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-modal-component-header {
  margin-bottom: 20px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-tax-input {
  max-width: 120px;
  width: 100%;
  margin-left: 20px;
}

.P-switch-box {
  min-width: 120px;
}

.P-tax-percent-block {
  margin-bottom: 20px;
  height: 38px;
}

.P-tax-block::v-deep {
  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 10px;
  }

  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }

  .v-input--selection-controls__input {
    margin-top: 5px;
  }
}
</style>
