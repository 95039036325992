<template>
  <div class="P-modal-component-header G-justify-between G-align-center">
    <h3 class="secondary--text">{{titleText}}</h3>
    <div v-if="isAdd" class="icon-cont" @click="click">
      <span class='mdi mdi-plus neutral--text'/>
    </div>
    <div v-if="isEdit" class="icon-cont" @click="click">
      <span class='mdi mdi-pencil neutral--text'/>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TitleComponent',
    props: {
      isAdd: {
        type: Boolean,
        default: false
      },
      click: {
        type: Function,
        default: () => null
      },
      titleText: {
        type: String,
        default: ''
      },
      isEdit: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "src/assets/scss/variables";
  .P-modal-component-header {
    margin-bottom: 10px;
    min-height: 40px;

    h3 {
      font-size: $txt16;
      font-weight: $semiBold;
      text-transform: capitalize;
    }
  }
</style>