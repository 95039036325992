<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header">
      <h3 class="secondary--text">Security & Login</h3>
    </div>
    <div class="P-modal-component-body">
      <div class="P-security-block">
        <div class="P-input-box">
          <OttInput
            v-model="email"
            :error="$v.formData.email.$error"
            label="Email Address*"
          />
        </div>
        <div
          v-if="users.userId"
          class="P-modal-component-header P-change-password"
        >
          <h3 class="secondary--text">Change Password</h3>
        </div>
        <div class="P-input-box">
          <password-input
            v-model="password"
            :error="$v.formData.password.$error"
            label="Password*"
          />
        </div>
        <div class="P-input-box P-confirm-password">
          <PasswordStrength
            :inputValue="confirmPassword"
            :error="$v.formData.confirmPassword.$error"
            @emitFunc="changePassword"
            label="Confirm Password*"
          />
        </div>
        <p class="neutral--text P-password-text">
          The password must contain at least 8 characters, 1 letter, 1 number
          and 1 symbol
        </p>
        <ErrorMessageContainer v-if="isValidateEmail" :message="errorMessage" />
      </div>
    </div>
    <div v-if="users.userId" class="P-save-btn-block G-flex">
      <ott-button-loader
        :isLoading="isLoading"
        @onClick="$emit('editUser')"
        button-text="Save"
        class-name="primary-sm-btn"
      />
    </div>
  </div>
</template>
<script>
import SecurityLoginMixin from "./SecurityLoginMixin";
// components
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import PasswordStrength from "@/components/PasswordStrength";
import PasswordInput from "@/components/PasswordInput";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

export default {
  name: "SecurityLogin",
  components: {
    ErrorMessageContainer,
    PasswordInput,
    PasswordStrength,
    OttInput,
    OttSwitch,
  },
  mixins: [SecurityLoginMixin],
};
</script>

<style lang="scss" scoped>
@import "./index";
.P-security-block {
  max-width: 380px;
}
.P-input-box {
  & + .P-input-box {
    margin-top: 20px;
  }
}
.P-password-text {
  font-size: $txt12;
  margin-bottom: 20px;
}
.P-confirm-password::v-deep {
  .password-range {
    margin-top: 20px;
  }
  .Password__strength-meter {
    margin-bottom: 12px;
  }
}

.P-change-password {
  margin-top: 25px;
}
</style>