<template>
  <v-main>
    <SidebarMenu />
    <div
      :class="{
        'P-minimize-main-container': isMinimize,
        'P-full-hide': isFullHide
      }"
      class="P-main-container   mainBackground"
    >
      <SidebarHeader />
      <div 
        class="P-main-pages mainBackground" 
        :class="{
          'P-page-search': $route.name === 'Search'
        }"
      >
        <router-view></router-view>
      </div>
    </div>
  </v-main>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import SidebarHeader from "@/components/sidebar/SidebarHeader";

export default {
  components: {
    SidebarMenu,
    SidebarHeader
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.loggedIn,
      isMinimize: state => state.sidebar.isMinimize,
      isFullHide: state => state.sidebar.isFullHide,      
      authUserId: state => state.auth.user.id,
      clientActivityModel: state => state.clientActivityModule.filterModel,
      clientBillsFilterModel: state => state.clientBillsModule.filterModel,
      clientsFilterModel: state => state.clientsModule.filterModel,
      discountFilterModel: state => state.discountModule.filterModel,
      equipmentsFilterModel: state => state.equipmentsModule.filterModel,
      equipmentsTypesFilterModel: state => state.equipmentsTypesModule.filterModel,
      flussonicsFilterModel: state => state.flussonicsModule.filterModel,
      packagesFilterModel: state => state.packagesModule.filterModel,
      providersFilterModel: state => state.providers.filterModel,
      reviewFilterModel: state => state.review.filterModel,
      shippingsFilterModel: state => state.shippingsModule.filterModel,
      transactionsFilterModel: state => state.transactionsModule.filterModel,
      usersFilterModel: state => state.users.filterModel,
      usersActivityFilterModel: state => state.usersActivityModule.filterModel
    }),

    routePath() {
      return this.$route.path
    }
  },

  watch: {
    routePath(newRoute, oldRoute) {
      setTimeout(() => {
        this.generalSetData({
          isSelectedAll: false
        })
      },100)

      // After every page opening reseting that page's filters
      switch(newRoute) {
        case '/review':
          this.reviewFilterModel?.reset()
          break;
        case '/providers-resellers':
          this.providersFilterModel?.reset()
          break;
        case '/users/list':
          this.usersFilterModel?.reset()
          break;
        case '/users/activity':
          this.usersActivityFilterModel?.reset()
          break;
        case '/clients/list':
          this.clientsFilterModel?.reset()
          break;
        case '/clients/activity':
          this.clientActivityModel?.reset()
          break;
        case '/clients/payments': 
          this.clientBillsFilterModel?.reset()
          break;
        case '/transactions':  
          this.transactionsFilterModel?.reset()
          break;
        case '/packages':
          // this.packagesFilterModel?.reset()
          break;
        case '/equipments/types':
          this.equipmentsTypesFilterModel?.reset()
          break;
        case '/equipments/list':
          this.equipmentsFilterModel?.reset()
          break;
        case '/discounts':
          this.discountFilterModel?.reset()
          break;
        case '/flussonics':
          this.flussonicsFilterModel?.reset()
          break;
        case '/shippings':
          this.shippingsFilterModel?.reset()
          break;
      }
    }
  },

  methods: {
    ...mapActions({
      getUserById: "users/getUserById",
      getSidebarSettings: 'sidebar/getSettings',
    }),

    ...mapMutations({
      updateAuthUser: 'auth/updateAuthUser',
      generalSetData: 'general/setData'
    })
  },
  async created() {
    if (this.loggedIn) {
      // If loggedIn, or if there is save auth data and refresh page, update auth user data
      await this.getUserById(this.authUserId).then((data) => this.updateAuthUser(data))
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/variables";

.P-main-container {
  margin: 0 auto;
  width: calc(100% - 280px);
  height: 100%;
  margin-right: 0;
  transition: $transition;
  &.P-minimize-main-container {
    width: calc(100% - 60px);
  }
  &.P-full-hide {
    width: 100% !important;
  }
}

.P-main-pages {
  height: calc(100vh - 170px);
  overflow: hidden;
  overflow-y: auto;
}

.P-page-search {
  height: calc(100vh - 255px) !important;
}
</style>
