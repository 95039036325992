<template>
  <ott-dialog
    v-if="users.modal.isOpen"
    @outside="cancel"
    @resetDataModal="resetDataModal"
    :is-open="users.modal.isOpen"
    :show-footer="false"
    :modal-title="users.userId ? 'Edit User' : 'Add User'"
    :width="1040"
    close-value="addProvider"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7">
          <div>
            <ul class="list">
              <li
                v-for="(i, index) in filteredTabsList"
                @click="clickList(index)"
                :key="index"
                :class="{
                  listActive: index === users.activeTab,
                  'P-disable-tab': index > users.disableTabs,
                }"
              >
                {{ i.name }}
              </li>
            </ul>
          </div>
          <!-- <ott-button
            v-if="!users.userId"
            style="width: 179px; height: 33px"
            custom-class="primary-sm-btn"
            text="Save and Close"
            :disabled="users.disableButton"
            :click="saveAndCloseData"
          /> -->
        </div>
        <div class="right">
          <div class="main">
            <GeneralInfo
              @editUser="updateUser()"
              v-show="activeTab === 0"
              ref="generalInfo"
            />
            <SecurityLogin
              @editUser="updateUser()"
              v-show="activeTab === 1"
               ref="securityLogin"
            />
            <Role
              @editUser="updateUser()"
              v-show="activeTab === 2 && users.showRoles"
              ref="roleUser"
            />
            <Bot
              v-show="activeTab === 3 && users.showRoles || isEquipmentInstaller"
              ref="bot"
            />

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div class="footer" v-if="!users.userId">
            <ott-button-loader
              @onClick="saveAndContinue"
              :is-loading="isLoading"
              :button-text="activeTab !== 2 ? 'Continue' : 'Save'"
              :icon-after="activeTab !== 2"
              className="primary-sm-btn P-login-btn"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import ManageUserMixin from "./ManageUserMixin";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  components: { OttButtonLoader },
  mixins: [ManageUserMixin],
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 226px;
    padding: 13px 13px 26px 13px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    & .list {
      padding: 5px 10px 10px 0;
      margin: 10px -15px 20px 0;
      overflow-y: scroll;
      height: 618px;
      text-transform: capitalize;

      li + li {
        margin-top: 3px;
      }

      & > li {
        padding: 7px 0 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        height: 33px;
        transition: $transition;

        &.P-disable-tab {
          color: $neutral-color;
          cursor: default;
        }

        &:hover:not(.P-disable-tab) {
          background-color: $neutral-color25;
          color: $secondary-color;
        }
      }
    }
  }

  .right {
    padding: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 0 -24px;
      padding: 20px 24px 0;

      & > :nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}

</style>
