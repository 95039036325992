var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._b({tag:"component",attrs:{"active":'P-active-link'}},'component',[_vm.isRouterLink ? { to: _vm.href } : { href: _vm.href }],false),[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.showTooltip ? _vm.tooltipData : '',
      placement: 'right',
      popperClass: 'tooltip-content',
      autoHide: true,
      distance: 5,
      html: true  
    }),expression:"{\n      content: showTooltip ? tooltipData : '',\n      placement: 'right',\n      popperClass: 'tooltip-content',\n      autoHide: true,\n      distance: 5,\n      html: true  \n    }"}],staticClass:"P-menu-list-header G-align-center",class:{ 'P-open-menu': _vm.isOpenSublist }},[_c('span',{staticClass:"mdi neutral--text P-icon",class:_vm.item.icon}),_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.item.title))]),(_vm.item.child && _vm.item.child.length)?_c('span',{staticClass:"mdi mdi-chevron-down P-icon-down white--text",class:{ 'P-open-sub': _vm.isOpenSublist }}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }