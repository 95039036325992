<template>
  <div class="P-table">
    <v-data-table
      :headers="header"
      :items="tableData"
      :items-per-page="9999"
      dense
      hide-default-header
      hide-default-footer
      item-key="name"
      class="cont-table"
    >
      <template v-slot:header>
        <th v-for="(i, index) in header" :key="index" class="secondary--text ps-2">
          {{ i }}
        </th>
        <th></th>
      </template>
        <template v-slot:item="{ item, index }">
        <tr class="P-hovered-tr ps-2">
          <template v-for="(bodyItem, index) in item">
            <td
              :class="{
                'P-disable-list': typeof bodyItem !== 'boolean' && item.inUse,
              }"            
              :key="index"
              v-if="
                index !== 'useForSent' &&
                index !== 'value' &&
                index !== 'id' &&
                index !== 'providerId'
              "
            >
              <span
                v-if="
                  typeof bodyItem !== 'boolean' &&
                  index !== 'useForSent' &&
                  index !== 'value'
                "
              >
                {{ bodyItem }}
              </span>
              <span
                v-else-if="bodyItem && index !== 'useForSent'"
                class="mdi mdi-check checkTable"
              />
            </td>
          </template>
          <td class="actionList">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list
                  class="modalBackground"
                  :class="{ 'P-list-actions': tableData.length === 1 && !tax }"
                >
                  <v-list-item
                    v-for="(itemAction, i) in itemsAction"
                    :key="i"
                    @click="itemClick(itemAction.title, index, item)"
                  >
                    <v-list-item-title>{{
                      itemAction.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false" 
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { AddProviderModel } from "@/models/providers/addProviderModel";
import { ContactInfoModel } from "@/models/providers/contactInfoModel";
import OttMenu from "@/components/vuetifyComponents/OttMenu";
import WarningModal from "@/components/WarningModal"

export default {
  name: "TableContent",
  components: { OttMenu, WarningModal },
  props: {
    tax: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: null,
    },
    header: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemsAction: [{ title: "Edit" }, { title: "Delete" }],
      emailOrPhone: {},
      emailOrPhoneIndex: null,
      showWarningModal: false,
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      warningModalType: "",
    };
  },
  computed: {
    ...mapState({
      contactAdminModule: (state) => state.contactAdminModule,
      companyInfoAdminModule: (state) => state.companyInfoAdminModule,
      adminPersonalInfoModule: (state) => state.personalInfoAdminModule,
      manageAdminModule: (state) => state.manageAdminModule,
    }),

    warningModalHeading() {
      return `${this.warningModalBtnText} company ${this.warningModalType}?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> company <span class="text-lowercase">${this.warningModalType}</span> ${this.warningModalType === 'phone' ? '<br />' : ''} <ins>${this.emailOrPhone.emailValue ? this.emailOrPhone.emailValue : this.emailOrPhone.phone}</ins>. If you're not sure, you can cancel this operation.`
    },
  },
  methods: {
    ...mapActions({
      deleteEmailAction: 'contactAdminModule/deleteEmail',
      manageEmail: 'contactAdminModule/manageEmail',
      deletePhoneAction: 'contactAdminModule/deletePhone',
      managePhone: 'contactAdminModule/managePhone',
      editAdmin: 'manageAdminModule/editAdmin',
    }),

    ...mapMutations({
      setError: 'contactAdminModule/setError',
      openEditEmail: 'contactAdminModule/openEditEmail',
      openEditPhone: 'contactAdminModule/openEditPhone',
      deleteEmailMutation: 'contactAdminModule/deleteEmail',
      deletePhoneMutation: 'contactAdminModule/deletePhone',
      setProviderId: 'manageAdminModule/setProviderId',
      setProviderData: 'manageAdminModule/setProviderData',
      updateContactData: 'contactAdminModule/updateContactData',
      updateCompanyInfo: 'companyInfoAdminModule/updateCompanyInfo',
      updatePersonalInfo: 'personalInfoAdminModule/updatePersonalInfo',
    }),

    itemClick() {
      const [value, index, data] = arguments;
      if (this.name === "email" && value === "Edit") {
        this.setError({
          value: false,
          message: "",
        });
        this.openEditEmail(index);
      } else if (this.name === "email" && value === "Delete") {
        this.openDeleteModal({title: this.name, item : data, index})
      }

      if (this.name === "phone" && value === "Edit") {
        this.openEditPhone(index);
      } else if (this.name === "phone" && value === "Delete") {
        this.openDeleteModal({title: this.name, item : data, index})       
        
      }
    },

    /**
     * Warning modal functions
     * **/

    openDeleteModal(e) {
      this.warningModalType = e.title
      this.emailOrPhoneIndex = e.index
      this.emailOrPhone = e.item;

      this.warningModalIsLoading = false;
      this.warningModalBtnText = "Delete";
      this.showWarningModal = true;
    },
    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.warningModalIsLoading = false;
      });
    },
    async warningModalBtnFunction() {
      this.warningModalIsLoading = true;
      const body = {
        index : this.emailOrPhoneIndex,
        data : this.emailOrPhone,
      };

      if (this.warningModalType === "email") {
        this.deleteEmailMutation(body);
        if (this.manageAdminModule.providerId) {
          await this.deleteEmailAction(this.emailOrPhone.id);

          if (this.emailOrPhone.inMain) {
            const email = new ContactInfoModel();
            let x = email.manageEmail(
              this.contactAdminModule.emails[0],
              this.manageAdminModule.providerId
            );
            const bodyEmail = {
              id: this.contactAdminModule.emails[0].id,
              data: x,
            };
            await this.manageEmail(bodyEmail)
              .then(async () => {
                await this.updateProviderData();
              });
          } else {
            await this.updateProviderData();
          }
        }
      }else {
        this.deletePhoneMutation(body);
          
        if (this.manageAdminModule.providerId) {
          await this.deletePhoneAction(this.emailOrPhone.id);

          if (this.emailOrPhone.inMain) {
            const phone = new ContactInfoModel();
            let x = phone.managePhone(
              this.contactAdminModule.phones[0],
              this.manageAdminModule.providerId
            );
            const bodyPhone = {
              id: this.contactAdminModule.phones[0].id,
              data: x,
            };

            await this.managePhone(bodyPhone)
              .then(async () => {
                await this.updateProviderData();
              });
          } else {
            await this.updateProviderData();
          }
        }
      }

      this.showWarningModal = false; 
    },
    async updateProviderData() {
      const provider = new AddProviderModel();
      const data = provider.updateData(
        this.companyInfoAdminModule.formData,
        this.contactAdminModule,
        this.adminPersonalInfoModule.companyAddressesForm,
        this.manageAdminModule.providerId
      );
      await this.editAdmin(data).then((data) => {
        if (data) {
          this.updateData(data);
        }
      });
    },
    updateData(data) {
      this.setProviderId(data.id);
      this.setProviderData(data);
      this.updateContactData(data);
      this.updateCompanyInfo(data);
      this.updatePersonalInfo(data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.cont-table {
  & th {
    text-align: left;
  }

  & td {
  }

  & .checkTable {
    color: $green;
  }
}

.actionList::v-deep {
  & .v-list .v-sheet {
    background-color: red !important;
  }
}

.P-table {
  padding: 10px 20px 0 20px;
  border-radius: 3px;
  box-shadow: 0 0 5px #284e6626;

  th {
    font-size: $txt12;
    padding: 10px 0;
    text-transform: capitalize;
  }

  th,
  td {
    min-width: 40px;
    max-width: 50px;

    &:first-child {
      min-width: 100px;
      max-width: 100px;
      padding: 0 0 0 3px !important;
    }

    &:last-child {
      width: 20px;
    }

    position: relative;
    transition: $transition;
  }

  tr {
    td {
      height: 47px !important;
    }
  }
}

.theme--dark {
  &.v-list {
    background-color: #3c4043;
  }

  .v-data-table {
    background-color: transparent;
  }

  .P-table {
    background-color: rgba(48, 49, 52, 0.43);
  }

  tr {
    td {
      border-top: 1px solid $neutral-color45;
      padding: 15px 0;
      transition: $transition;
      color: $white !important;
    }
  }

  tr:hover {
    background-color: transparent !important;

    td {
      color: $primary-colorDM !important;
    }

    .P-actions {
      opacity: 1;
      visibility: visible;
    }
  }

  .v-menu__content {
    box-shadow: 0px 0px 20px #00000040;
  }
}

.P-table::v-deep {
  .v-data-table__wrapper {
    overflow: hidden !important;
  }

  position: relative;


  tr:hover {
    background-color: transparent !important;

    td {
      color: $primary-color;
    }

    .P-actions {
      opacity: 1;
      visibility: visible;
    }
  }

  .P-actions {
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
}

.P-list-actions {
  .v-list-item {
    &:last-child {
      display: none;
    }

    &:first-child {
      border-bottom: 1px solid transparent !important;
    }
  }
}

.v-data-table
  > .v-data-table__wrapper
  tbody
  tr:last-child:hover
  td:first-child {
  border-radius: 0;
}

.v-data-table > .v-data-table__wrapper tbody tr:hover td:first-child {
  border-radius: 0;
}

.v-data-table > .v-data-table__wrapper tbody tr:hover td:last-child {
  border-radius: 0;
}
.P-disable-list {
  opacity: 0.5;
}
</style>
