<template>
  <div class="P-manage-label-printers">
    <div class="P-full-line">
      <div class="P-padding-100">
        <ott-select
          v-model="address"
          :items="addresses"
          :error="$v.address.$error"
          label="Office/Address*"
          selected-text="name"
        />
      </div>
    </div>
    <div class="P-full-line G-flex">
      <div class="P-padding-50">
        <ott-input
          v-model="ipAddress"
          :error="errorDuplicate || $v.ipAddress.$error"
          label="IP Address Or Domain Name*"
        />
      </div>
      <div class="P-padding-50">
        <ott-input
          v-model="port"
          :is-percent="true"
          :maxLength="5"
          :customInputClass="'P-smpt-port'"
          :error="validatePort || errorDuplicate || $v.port.$error"
          @emitFunc="validateSMTPPort"
          label="Port*"
        />
      </div>
    </div>
    <div class="P-full-line">
      <div class="P-padding-100">
        <ott-input label="Printer model" v-model="printerModel" />
      </div>
    </div>

    <div class="P-full-line G-align-center">
      <div class="P-padding-20">
        <ott-select
          v-model="pagesPerSheet"
          :items="pagesPerSheetList"
          label="Pages per sheet"
          selected-text="name"
        />
      </div>

      <div class="P-padding-50">
        <v-radio-group v-model="isReceiptPrinter">
          <div class="P-full-line G-flex">
            <div class="P-radio-box">
              <ott-radio
                v-model="printerType.isShippingLabel"
                label="Is shipping label printer"
              />
            </div>
            <div class="P-radio-box">
              <ottRadio
                v-model="printerType.isReceiptPrinter"
                label="Is receipt printer"
              />
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>

    <div class="P-full-line P-buttons G-flex">
      <div class="P-checkbox">
        <save-reset-buttons
          v-if="labelPrinters.labelPrintersList.length"
          :right-label="isEditMode ? 'Save' : 'Add'"
          :is-loading="isLoading"
          :right-click-func="() => addLabelPrinter()"
          :left-click-func="() => cancel()"
        />
        
        <ott-button-loader
          v-else
          @onClick="addLabelPrinter()"
          button-text="Save"
          class-name="primary-sm-btn"
          :is-loading="isLoading"
        />
      </div>
    </div>
    <ErrorMessageContainer
      v-if="errorDuplicate"
      :message="'Duplicate IP address or Domain name and Port'"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import _ from 'lodash';
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import allCountry from "@/constants/all-countries";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "ManageLabelPrinters",
  components: {
    ErrorMessageContainer,
    OttRadio,
    OttButton,
    OttInput,
    OttSelect,
    OttButtonLoader,
    SaveResetButtons,
  },
  validations: {
    ipAddress: { required },
    port: { required },
    address: { required },
  },
  data() {
    return {
      countryList: allCountry,
      validatePort: false,
      errorDuplicate: false,
      addresses: [],
      pagesPerSheetList: [
        {
          name: 1,
          value: 1
        },
        {
          name: 2,
          value: 2
        }
      ]
    };
  },
  computed: {
    ...mapState({
      addressList: (state) => state.companyAddressesAdmin.addressesList,
      labelData: (state) => state.labelPrintersAdmin.labelData,
      labelPrinters: (state) => state.labelPrintersAdmin,
      printerType: (state) => state.labelPrintersAdmin.printerType,
      isEditMode: (state) => state.labelPrintersAdmin.isEditMode,
      isAddLabel: (state) => state.labelPrintersAdmin.isAddLabel,
      addProviderModal: (state) => state.manageAdminModule,
      isLoading: (state) => state.appModule.isLoading,
    }),
    address: {
      get() {
        return this.labelData.address;
      },
      set(value) {
        this.setAddress(value);
      },
    },
    ipAddress: {
      get() {
        return this.labelData.ipAddress;
      },
      set(value) {
        this.setIpAddress(value);
      },
    },
    port: {
      get() {
        return this.labelData.port;
      },
      set(value) {
        this.setPort(value);
      },
    },
    printerModel: {
      get() {
        return this.labelData.printerModel;
      },
      set(value) {
        this.setPrinterModel(value);
      },
    },
    pagesPerSheet: {
      get() {
        return this.labelData.pagesPerSheet;
      },
      set(value) {
        this.setPagesPerSheet(value);
      },
    },
    isReceiptPrinter: {
      get() {
        return this.labelData.isReceiptPrinter;
      },
      set(value) {
        this.setIsReceiptPrinter(value);
      },
    },
  },

  mounted() {
    if (this.addressList.length) {
      this.addresses = _.clone(this.addressList)
      this.addresses.map(item => item.name = item.name.replace('<br />', ' '))
    }
  },

  methods: {
    ...mapActions({
      addLabelPrinterAction: 'labelPrintersAdmin/addLabelPrinter',
      updateLabelPrinter: 'labelPrintersAdmin/updateLabelPrinter',
      getLabelPrinterList: 'labelPrintersAdmin/getLabelPrinterList',
    }),

    ...mapMutations({
      setAddress: 'labelPrintersAdmin/setAddress',
      setIpAddress: 'labelPrintersAdmin/setIpAddress',
      setPort: 'labelPrintersAdmin/setPort',
      setPrinterModel: 'labelPrintersAdmin/setPrinterModel',
      setPagesPerSheet: 'labelPrintersAdmin/setPagesPerSheet',
      setIsReceiptPrinter: 'labelPrintersAdmin/setIsReceiptPrinter',
      toSaveData: 'labelPrintersAdmin/toSaveData',
      addLabelList: 'labelPrintersAdmin/addLabelList',
      closeEdit: 'labelPrintersAdmin/closeEdit',
      resetData: 'labelPrintersAdmin/resetData',
    }),
    /**
     * Validate port
     * **/

    validateSMTPPort() {
      if (+this.port >= 65535) {
        this.port = 65534;
      }
      this.validatePort = this.port < 1;
      this.validatePort = this.port.toString().split("")[0] === "0";
    },

    /**
     * Get duplicate printers
     * **/

    duplicatePrinters() {
      let isValidate = false
      if (this.labelPrinters?.labelPrintersList) {
        if (this.labelPrinters.labelPrintersList.length) {
          if (this.labelPrinters.editedIndex || this.labelPrinters.editedIndex===0) {
            isValidate = this.labelPrinters.labelPrintersList.some((x, index) => 
              x.ipAddress === this.ipAddress && +x.port === +this.port && index !== this.labelPrinters.editedIndex
            )
          } else {
            isValidate = this.labelPrinters.labelPrintersList.some((x, index) => x.ipAddress === this.ipAddress && +x.port===+this.port)
          }
        }
      }
      this.errorDuplicate = isValidate
      return isValidate
    },

    /**
     * Add label Printer
     * **/
    async addLabelPrinter() {
      this.$v.ipAddress.$touch();
      this.$v.port.$touch();
      this.$v.address.$touch();
      if (
        !this.$v.ipAddress.$error &&
        !this.$v.port.$error &&
        !this.$v.address.$error &&
        !this.validatePort
      ) {
        if (!this.duplicatePrinters()) {
          if (!this.isEditMode) {
            this.toSaveData(this.addProviderModal.providerId);
            await this.addLabelPrinterAction(this.addProviderModal.providerId)
              .then(async (data) => {
                if (data) {
                  await this.getLabelPrinterList(this.addProviderModal.providerId)
                    .then((dataList) => {
                      this.addLabelList();
                    });
                }
              });
          } else {
            this.toSaveData(this.addProviderModal.providerId);
            await this.updateLabelPrinter(this.labelData.id)
              .then(async (data) => {
                if (data) {
                  await this.getLabelPrinterList(this.addProviderModal.providerId)
                    .then((dataList) => {
                      this.addLabelList();
                      this.closeEdit();
                    });
                }
              });
          }
        }
      }
    },
    async cancel() {
      if (this.isEditMode) {
        this.resetData();
      } else {
        this.closeEdit();
      }

      await this.getLabelPrinterList(this.addProviderModal.providerId)
    },
  },
};
</script>

<style lang="scss" scoped>
.P-full-line {
  margin: 0 -10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-checkbox {
  padding: 10px;
  display: inline-block;
}

.P-buttons {
  margin-top: 10px;
}

.P-manage-label-printers::v-deep {
  .v-text-field__details {
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 10px;
  }
}

.P-radio-box {
  padding: 10px;

  &:first-child {
    margin-left: -5px;
  }
}
</style>
