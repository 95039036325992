<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <div class="P-payment-type G-align-start G-justify-between">
        <ul>
          <li
            :class="{
              'P-active-payment': invoice.selectTab === 1
            }"
            @click="selectPaymentType(1)"
            class="G-flex secondary--text"
          >
            Invoice settings
          </li>
          <li
            :class="{
              'P-active-payment': invoice.selectTab === 2
            }"
            @click="selectPaymentType(2)"
            class="G-flex secondary--text"
          >
            Invoice design
          </li>
        </ul>
      </div>
    </div>
    <div class="P-modal-component-body">
      <invoice-settings ref="invoiceSettings" v-if="invoice.selectTab === 1" />
      <invoice-design v-else />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttInput from "@/components/vuetifyComponents/OttInput";
import InvoiceSettings from "./inoiceSettings";
import InvoiceDesign from "./InvoiceDesign";


export default {
  name: "InvoiceGenerationAdmin",
  components: {
    InvoiceDesign,
    InvoiceSettings,
    OttInput,
    OttTextarea,
    OttSelect,
    OttButton
  },
  data() {
    return {};
  },

  computed: {
    ...mapState({
      invoice: state => state.invoiceAdmin,
      addProviderModal: state => state.manageAdminModule,
      isLoading: state => state.appModule.isLoading,
    })
  },

  async mounted() {
    this.setActiveTab(1)
    if(this.addProviderModal.providerId){
      await this.getInvoiceSettings(this.addProviderModal.providerId).then(()=>{
        this.setIsEditInvoiceSettings();
        this.setIsEditInvoiceDesign();
        if (this.invoice.designData.logo) {
          this.createImagePath()
        }
      })
    }
  },

  methods: {
    ...mapActions({
      getInvoiceSettings: 'invoiceAdmin/getInvoiceSettings',
      manageInvoiceData: 'invoiceAdmin/manageInvoiceData'
    }),

    ...mapMutations({
      setActiveTab: 'invoiceAdmin/setActiveTab',
      setIsEditInvoiceSettings: 'invoiceAdmin/setIsEditInvoiceSettings',
      setIsEditInvoiceDesign: 'invoiceAdmin/setIsEditInvoiceDesign',
      createImagePath: 'invoiceAdmin/createImagePath',
      generateInvoiceImage: 'invoiceAdmin/generateInvoiceImage',
    }),

    async selectPaymentType(index) {
      switch (index) {
        case 1: {
          this.setActiveTab(index);
          break;
        }
        case 2: {
          if (
            this.$refs["invoiceSettings"] &&
            this.$refs["invoiceSettings"].validateInvoiceSettings()
          ) {
            const body = {
              providerId: this.addProviderModal.providerId,
              data:{
                settings: this.invoice.settingsData
              }
            }
            this.generateInvoiceImage(this.addProviderModal.providerId)
            await this.manageInvoiceData(body).then(() => {
              this.setActiveTab(index);
              this.setIsEditInvoiceSettings();
            })
          }
          break;
        }
        default: {
          this.setActiveTab(index);
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-modal-component-body {
  margin-top: 20px;
}

.P-payment-type {
  width: 100%;

  ul {
    margin: 0;
    padding: 1px;
    box-shadow: 0 0 5px #00000012;
    border-radius: 27px;
    display: flex;
    align-items: flex-start;

    li {
      font-size: $txt14;
      font-weight: $semiBold;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 27px;
      transition: $transition;
      text-transform: capitalize;

      &.P-active-payment {
        background-color: $neutral-color25;
      }
    }
  }
}

.theme--dark {
  .P-payment-type {
    ul {
      background-color: $darkMode43;
    }

    svg {
      filter: grayscale(1) invert(1);
    }

    .P-active-payment {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.theme--dark {
  .P-payment-type {
    ul {
      background-color: $darkMode43;
    }

    .P-active-payment {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}
</style>
