<template>
  <div class="P-checkeeper-layout G-justify-between">
    <div class="check" ref="check" @drop="dropHandler" @dragover.prevent></div>

    <div class="elements">
      <h2 :class="$vuetify.theme.dark ? 'white--text' : 'secondary--text'">
        Add Elements
      </h2>
      <p
        class="info-text"
        :class="$vuetify.theme.dark ? 'white--text' : 'secondary--text'"
      >
        Drag and drop these elements onto your template. Elements tell your
        printer where to print the details of your check.
      </p>

      <draggable v-model="checkElements" class="mt-6 check-elements">
        <div
          v-for="(item, key) in checkElements"
          :key="key"
          draggable="true"
          @dragstart="dragstart($event, item)"
          class="check-element pa-2 me-2 mb-2 cursor-pointer"
        >
          {{ item.title }}
        </div>
      </draggable>

      <button @click="downloadElement">Download</button>
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "CheckeeperLayout",
  components: {
    Draggable,
  },
  data() {
    return {
      droppedElement: null,
      checkElements: [
        {
          title: "Signature",
          name: "signature",
        },
        {
          title: "Address",
          name: "address",
        },
        {
          title: "Payee",
          name: "payee",
        },
        {
          title: "Amount",
          name: "amount",
        },
        {
          title: "Date",
          name: "date",
        },
        {
          title: "Memo",
          name: "memo",
        },
        {
          title: "Check Number",
          name: "check_number",
        },
        {
          title: "Bank Details",
          name: "bank_details",
        },
        {
          title: "Bank Account",
          name: "bank_account",
        },
        {
          title: "Business Details",
          name: "business_details",
        },
        {
          title: "Written Amount",
          name: "written_amount",
        },
      ],
      elementsDragOptions: {
        group: "elements",
      },
    };
  },
  methods: {
    dragstart(event, item) {
      this.droppedElement = item;
    },
    dropHandler(event) {
      // Create an input element
      const draggedElement = document.createElement("div");
      draggedElement.classList = "dragged-check-element";
      draggedElement.innerHTML = `
        <span>{${this.droppedElement?.name}}</span>
      `;

      // Calculate the drop position
      const rect = event.target.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;

      // Set the position of the input element
      draggedElement.style.position = "absolute";
      draggedElement.style.left = `${offsetX}px`;
      draggedElement.style.top = `${offsetY}px`;

      // Append the input element to the check area
      event.target.appendChild(draggedElement);

      // Update checkContent to trigger reactivity
      this.checkContent = event.target.innerHTML;
      this.droppedElement = null;
    },
    downloadElement() {
      // Get the element to download using $refs
      const elementToDownload = this.$refs.check;

      // Create a new HTML document
      const newDoc = document.implementation.createHTMLDocument();

      // Create a <style> tag with your external styles
      const styleTag = document.createElement("style");
      styleTag.setAttribute("lang", "scss");
      styleTag.textContent = `
        .check {
            width: 600px;
            height: 100%;
            background-color: white;
            box-shadow: 0px 0px 5px #00000045;
            position: relative;
            }

            .dragged-check-element {
                padding: 10px;
                border-radius: 3px;
                color: #0A3C68;

                &:hover {
                    outline: 1px solid orange;
                }
            }
        }
      `;

      newDoc.head.appendChild(styleTag);

      // Clone the target element
      const clone = elementToDownload.cloneNode(true);

      // Append the cloned element to the new document
      newDoc.body.appendChild(clone);

      // Create a new Blob containing the HTML content
      const blob = new Blob([newDoc.documentElement.outerHTML], {
        type: "text/html",
      });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "downloaded_element.html";

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/variables";

.P-checkeeper-layout {
  height: 100%;

  & .elements {
    width: 28%;

    & .info-text {
      font-size: $txt14;
    }

    & .check-elements {
      display: flex;
      flex-wrap: wrap;
    }

    & .check-element {
      box-shadow: $box-shadow;
      color: $neutral-color;
      width: max-content;
      border: 1px solid $secondary-color;
      font-size: $txt14;
      background-color: $white;
    }
  }
}

.check {
  width: 600px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 5px #00000045;
  position: relative;
}

.dragged-check-element {
  padding: 10px;
  border-radius: 3px;
  color: #0a3c68;

  &:hover {
    outline: 1px solid orange;
  }
}

.theme--dark {
  .check-element {
    color: white !important;
    border-color: white !important;
    background-color: transparent !important;
  }

  .dragged-check-element {
    color: #0a3c68;
  }
}
</style>