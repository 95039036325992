<template>
  <div v-if="paymentList.length" class="P-saved-payment">
    <title-component title-text="saved payment" />
    <payment-list :pageIsPayBalance="true" ref="paymentList" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// components
import TitleComponent from "@/components/TitleComponent";
import PaymentList from "@/components/sidebar/components/manageAdmin/paymentMethodsAdmin/PaymentList";

export default {
  name: "SavedPayment",
  components: { TitleComponent, PaymentList },
  computed: {
    ...mapState({
      // balanceRefill: (state) => state.balanceRefill,
      authProviderId: (state) => state.auth.user.provider.id,
      paymentList: state => state.paymentMethodsAdmin.paymentList
    }),
  },

  created()  {
    this.setProviderId(this.authProviderId)
  },

  methods: {
    ...mapMutations({
      setProviderId: 'manageAdminModule/setProviderId',
    })
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
.P-saved-payment {
  &__block {
    margin-top: 6px;
    &-item {
      box-shadow: 0 0 5px #284e6626;
      border-radius: 4px;
      padding: 14px 16px;
      .P-card-number {
        font-size: $txt14;
        font-weight: $semiBold;
        span {
          margin-left: 4px;
        }
      }
    }
  }
  .G-grid-column-2 {
    grid-row-gap: 22px;
  }
}

.theme--dark {
  .P-saved-payment {
    &__block {
      &-item {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}
</style>