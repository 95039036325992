<template>
  <div class="P-manage-admin G-flex">
    <div class="P-general-info-left">
      <TitleComponent title-text="General Info" />
      <ul class="P-general-information">
        <li class="G-align-center">
          <p class="neutral--text">Parent</p>
          <p
            v-if="registeredData && registeredData.parent"
            class="secondary--text"
          >
            {{ registeredData.parent.name[0].name }}
          </p>
          <p v-else class="secondary--text">-</p>
        </li>
        <li class="G-align-center">
          <p class="neutral--text">Added</p>
          <p class="secondary--text" v-if="registeredData">
            {{ registeredData.createdAt }}
          </p>
          <p class="secondary--text" v-else>-</p>
        </li>
        <li class="G-align-center">
          <p class="neutral--text">Added BY</p>
          <p
            v-if="registeredData && registeredData.registerBy"
            class="secondary--text"
          >
            {{ registeredData.registerBy.firstname }}
            {{ registeredData.registerBy.lastname }}
          </p>
          <p class="secondary--text" v-else>-</p>
        </li>
        <li class="G-align-center">
          <p class="neutral--text">Edited</p>
          <p class="secondary--text" v-if="registeredData">
            {{ registeredData.updatedAt }}
          </p>
          <p class="secondary--text" v-else>-</p>
        </li>
        <li class="G-align-center">
          <p class="neutral--text">Edited By</p>
          <p
            v-if="registeredData && registeredData.editBy"
            class="secondary--text"
          >
            {{ registeredData.editBy.firstname }}
            {{ registeredData.editBy.lastname }}
          </p>
          <p class="secondary--text" v-else>-</p>
        </li>
      </ul>
    </div>
    <div class="P-general-info-right">
      <div class="P-balance-block">
        <div class="P-balance-info">
          <div class="P-balance-header G-flex-center flex-column font-weight-bold">
            <p>Balance</p>
            <span>{{ 
              registeredData 
                ? numberToFinanceNumber(registeredData.balance)
                : '-' 
            }}</span>
          </div>
        </div>
        <ott-button-loader
          :is-loading="isLoading"
          @onClick="openBalanceRefillModal"
          button-text="Pay"
          class-name="green-sm-btn"
        />
      </div>
      <!-- CREDIT <div class="P-credit-block">
        <div class="P-balance-info">
          <div class="P-credit-header G-justify-between G-align-center">
            <p>Credit</p>
            <span>
              ${{
                registeredData && registeredData.credits
                  ? registeredData.credits.amount
                  : 0
              }}
            </span>
          </div>
        </div>
        <ul>
          <li class="G-justify-between G-align-center">
            <p class="secondary--text">Credit</p>
            <p class="secondary--text">
              ${{
                registeredData && registeredData.credits
                  ? registeredData.credits.amount
                  : 0
              }}
            </p>
          </li>
          <li class="G-justify-between G-align-center">
            <p class="secondary--text">Credit Remain</p>
            <p class="secondary--text">
              {{
                registeredData && registeredData.credits
                  ? Math.floor(registeredData.credits.remainDays)
                  : 0
              }}
              day
            </p>
          </li>
          <li class="G-justify-between G-align-center">
            <p class="secondary--text">To Block Remain</p>
            <p class="secondary--text">
              {{
                registeredData && registeredData.credits
                  ? Math.floor(registeredData.credits.remainDaysBlock)
                  : 0
              }}
              day
            </p>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>
<script>
import GeneralInfoMixin from "./GeneralInfoMixin";
import TitleComponent from "@/components/TitleComponent";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "GeneralInfoAdmin",
  components: { OttButtonLoader, TitleComponent },
  mixins: [GeneralInfoMixin],
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-general-info-right {
  width: 35%;
  padding: 0 0 24px 24px;
}

.P-general-info-left {
  width: 65%;
  margin-top: -24px;
  padding: 10px 25px 25px 0;
  border-right: 1px solid $neutral-color;
}

.P-manage-admin {
  height: 100%;
}

.P-general-information {
  max-width: 300px;
  padding: 0;

  li {
    p {
      width: 50%;
      margin: 0;

      &:first-child {
        font-size: $txt12;
      }

      &:last-child {
        font-size: $txt14;
      }
    }

    & + li {
      margin-top: 10px;
    }
  }
}

.P-balance-block {
  padding: 15px;
  background-color: $green-new-light;
  border-radius: 4px;

  .P-balance-header {
    margin-bottom: 20px;

    p,
    span {
      color: $green-new;
    }

    h3 {
      font-size: $txt16;
    }

    p {
      margin-bottom: 0;
      font-size: $txt14;
      position: relative;
      padding-left: 15px;

      &:before {
        width: 8px;
        height: 8px;
        background-color: $green-new;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
      }
    }
  }
}

.P-credit-block {
  margin-top: 25px;
  padding: 15px;
  background-color: $danger-new-light;
  border-radius: 4px;

  ul {
    padding: 0;

    li {
      p {
        margin: 0;
      }
    }
  }

  .P-credit-header {
    margin-bottom: 15px;

    p,
    span {
      color: $danger-new;
    }

    h3 {
      font-size: $txt16;
    }

    p {
      margin-bottom: 0;
      font-size: $txt14;
      position: relative;
      padding-left: 15px;

      &:before {
        width: 8px;
        height: 8px;
        background-color: $danger-new;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
      }
    }
  }
}
</style>
