var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-modal-components"},[(!_vm.priceGroup.editMode && !_vm.priceGroup.addMode)?_c('div',{staticClass:"P-modal-component-header G-justify-between"},[_c('div',{staticClass:"P-payment-type G-align-start G-justify-between"},[_c('ul',[_c('li',{staticClass:"G-flex secondary--text",class:{
            'P-active-payment': _vm.priceGroup.priceGroupType === 1,
          },on:{"click":function($event){return _vm.selectPaymentType(1)}}},[_vm._v(" Clients Group ")]),_c('li',{staticClass:"G-flex secondary--text",class:{
            'P-active-payment': _vm.priceGroup.priceGroupType === 2,
          },on:{"click":function($event){return _vm.selectPaymentType(2)}}},[_vm._v(" Providers Group ")])])])]):_vm._e(),_c('div',{staticClass:"P-modal-component-body"},[(
        !_vm.priceGroup.priceGroupList.length ||
        _vm.priceGroup.editMode ||
        _vm.priceGroup.addMode
      )?_c('ManagePriceGroup'):_vm._e(),(
        _vm.priceGroup.priceGroupList.length &&
        !_vm.priceGroup.editMode &&
        !_vm.priceGroup.addMode
      )?_c('PriceGroupList'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }