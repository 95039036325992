<template>
  <div class="P-payment-components">
    <div class="P-inputs">
      <div class="P-full-line G-flex">
        <div class="P-padding-50">
          <ottInput
            v-if="cardNumber"
            v-model="cardNumber"
            v-cardformat:formatCardNumber
            :error="$v.creditCard.cardNumber.$error"
            @paste="handlePasteCardNumber"
            label="Card number*"
            class="card-number"
          />
           <ottInput
            v-else
            v-model="cardNumber"
            :error="$v.creditCard.cardNumber.$error"
            @paste="handlePasteCardNumber"
            label="Card number*"
            class="card-number"
          />
        </div>
        <div class="P-padding-50">
          <ottInput
            v-model="cardholderName"
            :error="$v.creditCard.cardholderName.$error"
            @emitKeypress="onlyLetters($event)"
            @paste="handlePasteCardHolder"
            label="Card holder name*"
          />
        </div>
      </div>
      <div class="P-full-line G-flex">
        <div class="P-padding-20">
          <ottInput
            v-model="expireDate"
            v-cardformat:formatCardExpiry
            :error="!validateExpiryDate || $v.creditCard.expireDate.$error"
            :maxLength="7"
            :customInputClass="'P-expiry-date'"
            @paste="handlePasteExpiryDate"
            @emitFunc="expiryDateValidation"
            label="Expiry date*"
          />
        </div>
        <div class="P-padding-15">
          <ottInput
            v-model="cvc"
            v-cardformat:formatCardCVC
            :maxLength="4"
            :customInputClass="'P-cvv'"
            :error="$v.creditCard.cvc.$error"
            @paste="handlePasteCvc"
            label="CVC/CVV*"
          />
        </div>
      </div>
      <div class="P-full-line">
        <div class="P-checkbox pt-0 pb-2 ml-1 P-width-content">
          <ottSwitch label="An existing address" v-model="paymentMethod.anExistingAddress" />
        </div>
      </div>
      <div v-if="!paymentMethod.anExistingAddress" class="P-full-line G-align-center">
        <div class="P-padding-33">
          <ott-select
            v-if="contact.phones.length"
            v-model="phone"
            :items="contact.phones"
            :multiple="false"
            :persistentHint="true"
            :clear="true"
            :error="$v.creditCard.phone.$error"
            label="Phone Number*"
            selected-text="phone"
          />
          <ottInput
            v-else
            v-model="phone"
            :error="$v.creditCard.phone.$error"
            label="Phone Number*"
          />
        </div>
        <div :class="contact.phones.length ? 'P-padding-66' : 'P-padding-100'">
          <ottUsAutocomplate
            :error-address="$v.creditCard.address.$error"
            :address-data="address"
            @updateAddress="updateAddress"
            ref="usAddress"
            @saveAddress="saveAddress"
            isRequiredField
          />
        </div>
      </div>
      <div v-if="!paymentMethod.anExistingAddress" class="P-full-line G-flex-wrap">
        <div class="P-padding-33">
          <ott-select-autocomplete
            v-model="country"
            outlined
            :items="countryList"
            :height="38"
            :error="$v.creditCard.country.$error"
            :clear="true"
            selected-text="name"
            label="Country*"
          />
        </div>
        <div class="P-padding-33">
          <ottInput
            v-model="city"
            :error="$v.creditCard.city.$error"
            label="City*"
          />
        </div>
        <div class="P-padding-33">
          <ottInput
            v-model="state"
            label="State/province"
          />
        </div>
        <div class="P-padding-33">
          <ottInput
            v-model="suite"
            label="Suite/unit"
          />
        </div>
        <div class="P-padding-33">
          <ottInput
            v-model="zip"
            :error="$v.creditCard.zip.$error"
            label="Zip code*"
          />
        </div>
      </div>
      <div v-if="paymentMethod.anExistingAddress" class="P-full-line">
        <div class="P-padding-100">
          <ottSelect
            v-model="existingAddress"
            :items="billingAddresses"
            :error="$v.creditCard.existingAddress.$error"
            label="Billing addresses*"
            selected-text="name"
          />
        </div>
      </div>
      <div class="P-full-line G-flex">
        <template v-if="!pageIsPayBalance">
          <div class="P-checkbox">
            <ott-checkbox
              v-model="paymentMethod.creditCard.default"
              label="Default"
            />
    <!--        :disabled="this.paymentMethod.paymentList.length===1"-->

          </div>
          <div class="P-checkbox">
            <ott-checkbox
              v-model="paymentMethod.creditCard.inUse"
              label="In Use"
            />
          </div>
        </template>

        <div class="P-checkbox" v-else>
          <ott-checkbox
            v-model="paymentMethod.creditCard.save"
            label="Save"
          />
        </div>
      </div>
    </div>

    <template v-if="!pageIsPayBalance">
      <save-reset-buttons
        v-if="paymentMethod.paymentList.length"
        :right-label="paymentMethod.editMode ? 'Save' : 'Add'"
        :right-click-func="() => manageCreditCard()"
        :left-click-func="() => resetData()"
        :is-loading="isLoading"
      />

      <div class="G-flex mt-5" v-else>
        <ott-button-loader
          @onClick="manageCreditCard()"
          :is-loading="isLoading"
          button-text="Add"
          class-name="primary-sm-btn"
        />
      </div>
    </template>
    
    <div class="mt-5">
      <ErrorMessageContainer :message="errorMessage" v-if="error"/>
    </div>
  </div>
</template>
<script>
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttUsAutocomplate from "@/components/vuetifyComponents/OttUsAutocomplate";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import CreditCardMixin from "./CreditCardMixin";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "CreditCard",
  components: {
    OttSelectAutocomplete,
    ErrorMessageContainer,
    OttSelect,
    OttCheckbox,
    OttUsAutocomplate,
    OttSwitch,
    OttInput,
    SaveResetButtons,
    OttButtonLoader
  },
  mixins:[CreditCardMixin],
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-payment-components::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.P-payment-components {
  margin-top: 15px;
  .P-inputs {
    margin: 0 -10px;

    .P-width-content {
      width: fit-content;
    }
  }
}
</style>
