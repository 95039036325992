import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import allCountry from "@/constants/all-countries";
import timeZoneList from "@/constants/time-zone-list";

export default {
  data() {
    return {
      allCountry,
      timeZoneList
    }
  },

  validations: {
    formData: {
      companyName: { required },
      website: { required },
      timezone: { required }
    }
  },

  computed: {
    ...mapState({
      companyInfoAdminModule: state => state.companyInfoAdminModule,
      formData: state => state.companyInfoAdminModule.formData,
      isLoading: state => state.appModule.isLoading,
      authProvider: state => state.auth.user.provider
    }),

    companyName: {
      get() {
        return this.companyInfoAdminModule.formData.companyName
      },
      set(value) {
        this.setCompanyName(value)
      }
    },
    website: {
      get() {
        return this.companyInfoAdminModule.formData.website
      },
      set(value) {
        this.setWebsite(value)
      }
    },
    country: {
      get() {
        return this.companyInfoAdminModule.formData.country
      },
      set(value) {
        this.setCountry(value)
      }
    },
    timezone: {
      get() {
        return this.companyInfoAdminModule.formData.timezone
      },
      set(value) {
        this.setTimeZone(value)
      }
    }
  },

  methods: {
    ...mapMutations({
      setCompanyName: 'companyInfoAdminModule/setCompanyName',
      setWebsite: 'companyInfoAdminModule/setWebsite',
      setCountry: 'companyInfoAdminModule/setCountry',
      setTimeZone: 'companyInfoAdminModule/setTimeZone',
    }),

    updateData() {
      if (this.validateData()) {
        this.$emit('updateData')
      }
    },

    validateData() {
      this.$v.formData.$touch()
      return !this.$v.formData.$error
    }

  },
}
