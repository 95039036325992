<template>
    <div>
        <div class="P-confirm-success P-padding-top" v-if="balanceRefill.printSuccess === 1">
            <span class="icon-checked"></span>
            <h2>Thank You !</h2>
            <p class="P-success-message">
                Your Balance {{amount}} Has Been Success
            </p>
            <div class="P-divider"></div>
            <div class="G-justify-center">
                <div class="P-confirm-send-block">
                    <span class="P-confirm-send-by">{{ balanceType === 1 ? 'Receipt' : 'Refund Check' }}</span> <br />
                    <p v-if="emailForIncoice" class="P-confirm-email">Sent Email By {{ emailForIncoice }}</p>
                    <ott-button
                        :click="handlePrintSuccess"
                        text="Print Refund Check"
                        custom-class="neutral-sm-btn"
                    />
                </div>
            </div>
        </div>
        <div v-else class="P-confirm-success P-confirm-final G-flex-column">
            <span class="icon-checked"></span>
            <h2>Thank You !</h2>
            <p class="P-success-message">Your Balance Refill Has Been Success</p>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import BalanceRefillAndRefundMixin from "./BalanceRefillAndRefundMixin";
    // component
    import OttButton from "@/components/vuetifyComponents/OttButton";
    export default {
        name: "Confirm",
        components: {OttButton},
        mixins: [BalanceRefillAndRefundMixin],
        computed: {
            ...mapState({
                balanceRefill: state => state.balanceRefill
            })
        },
        methods: {
            ...mapMutations({
                changePrintSuccessValue: "balanceRefill/changePrintSuccessValue"
            }),
            handlePrintSuccess() {
                this.changePrintSuccessValue(2)
            }
        }
    }
</script>

<style scoped lang="scss">
@import "src/views/clients/modals/checkout/components/confirmation/confirm";
.P-confirm-success {
    max-width: 555px;
    min-height: 635px;
    &.P-padding-top {
        padding-top: 104px;
    }
    &.P-confirm-final {
        justify-content: center;
        .P-success-message {
            margin-bottom: 0;
        }
    }
}
</style>