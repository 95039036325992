<template>
  <div class="P-invoice-design G-flex ">
    <invoice-design-manage />
    <invoice-design-img />
  </div>
</template>
<script>
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import InvoiceDesignManage from "./InvoiceDesignManage";
import InvoiceDesignImg from "./InvoiceDesignImg";
export default {
  name: "InvoiceDesign",
  components: {
    InvoiceDesignImg,
    InvoiceDesignManage,
    OttInput,
    OttSelect,
    OttButton
  }
};
</script>
<style lang="scss" scoped></style>
