<template>
  <div class="main-content">
    <div v-if="clientId">
      <div class="message-types G-flex G-justify-center mb-4">
        <div v-for="(item, index) in messageTypes" :key="index" >
          <div class="mr-2 img-block" :class="{ active: client?.provider.hasValidTwilio && item.type === 'sms' }">
            <v-tooltip top :color="client?.provider.hasValidTwilio && item.type === 'sms' ? 'primary' : ''">
              <template v-slot:activator="{ on, attrs }">
                <img v-bind="attrs" v-on="on" class="ms-2" :src="require('@/assets/images/' + item.img)" width="26" @click="selectMessageType(item)" />
              </template>
              <span :class="{ 'primary-tooltip-content': item.type === 'sms' }">{{ item.name }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="G-flex P-inputs G-flex-wrap">
        <div class="P-padding-50">
          <ott-select
            v-model="selectedPhone"
            label="Phone Numbers"
            selected-text="name"
            :items="phones"
            :error="$v.selectedPhone.$error"
            @emitInputChange="handlePhone"
            :disabled="!client?.provider.hasValidTwilio"
            :multiple="false"
            persistent-hint
            clear
          />
        </div>
        <div class="P-padding-50">
          <ott-select
            v-model="selectedLocation"
            label="Locations"
            selected-text="name"
            :items="locations"
            @emitInputChange="handleLocation"
            :disabled="!isSelectedPhone || !client?.provider.hasValidTwilio"
            :multiple="false"
            persistent-hint
            clear
          />
        </div>
        <div class="P-padding-100 pt-0">
          <ott-select
            v-model="selectedAddress"
            label="Company addresses"
            selected-text="name"
            :items="addresses"
            @emitInputChange="handleAddress"
            :disabled="!isSelectedPhone || !client?.provider.hasValidTwilio"
            :multiple="false"
            persistent-hint
            clear
          />
        </div>
      </div>
      <div class="chat-messages mb-4" :class="{ 'G-flex G-justify-center G-align-center': isLoading || isNoMessages }" ref="chatMessages" @scroll="handleScroll">
        <div v-if="!isLoading">
          <v-progress-circular
            v-if="isFetchingData"
            :width="2"
            :size="20"
            indeterminate
            color="primary"
            class="P-prev-data-loader"
          ></v-progress-circular>
          <div 
            v-for="(message, index) in messages" 
            :key="index" 
            :class="{ 'my-message G-flex-column G-align-end': message.isMe, 'other-message': !message.isMe, 'mb-2': !message.date }" 
            class="message"
          >
            <div v-if="message.isMe" class="message-sender">{{ message.sender }}</div>
            <div class="message-text">{{ message.text }}</div>
            <div v-if="message.date" class="message-date G-flex G-justify-end G-align-center">
              {{ formatMessageDate(message.date) }}

              <v-icon small v-if="message.isMe && message.deliveryState === 1" class="primary--text ml-1 mdi-check-all">mdi-check-all</v-icon>
              <v-icon small v-if="message.isMe && message.deliveryState === 2" class="neutral--text ml-1">mdi-check</v-icon>
            </div>
          </div>
        </div>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        ></v-progress-circular>
        <p v-if="isNoMessages" class="neutral--text text-center mb-0">No messages here yet...</p>
      </div>
      <v-progress-linear v-if="isShowProgress" v-model="smsProgress" class="mb-3"></v-progress-linear>
      <div class="G-flex G-align-end typing-block">
        <ott-textarea
          v-model="message"
          label="Type your message"
          class="me-3 typing-textarea"
          :rows="1"
          :is-append-icon="client?.provider.hasValidTwilio"
          @appendIcon="showIcons()"
          @keydown="enterMessage"
          @input="handleTyping"
          :disabled="!client?.provider.hasValidTwilio"
          is-auto-grow
          ref="typingArea"
        >
        </ott-textarea>
        <v-btn color="primary" @click="sendMessage()" class="send-btn" :disabled="!client?.provider.hasValidTwilio">
          <v-icon class="white--text">mdi-send</v-icon>
        </v-btn>

        <div class="emojis-block">
          <transition name="fade">
            <Picker v-if="isShowEmojis" :data="emojiIndex" set="apple" @select="selectEmoji" />
          </transition>
        </div>
      </div>
    </div>

    <!-- <div class="P-all-chats">
      <div v-for="(item, index) in allChats" :key="index" class="P-chat-block G-flex G-align-center">
        <v-avatar
          color="primary"
          size="65"
        >A</v-avatar>
        <div class="ms-4">
          <h3 class="secondary--text">{{ item.name }}</h3>
          <p class="neutral--text mb-0">{{ item.message }}</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import HubMixin from "@/mixins/Hub.mixin";

// Import data/twitter.json to reduce size, all.json contains data for
// all emoji sets.
import data from "emoji-mart-vue-fast/data/all.json";
// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";

import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
let emojiIndex = new EmojiIndex(data)


import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: 'Chat',
  components: { 
    OttTextarea,
    Picker,
    OttSelect
  },
  mixins: [HubMixin],
  props: {
    clientId: {
      type: String,
      default: null
    },
    phones: {
      type: Array,
      default: []
    },
    client: {
      type: Object,
    },
    locations: {
      type: Array,
      default: []
    },
    addresses: {
      type: Array,
      default: []
    }
  },
  validations: {
    selectedPhone: { required },
  },
  data() {
    return {
      message: '',
      messages: [],
      emojiIndex: emojiIndex,
      emojisOutput: '',
      isShowEmojis: false,
      selectedPhone: '',
      selectedLocation: '',
      selectedAddress: '',
      isSelectedPhone: false,
      allChats: [
        { 
          name: 'Anna',
          message: 'Hiiii'
        },
        {
          name: 'Anna',
          message: 'Hiiii lorem ipsum'
        },     
        {
          name: 'Anna',
          message: 'Hiiii'
        },      
        {
          name: 'Anna',
          message: 'Hiiii'
        }
      ],
      selectedPass: '',
      isLoading: true,
      allMessages: [],
      allDataLoaded: false,
      isFetchingData: false,
      isNoMessages: false,
      messageTypes: [
        {
          name: "Message",
          type: "sms",
          img: "message.png"
        },
        {
          name: "Viber",
          type: "viber",
          img: "viber.png"
        },
        {
          name: "Whatsapp",
          type: "whatsapp",
          img: "whatsapp.png"
        },
        {
          name: "Instagram",
          type: "instagram",
          img: "instagram.png"
        },
        {
          name: "Messenger",
          type: "messenger",
          img: "messenger.png"
        }
      ],
      selectedMessageType: 'sms'
    };
  },
  watch: {
    selectedPhone(newVal) {
      this.isSelectedPhone = newVal !== null
    },
    hubState() {
      if (this.clientId && this.hubState === 5) {
        this.subscribeHubGroup(null, `chat-client-mini-${this.clientId}`, this.handleChat);
      }
    }
  },
  mounted() {
    if (this.clientId) {
      this.subscribeHubGroup(null, `chat-client-mini-${this.clientId}`, this.handleChat);

      this.setData({ currentPage: 1 })
      this.getClientChat();
      this.scrollToBottom(); 
    }
  },
  beforeDestroy() {
    if (this.clientId) {
      this.subscribeHubGroup(`chat-client-mini-${this.clientId}`, null, this.handleChat);
    }
  },
  updated() {
    if (this.currentPage === 1) {
      this.scrollToBottom()
    }
  },
  computed: {
    ...mapState({
      hubState: (state) => state.appModule.hubState,
      smsProgress: state => state.chatModule.smsProgress,
      isShowProgress: state => state.chatModule.isShowProgress,
      currentPage: state => state.chatModule.currentPage
    }),
  },

  methods: {
    connectedHub() {
      this.subscribeHubGroup(null, `chat-client-mini-${this.clientId}`, this.handleChat);
    },
    handleChat(data) {
      this.setData({ currentPage: 1 })
      this.getClientChat();
    },
    getClientChat() {
      this.getClientMessages({ filter: {}, clientId: this.clientId }).then(data => {
        let newData = data.data.results;

        if (newData.length > 0) {
          const existingMessagesIds = this.allMessages.map(message => message.id)
          newData = newData.filter(message => !existingMessagesIds.includes(message.id))

          if (newData.length > 0) {
            this.allMessages = [...this.allMessages, ...newData]

            newData.length === data.data.results.length ?
              this.fetchList(this.allMessages) : 
              this.fetchList(data.data.results)

            this.page = data.data.page;
            this.setData({ smsProgress: 0 })
            this.setData({ isShowProgress: false })
            this.selectedLocation = ''
            this.selectedAddress = ''
            this.isLoading = false
            this.isFetchingData = false
            this.isNoMessages = false
          } else {
            this.allDataLoaded = true
          }
        } else {
          this.allDataLoaded = true
          this.isFetchingData = false
          this.isLoading = false
          if (!this.allMessages.length) {
            this.isNoMessages = true
          }
        }      
      }, err => {
        this.toast({ success: false, message: err});
      });
    },
    fetchList(results) {
      this.messages = [];
      results.forEach((item)=>{
        const isMe = item.from_client !== this.clientId;
        this.messages.push({
          isMe,
          date: new Date(item.createdAt),
          text: item.message,
          sender: isMe && item.user?.id
            ? `${item.user.firstname} ${item.user.lastname}`
            : (item.byUser
              ? `${item.byUser.firstname} ${item.byUser.lastname}`
              : ''),
          deliveryState: item.deliveryState
        })
      })
      const listReversed = this.messages.reverse()
      this.$forceUpdate()
      return listReversed
    },
    toast(res) {
            const toastOptions = {
              msg: res.message,
              position: "toast-bottom-right",
              defaultTimeout: 500
            }
      
            if (res.success) {
              toastOptions.type = "success"
              toastOptions.style = { 
                backgroundColor: "#01B2B8", 
                width: "max-content", 
                textTransform: "capitalize",
                opacity: '1'
              }
      
              this.$toastr.Add(toastOptions);
            } else {
              toastOptions.type = "error",
              toastOptions.style = { 
                backgroundColor: "red", 
                width: "max-content", 
                textTransform: "capitalize",
                opacity: '1'
              }
      
              this.$toastr.Add(toastOptions);
            }
    },
    ...mapActions({
      getClientMessages: "chatModule/getMessages",
      sentClientMessage: "chatModule/sendMessage"      
    }),
    ...mapMutations({
      setData: "chatModule/setData",
    }),
    formatMessageDate(date) {
      const currentDate = new Date()
      const messageDate = new Date(date)

      // check if it is today
      if (
        messageDate.getDate() === currentDate.getDate() &&
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear()
      ) {
        const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        return `Today, ${messageDate.getDate()} ${this.getMonthName(messageDate.getMonth())} at ${time}`
      }

      // check if it is yesterday
      const yesterday = new Date(currentDate)
      yesterday.setDate(currentDate.getDate() - 1)
      if (
        messageDate.getDate() === yesterday.getDate() &&
        messageDate.getMonth() === yesterday.getMonth() &&
        messageDate.getFullYear() === yesterday.getFullYear()
      ) {
        const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        return `Yesterday, ${messageDate.getDate()} ${this.getMonthName(messageDate.getMonth())} at ${time}`
      }

      // else
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const dayAbbreviation = days[messageDate.getDay()]
      const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      return `${dayAbbreviation}, ${messageDate.getDate()} ${this.getMonthName(messageDate.getMonth())} at ${time}`
    },
    getMonthName(monthIndex) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ]
      return months[monthIndex]
    },
    sendMessage() {
      this.$v.selectedPhone.$touch()
      if (!this.$v.selectedPhone.$error && this.message.trim() !== '') {
        this.isShowEmojis = false
        this.setData({ isShowProgress: true })
        this.sentClientMessage({ clientId: this.clientId, data: { message: this.message, phone: this.selectedPhone, type: this.selectedMessageType }}).then(data=> {
          this.message = '';
          this.setData({ currentPage: 1 })
          this.getClientChat();
        }, errr => {
          this.toast({ success: false, message: errr});
        });
      }
    },

    enterMessage(event) {
      event.preventDefault()
      this.sendMessage()
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatMessages;
        container.scrollTop = container.scrollHeight;
      });
    },
    showIcons() {
      this.isShowEmojis = !this.isShowEmojis
    },
    selectEmoji(emoji) {
      this.message += emoji.native
      this.$nextTick(() => {
        this.$refs.typingArea.focusTextarea()
      })
    },
    handleLocation(value) {
      if (value) {
        const selectedLoc = this.locations.filter(item => item.login === value)
        this.selectedPass = selectedLoc[0].password
        this.message += 'login: ' + selectedLoc[0].login + ',' + '\n' + 'password: ' + selectedLoc[0].password + '\n'
      }
      const length = this.message.length
      this.$nextTick(() => {
        this.$refs.typingArea.focusTextareaWithLength(length)
      })
    },
    handleAddress(value) {
      if (value) {
        this.message += value + '\n'
      }
      const length = this.message.length
      this.$nextTick(() => {
        this.$refs.typingArea.focusTextareaWithLength(length)
      })
    },
    handlePhone(value) {
      this.isSelectedPhone = true
    },
    handleScroll() {
      const scrollContainer = this.$refs.chatMessages

      if (scrollContainer.scrollTop === 0 && !this.allDataLoaded) {
        this.isFetchingData = true
        this.setData({ currentPage: this.currentPage + 1 })
        this.getClientChat()
      }
    },
    handleTyping(val) {
      this.isShowEmojis = false

      if (!(this.message.includes(this.selectedLocation) && this.message.includes(this.selectedPass))) {
        this.selectedLocation = ''
      }

      if (!this.message.includes(this.selectedAddress)) {
        this.selectedAddress = ''
      }
    },
    selectMessageType(val) {
      this.selectedMessageType = val.type
    }
  },
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/variables";
.v-application--is-ltr .ms-2 {
  margin-left: 0 !important;
}
.main-content {
  width: 100%;
  textarea {
    line-height: 1.5;
    font-size: 14px;
  }

  .message-types {
    .img-block {
      position: relative;
      img {
        height: 26px; 
        width: 26px; 
        filter: grayscale(1); 
        cursor: pointer;
        border-radius: 4px;
      }

      &.active {
        img {
          filter: none;
        }
        .status {
          width: 12px;
          height: 12px;
          display: block;
          background-color: green;
          position: absolute;
          right: -5px;
          bottom: 3px;
          border-radius: 50%;
        }
      }
    }
  }

  .P-inputs {
    margin: 0 -10px;
  }
}
.chat-messages {
  overflow-y: auto;
  max-height: 250px;
  height: 250px;
  // height: calc(100vh - 130px);
  padding: 10px;
  background-color: $neutral-color25;
  .P-prev-data-loader {
    margin: 0 47%;
  }
  .message {
    width: fit-content;
    white-space: pre-line;

    &.my-message {
      margin-left: auto;

      .message-text {
        background-color: #01B2B8;
        color: #fff;
      }
      .message-date {
        text-align: end;
      }
    }

    &.other-message {
      .message-text {
        background-color: #fff;
      }
    }

    .message-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    .message-sender {
      font-weight: 500 ;
    }

    .message-text {
      max-width: 270px;
      word-wrap: break-word;
      // margin: 8px;
      padding: 10px;
      border-radius: 8px;
    }

    .message-sender,
    .message-date {
      font-size: 12px;
      color: #777;
    }
  }
}

.typing-block {
  position: relative;
  .emojis-block {
    position: absolute;
    bottom: 50px;
    right: 0;
  }
}

.theme--dark {
  .P-sms-cont {
    background: $neutral-color75;
  }
  .chat-messages {
    background-color: #36393C;
    .message.other-message .message-text {
      color: $secondary-color-dark
    }
  }
}
.send-btn.theme--dark {
  background-color: $primary-color !important;
}

.mdi-check-all.theme--dark {
  color: $primary-color !important;
}

.v-application.theme--dark .primary-tooltip-content {
  color: $dark_light;
}

::v-deep .typing-textarea .v-input__slot {
  margin-bottom: 0 !important;
}

::v-deep {
  .send-btn.v-btn {
    height: 38px;
    box-shadow: none;
  }

  .typing-block textarea {
    max-height: 144px !important;
    overflow-y: auto;
  }
}

::v-deep .typing-block .v-input__append-inner {
  align-self: flex-end !important;
  margin-bottom: 6px !important;
}

.P-chat-block {
  padding: 10px 0;
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $neutral-color15;
    cursor: pointer;
  }
}
</style>
