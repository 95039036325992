import permissionService from "@/services/permission.service";

/**
 * Sidebar object for  left  menu
 * sidebar width  permissions
 * **/

/* speedometer ==== gauge*/
/* chart-line-variant ==== trending-up*/

/* account-multiple-remove ==== trending-up*/

class SidebarMenuModel {
  constructor(roles) {
    this.menu = [
      {
        key: "reviews",
        permissionKey: "getRegistrationProviders",
        href: "/review",
        title: "Review",
        link: true,
        icon: "mdi-account-clock-outline",
        child: [],
      },
      {
        key: "ottProviders",
        permissionKey: "getOttProviders",
        href: "/providers-resellers",
        title: "Providers / Resellers",
        icon: "mdi-account-check",
        child: [],
      },
      {
        key: "users",
        permissionKey: "getUsers",
        title: "Users",
        link: true,
        icon: "mdi-account-cog-outline",
        child: [],
      },
      {
        key: "clients",
        permissionKey: "getClientList",
        title: "Clients",
        icon: "mdi-account-multiple-check-outline",
        child: [],
      },
      {
        key: "transactions",
        permissionKey: "viewTransactionPage",
        href: "/transactions",
        title: "Transactions",
        icon: "mdi-clipboard-text-multiple-outline",
        link: true,
      },
      {
        key: "packages",
        permissionKey: "getPackages",
        href: "/packages",
        title: "Packages",
        icon: "mdi-checkbox-multiple-marked-outline",
        link: true,
      },
      {
        key: "equipments",
        permissionKey: "getEquipments",
        title: "Equipments",
        icon: "mdi-tablet-cellphone",
        child: [],
        link: true,
      },
      {
        key: "discounts",
        permissionKey: "getDiscounts",
        href: "/discounts",
        title: "Discounts",
        icon: "mdi-percent-outline",
        link: true,
      },
      {
        key: "flussonics",
        permissionKey: "getFlussonics",
        href: "/flussonics",
        title: "Flussonics hosts",
        icon: "mdi-view-dashboard-outline",
        link: true,
      },
      {
        key: "fullCalendar",
        permissionKey: "getFullCalendar",
        href: "/appointments",
        title: "Appointments",
        icon: "mdi-calendar",
        link: true,
      },
      {
        key: "shippings",
        permissionKey: "getShippings",
        href: "/shippings",
        title: "Shippings",
        icon: "mdi-truck-delivery-outline",
        link: true,
      },
      {
        key: "help",
        permissionKey: "getHelps",
        href: "/help",
        title: "Help",
        icon: "mdi-help-circle-outline",
        link: true,
      },

      // {
      //   key: "settings",
      //   permissionKey: "",
      //   title: "Settings",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },

      // {
      //   href: "/client",
      //   permissionKey: "",
      //   title: "Client",
      //   link: true,
      //   icon: "mdi-account-group-outline",
      //   child: []
      // },
      // {
      //   href: "/channels",
      //   permissionKey: "getChannels",
      //   key: "channels",
      //   title: "Channels",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },
      // {
      //   key: "price-group",
      //   permissionKey: "getPriceGroups",
      //   title: "Price Groups",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },
      // {
      //   key: "eshop",
      //   permissionKey: "",
      //   href: "/eshop",
      //   title: "Buy Products (eShop)",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },
      // {
      //   key: "sale-rent-products",
      //   permissionKey: "",
      //   title: "ToSale / ToRent Products",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },
      // {
      //   key: "email-providers",
      //   permissionKey: "",
      //   href: "/email-providers",
      //   title: "Client Email Address",
      //   icon: "mdi-email-outline",
      //   child: []
      // },
      // {
      //   key: "statistics",
      //   permissionKey: "",
      //   title: "Statistics",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },
      // {
      //   key: "language",
      //   permissionKey: "",
      //   title: "Languages / Translations",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // },
      // {
      //   key: "settings",
      //   permissionKey: "",
      //   title: "Settings",
      //   icon: "mdi-view-dashboard-outline",
      //   child: []
      // }
    ];
    let users = this.menu.filter((r) => r.key === "users")[0];
    let clients = this.menu.filter((r) => r.key === "clients")[0];
    let settings = this.menu.filter((r) => r.key === "settings")[0];
    let equipments = this.menu.filter((r) => r.key === "equipments")[0];

    // Price group sub list
    if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
      users.child.push({
        href: "/users/list",
        title: "List",
        permissionKey: "getUsers",
        link: true,
      });
    }
    if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
      users.child.push({
        href: "/users/activity",
        title: "Activity",
        permissionKey: "getActiveUsers",
        link: true,
      });
    }

    //  Clients
    if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
      clients.child.push({
        href: "/clients/list?extendedType",
        permissionKey: "viewClientPage",
        title: "List",
        link: true,
      });

      clients.child.push({
        href: "/clients/activity",
        permissionKey: "getClientActivitys",
        title: "Activity",
        link: true,
      });

      clients.child.push({
        href: "/clients/payments",
        permissionKey: "getBillInvoices",
        title: "Bills",
        link: true,
      });

      clients.child.push({
        href: "/clients/deleted",
        // given this permission as this route has not permission key 
        permissionKey: "getClientList",
        title: "Deleted Clients",
        link: true,
      });
    }
    // Equipments
    if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
      equipments.child.push({
        href: "/equipments/types",
        permissionKey: "getEquipments",
        title: "Types",
        link: true,
      });
    }
    if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
      equipments.child.push({
        href: "/equipments/list",
        permissionKey: "getEquipments",
        title: "List",
        link: true,
      });
    }
    //
    // //settings
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/contact",
    //     title: "Contact",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/admin-personal-info",
    //     title: "Admin Personal Info",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/company-addresses",
    //     title: "Company Addresses",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/payment-methods",
    //     title: "Payment Methods",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/payment-gateway",
    //     title: "Payment Gateway",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/shipping-providers",
    //     title: "Shipping Providers",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/printers",
    //     title: "Printers",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/ui-customization",
    //     title: "UI Customization",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/info-for-clients-app",
    //     title: "Info for clients apps",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/sale-taxes",
    //     title: "Sale Taxes",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/bill-invoices-gen",
    //     title: "Bill/Invoices",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/other-api-integration",
    //     title: "Other API Integration",
    //     link: true
    //   });
    // }
    // if (permissionService.hasPermission(roles, ["ottprovider"], [])) {
    //   settings.child.push({
    //     href: "/settings/price-group",
    //     title: "Price Groups",
    //     link: true
    //   });
    // }
  }
}

export default SidebarMenuModel;
