<template>
  <div>
    <title-contact :click="addEmail" text="Email Address" />
    <email-content ref="emailForm" />
    <div class="P-phone-block">
      <title-contact :click="addEmail" text="Phone Number" />
      <phone-content ref="phoneNumberForm" />
    </div>
    <save-reset-buttons
      :is-Loading="isLoading"
      :left-click-func="reset"
      :right-click-func="save(false)"
      left-label="Reset"
      right-label="Add"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import EmailContent from "../email/EmailContent";
import PhoneContent from "../phone/PhoneContent";
import TitleContact from "../components/TitleContact";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "ContactForm",
  components: {
    TitleContact,
    PhoneContent,
    EmailContent,
    SaveResetButtons,
  },
  computed: {
    ...mapState({
      contactAdminModule: state => state.contactAdminModule,
      validateEmail: state => state.contactAdminModule.validateEmail,
      validatePhone: state => state.contactAdminModule.validatePhone,
      isLoading: state => state.appModule.isLoading
    })
  },

  methods: {
    ...mapMutations({
      showForm: 'contactAdminModule/showForm',
      saveMutation: 'contactAdminModule/save',
      resetContactForm: 'contactAdminModule/resetContactForm',
    }),

    addEmail() {
      this.showForm("email");
    },
    save(isFirst) {
      if(!isFirst){
        if (this.$refs["emailForm"]) this.$refs["emailForm"].validationEmail();
        if (this.$refs["phoneNumberForm"]) this.$refs["phoneNumberForm"].validationPhoneNumber();
      }
      if (this.validateEmail && this.validatePhone) {
        this.saveMutation();
      }
    },
    reset() {
      this.resetContactForm();
    },
    addPhone() {}
  },
    // watch:{
    //     validateEmail: function(data){
    //         if(data){
    //             this.save(true)
    //         }
    //     },
    //   validatePhone: function(data){
    //         if(data){
    //             this.save(true)
    //         }
    //     }
    // }
};
</script>

<style scoped lang="scss">
.P-phone-block {
  margin-top: 20px;
}
</style>
