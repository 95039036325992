var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-table"},[_c('v-data-table',{staticClass:"cont-table",attrs:{"headers":_vm.header,"items":_vm.tableData,"items-per-page":9999,"dense":"","hide-default-header":"","hide-default-footer":"","item-key":"name"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._l((_vm.header),function(i,index){return _c('th',{key:index,staticClass:"secondary--text ps-2"},[_vm._v(" "+_vm._s(i)+" ")])}),_c('th')]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('tr',{staticClass:"P-hovered-tr ps-2"},[_vm._l((item),function(bodyItem,index){return [(
              index !== 'useForSent' &&
              index !== 'value' &&
              index !== 'id' &&
              index !== 'providerId'
            )?_c('td',{key:index,class:{
              'P-disable-list': typeof bodyItem !== 'boolean' && item.inUse,
            }},[(
                typeof bodyItem !== 'boolean' &&
                index !== 'useForSent' &&
                index !== 'value'
              )?_c('span',[_vm._v(" "+_vm._s(bodyItem)+" ")]):(bodyItem && index !== 'useForSent')?_c('span',{staticClass:"mdi mdi-check checkTable"}):_vm._e()]):_vm._e()]}),_c('td',{staticClass:"actionList"},[_c('div',{staticClass:"P-actions"},[_c('v-menu',{attrs:{"left":"","content-class":"P-actions-list"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:{'white--text': _vm.$vuetify.theme.dark, 'neutral--text': !_vm.$vuetify.theme.dark}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"modalBackground",class:{ 'P-list-actions': _vm.tableData.length === 1 && !_vm.tax }},_vm._l((_vm.itemsAction),function(itemAction,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.itemClick(itemAction.title, index, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(itemAction.title))])],1)}),1)],1)],1)])],2)]}}])}),(_vm.showWarningModal)?_c('WarningModal',{attrs:{"isModalOpen":_vm.showWarningModal,"modalHeading":_vm.warningModalHeading,"modalText":_vm.warningModalText,"btnText":_vm.warningModalBtnText,"isDangerButton":_vm.warningModalIsDangerButton,"isLoading":_vm.warningModalIsLoading},on:{"closeModal":function($event){_vm.showWarningModal = false},"btnFunction":_vm.warningModalBtnFunc}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }