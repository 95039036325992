<template>
  <component
    v-bind="[isRouterLink ? { to: href } : { href: href }]"
    :is="tag"
    :active="'P-active-link'"
  >
    <div
      :class="{ 'P-open-menu': isOpenSublist }"
      class="P-menu-list-header G-align-center"
      v-tooltip="{
        content: showTooltip ? tooltipData : '',
        placement: 'right',
        popperClass: 'tooltip-content',
        autoHide: true,
        distance: 5,
        html: true  
      }" 
    >
      <span class="mdi neutral--text P-icon" :class="item.icon"></span>
      <p class="white--text">{{ item.title }}</p>
      <span
        v-if="item.child && item.child.length"
        :class="{ 'P-open-sub': isOpenSublist }"
        class="mdi mdi-chevron-down P-icon-down white--text"
      />
    </div>
  </component>
</template>

<script>
export default {
  name: "SidebarLink",
  props: {
    item: {
      type: Object,
      required: true
    },
    attributes: {
      type: Object,
      default: null
    },
    isOpenSublist: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRouterLink() {
      return !!this.item.href;
    },
    tag() {
      return this.isRouterLink ? "router-link" : "a";
    },
    href() {
      if (!this.item.href) return "#";
      return this.item.href;
    },
    tooltipData() {
      return `
        <div class="P-menu-tooltip secondary-07" ref="toolbar" v-show="isOpenToolbar">
          <p class="white--text">${ this.item.title }</p>
        </div>
      `
    }
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.router-link-active {
  .P-menu-list-header {
    &:before {
      height: 100% !important;
    }
    .neutral--text,
    .white--text {
      color: $primary-color !important;
    }
    background-color: $secondary-dark-66;
  }
}
.P-menu-list-header.P-open-menu {
  &:before {
    height: 100% !important;
  }
  .neutral--text,
  .white--text {
    color: $primary-color !important;
  }
  background-color: $secondary-dark-66;
}
.P-icon {
  font-size: 18px;
}
.P-menu-list-header {
  position: relative;
  padding: 12px 22px;
  cursor: pointer;
  overflow: hidden;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 3px;
    background-color: $primary-color;
    transition: $transition;
    content: "";
  }

  &:hover,
  &.router-link-active {
    &:before {
      height: 100% !important;
    }
    .neutral--text,
    .white--text {
      color: $primary-color !important;
    }
    background-color: $secondary-dark-66;
  }

  p,
  a {
    margin-bottom: 0;
    font-size: $txt14;
    font-weight: 600;
    margin-left: 15px;
    transition: $transition;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .P-icon-down {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    font-size: 20px;
    transition: 0.3s;
    &.P-open-sub {
      top: 50%;
      transform: rotate(180deg) translateY(45%);
    }
  }
}

.theme--dark {
  .P-menu-list-header.P-open-menu {
    &:before {
      height: 100% !important;
    }
    .neutral--text,
    .white--text {
      color: $primary-color !important;
    }
    background-color: $secondary-dark-66-dark;
  }
  .router-link-active {
    .P-menu-list-header {
      &:before {
        height: 100% !important;
      }
      .neutral--text,
      .white--text {
        color: $primary-color !important;
      }
      background-color: $secondary-dark-66-dark !important;
    }
  }
  .P-menu-list-header {
    &:hover {
      &:before {
        height: 100% !important;
      }
      .neutral--text,
      .white--text {
        color: $primary-color !important;
      }
      background-color: $secondary-dark-66-dark;
    }
  }
}
</style>

<style lang="scss">
.v-popper__popper:has(.P-menu-tooltip) {
  & .v-popper__inner > div {
    background-color: #5881A5;
    padding: 3px 6px;
    font-size: 14px;
    white-space: nowrap;
    color: white;
    font-weight: normal;

  }
}
</style>
