<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3 class="secondary--text">Select providers</h3>
    </div>
    <div class="P-modal-component-body P-merchant-block">
      <div class="P-full-line G-flex">
        <div class="P-padding-33">
          <OttSelect
            v-model="shipmentProviders.selectedData.selectProviderOrders"
            :items="shipmentProviders.providersList"
            :clear="true"
            label="Provider orders"
            selected-text="name"
          />
        </div>
        <div class="P-padding-33">
          <OttSelect
            v-model="shipmentProviders.selectedData.selectClientsOrder"
            :items="shipmentProviders.providersList"
            :clear="true"
            label="Client orders"
            selected-text="name"
          />
        </div>
      </div>

      <div class="G-flex mt-2">
        <ott-button-loader
          @onClick="updateData()"
          :is-loading="isLoading"
          button-text="Save"
          class-name="primary-sm-btn"
        />
      </div>

      <div class="P-merchant-api">
        <div class="P-modal-component-header G-justify-between">
          <h3 class="secondary--text">API Integration</h3>
        </div>
        <div class="P-list-block">
          <v-expansion-panels accordion class="G-accordion" v-model="accordion">
            <v-expansion-panel 
              v-for="(api, index) of apiIntegrationsData" 
              :key="index"
              :disabled="api.disabled"
              :readonly="api.readonly"
            >
              <v-expansion-panel-header
                :disable-icon-rotate="true"
              >
                <div class="G-accordion-header G-flex G-align-center G-justify-between">
                  <div class="P-image-block G-flex G-align-center">
                    <div
                      class="P-image"
                      :style="{
                        backgroundImage:
                          `url(${require('@/assets/images/api/' + api.logo)})`,
                      }"
                    ></div>

                    <p class="secondary--text ms-8">{{ api.title }}</p>
                  </div>

                  <template 
                    v-if="
                      showError && 
                      (
                        (!api.disabled && index === 0 && productionToken) ||
                        (!api.disabled && index === 1 && email && password) ||
                        (!api.disabled && index === 2 && apiKey && apiSecret) ||
                        (!api.disabled && index === 3 && apiToken)
                      )
                  ">
                    <div v-if="api.isValid == true && !api.disabled" class="G-flex G-align-center me-6">
                      <span class="status me-2 text-uppercase secondary--text">Active</span>
                      <v-icon class="success--text" medium>mdi-checkbox-marked-circle-outline</v-icon>
                    </div>
                    <div v-if="api.isValid == false && !api.disabled" class="G-flex G-align-center me-6">
                      <span class="secondary--text status me-2 text-uppercase">Failed</span>
                      <v-icon class="error--text" medium>mdi-cancel</v-icon>
                    </div>
                  </template>
                </div>
                <template v-slot:actions>
                  <span class="mdi mdi-plus neutral--text P-accordion-plus" />
                  <span class="mdi mdi-minus neutral--text P-accordion-minus" />
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-alert
                  v-if="
                    showError && 
                    (
                      (api.isValid == false && !api.disabled && index === 0 && productionToken) ||
                      (api.isValid == false && !api.disabled && index === 1 && email && password) ||
                      (api.isValid == false && !api.disabled && index === 2 && apiKey && apiSecret) ||
                      (api.isValid == false && !api.disabled && index === 3 && apiToken)
                    )
                  "
                  type="error"
                >
                  The provided token is invalid
                </v-alert>

                <div v-if="api.flexInputs.length" class="P-full-line G-flex">
                  <div 
                    v-for="(flexInp, flexIndex) of api.flexInputs"
                    :key="flexIndex" 
                    :class="flexInp.class">

                    <component 
                      :is="flexInp.type === 'text' ? 'OttInput' : 'PasswordInput'"
                      v-bind="{
                        value: flexInp.value,
                        label: flexInp.label,
                        error: validationSpaces[flexInp.name] || ($v[flexInp.name] !== undefined ? $v[flexInp.name].$error : false)
                      }"
                      @emitFunc="validateSpace(`${flexInp.name}`)"
                      @input="changeInputValue($event, flexInp.name)"
                    />
                  </div>
                </div>
                
                <div v-if="api.inputs.length" class="P-full-line">
                  <div 
                    v-for="(inp, inpIndex) of api.inputs" 
                    :key="inpIndex" 
                    :class="inp.class"
                    class="G-flex G-align-center P-input-block"
                  >
                    <div v-if="inp.label" class="P-title">
                      <h4>{{ inp.label }}</h4>
                    </div>
                    <component 
                      :is="inp.type === 'text' ? 'OttInput' : 'PasswordInput'"
                      v-bind="{
                        value: inp.value,
                        label: inp.label,
                        error: validationSpaces[inp.name] || ($v[inp.name] !== undefined ? $v[inp.name].$error : false)
                      }"
                      @emitFunc="validateSpace(`${inp.name}`)"
                      @input="changeInputValue($event, inp.name)"
                    />
                  </div>
                </div>

                <div
                  class="P-full-line P-buttons G-justify-between G-align-center"
                >
                  <div class="P-button-boxes G-align-center">
                    <div class="P-checkbox">
                      <save-reset-buttons
                        :is-loading="isLoading"
                        :right-label="api.rightButtonLabel"
                        :right-click-func="() => api.rightButtonFunc()"
                        :left-click-func="() => api.leftButtonFunc()"
                      />       
                    </div>        
                  </div>
                  <v-tooltip top color="tooltipBgColor">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="G-link-api">
                        <a
                          :href="api.link"
                          class="G-align-center G-justify-center"
                          target="_blank"
                        >
                          <img
                            :src="require('@/assets/images/api/' + api.icon)"
                            :titile="api.title + ' icon'"
                            alt="svg"
                          />
                        </a>
                      </div>
                    </template>
                    <span>{{ api.linkTitle }}</span>
                  </v-tooltip>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ShippingProvidersMixin from "@/mixins/providers/ShippingProvidersMixin";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import PasswordInput from "@/components/PasswordInput";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "ShippingProvidersAdmin",
  mixins: [ ShippingProvidersMixin ],
  components: {
    PasswordInput,
    OttButton,
    OttInput,
    OttSwitch,
    OttSelect,
    SaveResetButtons,
    OttButtonLoader,
  },

  computed: {
    ...mapState({
      shipmentProviders: (state) => state.shippingProvidersAdmin,
      isLoading: (state) => state.appModule.isLoading,
      addProviderModal: (state) => state.manageAdminModule,
    }),
  },

  methods: {
    ...mapActions({
      getShippingProviders: 'shippingProvidersAdmin/getShippingProviders',
      updateShippingProvider: 'shippingProvidersAdmin/updateShippingProvider'
    }),

    ...mapMutations({
      setData: 'shippingProvidersAdmin/setData',
      setEasyShipDataProductionToken: 'shippingProvidersAdmin/setEasyShipDataProductionToken',
      setShipRocketDataEmail: 'shippingProvidersAdmin/setShipRocketDataEmail',
      setShipRocketDataPassword: 'shippingProvidersAdmin/setShipRocketDataPassword',
      setShipStationDataApiKey: 'shippingProvidersAdmin/setShipStationDataApiKey',
      setShipStationDataApiSecret: 'shippingProvidersAdmin/setShipStationDataApiSecret',
      setGoShippoDataApiToken: 'shippingProvidersAdmin/setGoShippoDataApiToken',
      toSaveData: 'shippingProvidersAdmin/toSaveData',
      saveEasyShipDataMutation: 'shippingProvidersAdmin/saveEasyShipData',
      saveShipRocketDataMutation: 'shippingProvidersAdmin/saveShipRocketData',
      saveShipStationDataMutation: 'shippingProvidersAdmin/saveShipStationData',
      saveGoShippoDataMutation: 'shippingProvidersAdmin/saveGoShippoData',
      cancelEasyShipDataMutation: 'shippingProvidersAdmin/cancelEasyShipData',
      cancelShipRocketDataMutation: 'shippingProvidersAdmin/cancelShipRocketData',
      cancelShipStationDataMutation: 'shippingProvidersAdmin/cancelShipStationData',
      cancelGoShippoDataMutation: 'shippingProvidersAdmin/cancelGoShippoData',
      setIsEditEasyShip: 'shippingProvidersAdmin/setIsEditEasyShip',
      setIsEditShipRocket: 'shippingProvidersAdmin/setIsEditShipRocket',
      setIsEditShipStation: 'shippingProvidersAdmin/setIsEditShipStation',
      setIsEditGoShippo: 'shippingProvidersAdmin/setIsEditGoShippo',
      fetchData: 'shippingProvidersAdmin/fetchData',
    }),
  }
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-merchant-block {
  margin-top: 15px;
}

.P-full-line {
  margin: 0 -10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-padding-33 {
  width: 33.333%;
  padding: 10px;
}

.P-edit-merchant {
  margin-top: 15px;
}

.P-edit-merchant::v-deep,
.P-merchant-block::v-deep {
  .v-text-field__details {
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 10px;
  }
}

.P-merchant-api {
  margin-top: 15px;
}

.P-checkbox {
  padding: 10px;
  display: inline-block;
}

.P-list-block {
  margin-top: 15px;
}

::v-deep {
  .P-save-and-reset-block {
    margin-top: 0;
  }
}

.P-input-block {
  .P-title {
    width: 25%;
  }

  .ott-input-cont {
    width: 50%;
  }
}
</style>
