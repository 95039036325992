<template>
  <div>
    <TitleComponent :titleText="getTitleName()" v-if="priceGroupAdmin.editMode || priceGroupAdmin.addMode"/>
    <div class="P-manage-price-group P-manage-admin G-flex-wrap">
      <div class="P-padding-100">
        <ott-input
          v-model="name"
          :error="$v.formData.name.$error"
          label="Group Name*"
        />
      </div>
      <div class="P-padding-33">
        <ott-input
          v-model.number="percent"
          :error="$v.formData.percent.$error"
          customInputClass="P-initial-discount"
          label="Initial Discount*"
          type="number"
        />
      </div>
      <div class="P-padding-33">
        <ott-input
          v-model="digits"
          :error="$v.formData.digits.$error"
          :is-percent="true"
          :customInputClass="'P-digits'"
          label="Digits*"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="round"
          :items="discountRoundingList"
          :clear="true"
          :error="$v.formData.round.$error"
          class="P-test"
          selected-text="name"
          label="Discount Rounding*"
        />
      </div>
      <div class="P-padding-100">
        <div class="G-flex" v-if="!priceGroupAdmin.priceGroupList.length">
          <ott-button-loader
            :is-loading="isLoading"
            @onClick="saveData()"
            button-text="Add"
            class-name="primary-sm-btn"        
          />
        </div>
        <save-reset-buttons
          v-else
          :is-loading="isLoading"
          :right-click-func="() => saveData()"
          :left-click-func="() => resetData()"
          :right-label="priceGroupAdmin.addMode ? 'Add' : 'Save'"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import TitleComponent from "@/components/TitleComponent";
  import SaveResetButtons from "@/components/SaveResetButtons";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader"; 

  export default {
    name: 'ManagePriceGroup',
    components: {TitleComponent, OttSelect, OttInput, SaveResetButtons, OttButtonLoader},

    validations: {
      formData: {
        name: {required},
        percent: {required},
        digits: {required},
        round: {required},
      }
    },

    computed: {
      ...mapState({
        formData: state => state.priceGroupAdmin.formData,
        discountRoundingList: state => state.priceGroupAdmin.discountRoundingList,
        providerId: state => state.manageAdminModule.providerId,
        priceGroupAdmin: state => state.priceGroupAdmin,
        parentId: state => state.manageAdminModule.parentId,
        isLoading: state => state.appModule.isLoading
      }),

      name: {
        get() {
          return this.formData.name
        },
        set(value) {
          this.setGroupName(value)
        }
      },
      percent: {
        get() {
          return this.formData.percent
        },
        set(value) {
          this.setInitialDiscount(value)
        }
      },
      digits: {
        get() {
          return this.formData.digits
        },
        set(value) {
          this.setDigits(value)
        }
      },
      round: {
        get() {
          return this.formData.round
        },
        set(value) {
          this.setDiscountRounding(value)
        }
      },
    },

    mounted() {
    },

    methods: {
      ...mapActions({
        getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
        editPriceGroup: 'priceGroupAdmin/editPriceGroup',
        getPriceGroupListAction: 'priceGroupAdmin/getPriceGroupList',
        addPriceGroup: 'priceGroupAdmin/addPriceGroup',
      }),

      ...mapMutations({
        generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
        setGroupName: 'priceGroupAdmin/setGroupName',
        setInitialDiscount: 'priceGroupAdmin/setInitialDiscount',
        setDigits: 'priceGroupAdmin/setDigits',
        setDiscountRounding: 'priceGroupAdmin/setDiscountRounding',
        saveDataMutation: 'priceGroupAdmin/saveData',
        closeData: 'priceGroupAdmin/closeData',
        resetDataMutation: 'priceGroupAdmin/resetData',
      }),

      /**
       * Get price group list
       * **/

      async getPriceGroupList() {
        await Promise.all([
          this.getPriceGroupByTypeList({ type: 1 }),
          this.getPriceGroupByTypeList({ 
            type: 2,
            provider: this.parentId ? this.parentId : null
          })
        ])
        .then(data => {
          this.generateAllPriceGroups(data)
        })
      },

      getTitleName() {
        let titleText = '';
        if (this.priceGroupAdmin.priceGroupType === 1) {
          if (this.priceGroupAdmin.editMode) {
            titleText = 'Edit Clients Price Group'
          }
          if (this.priceGroupAdmin.addMode) {
            titleText = 'Add New Clients Price Group'
          }
        } else {
          if (this.priceGroupAdmin.editMode) {
            titleText = 'Edit Providers Price Group'
          }
          if (this.priceGroupAdmin.addMode) {
            titleText = 'Add New Providers Price Group'
          }
        }
        return titleText
      },
      async saveData() {
        this.$v.formData.$touch()
        if (!this.$v.formData.$error) {
          this.saveDataMutation()
          if (this.priceGroupAdmin.editMode) {
            await this.editPriceGroup().then(async () => {
              await this.getPriceGroupListAction().then(async () => {
                this.closeData()
                await this.getPriceGroupList()
              })
            })
          } else {
            await this.addPriceGroup().then(async () => {
              await this.getPriceGroupListAction().then(async () => {
                this.closeData()
                await this.getPriceGroupList()
              })
            })
          }

        }
      },
      resetData() {
        if (this.priceGroupAdmin.addMode) {
          this.closeData()
        } else {
          this.resetDataMutation()
        }
      },

    }, 
  }
</script>
<style lang="scss" scoped>
  @import "../index";

  .P-manage-price-group {
    margin : 20px -10px 0;
  }

</style>
