import { mapState, mapMutations, mapActions  } from "vuex";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import timeZoneList from "@/constants/time-zone-list";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import allCountry from "@/constants/all-countries";

export default {
  name: 'GeneralInfo',
  components: {OttSwitch, OttSelect, OttPhoneNumber, OttInput, OttButton, OttButtonLoader},
  data() {
    return {
      timeZoneList: timeZoneList,
      validPhoneNumber: false,
      isValidateNumber: false,
      isValidateImage: false,
      avatar: '',
      isValidateEmail: false,
      errorMessage: '',
      showPhoneError: false,
      openColorPicker: false,
    }
  },
  validations: {
    formData: {
      firstname: { required },
      lastname: { required },
      phone: { required },
      address: { required }
    },
  },
  
  computed: {
    ...mapState({
      generalInfoUser: state => state.generalInfoUser,
      formData: state => state.generalInfoUser.formData,
      uploadImageModal: state => state.uploadImageModal,
      userData: state => state.auth.user,
      users: state => state.users,
      isLoading: state => state.appModule.isLoading,
      addressLists: state => state.companyAddressesAdmin.addressLists,
      authProviderId: state => state.auth.user.provider.id,
    }),

    /**
     *  getters and setters for update data generation info
     * **/

    color: {
      get() {
        return this.generalInfoUser.formData.color;
      },
      set(color) {
        this.setFormData({ color })
      }
    },

    address: {
      get() {
        return this.generalInfoUser.formData.address;
      },
      set(address) {
        this.setFormData({ address })
      }
    },
    image: {
      get() {
        return this.generalInfoUser.formData.image;
      },
      set(image) {
        this.setFormData({ image })
      }
    },
    firstname: {
      get() {
        return this.generalInfoUser.formData.firstname;
      },
      set(firstname) {
        this.isValidateEmail = false
        this.setFormData({ firstname })
      }
    },
    phone: {
      get() {
        return this.generalInfoUser.formData.phone;
      },
      set(phone) {
        this.isValidateEmail = false
        this.setFormData({ phone })
      }
    },
    lastname: {
      get() {
        return this.generalInfoUser.formData.lastname;
      },
      set(lastname) {
        this.isValidateEmail = false
        this.setFormData({ lastname })
      }
    },
    isAccessEnable: {
      get() {
        return this.generalInfoUser.formData.accessEnable;
      },
      set(accessEnable) {
        let access = accessEnable ? true : false
        this.setFormData({ accessEnable: access })
      }
    },
    isShowUnsuccessfulTransactions: {
      get() {
        return this.generalInfoUser.formData.showUnsuccessfulTransactions;
      },
      set(value) {
        this.setFormData({ showUnsuccessfulTransactions: value })
      }
    },
  },

  async mounted() { 
    if (!this.generalInfoUser.formData.phone) {
      allCountry.forEach(item => {
        if (item.iso2 === this.userData?.geoInfo?.countryCode.toUpperCase()) {
          this.setFormData({ phone: "+" + item.dialCode })
        }
      });
    }
    if (this.users.userId) {
      this.createImagePath()
    }

    if (this.authProviderId)
      await this.getCompanyAddresses(this.authProviderId)
  },

  methods: {
    ...mapActions({
      checkUsersPhone: 'users/checkUsersPhone',
      getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses',   
    }),

    ...mapMutations({
      setData: 'generalInfoUser/setData',
      setFormData: 'generalInfoUser/setFormData',
      createImagePath: 'generalInfoUser/createImagePath',
      openUploadImageModal: 'uploadImageModal/openModal',
    }),

    colorClick() {
      if (this.userData?.rolesInfo?.admin)
        this.openColorPicker = !this.openColorPicker
    },

    /**
     * select image from PC
     * **/
    selectImage() {
      setTimeout(() => {
        this.$refs["uploadFile"].click();
      }, 0)
    },

    /**
     * Upload image
     * **/
    uploadFile(data) {
      let reader = new FileReader();
      reader.onload = e => {
        this.setData({ imagePath: e.target.result });
        this.openUploadImageModal();
        this.setFormData({ image: data.target.files[0] })
      };
      reader.readAsDataURL(data.target.files[0]);
    },


    /**
     * open crop image modal
     * **/
    cropImage() {
      if (this.generalInfoUser.imagePath) {
        this.openUploadImageModal();
      }
    },


    /**
     * Remove Image
     * **/

    removeImage() {
      this.$refs["uploadFile"].value = null
      this.setData({ imagePath: '' })
      this.setFormData({ image: null })

    },
    /**
     * Validate General Info
     * **/

    async validationInfo() {

      this.$v.formData.$touch()
      if (!this.$v.formData.$error && !this.validPhoneNumber && !this.isValidateNumber) {
        const body = {
          phone: this.phone
        };
        if (this.users.userId) {
          body.userId = this.users.userId
        }

        let x = await this.checkUsersPhone(body).then(data => {

          if (!data) {
            this.isValidateEmail = false;
            return Promise.resolve(true)
          } else {
            this.isValidateEmail = true;
            this.errorMessage = 'The user with the current Phone number is already created.'
            return Promise.resolve(false)
          }
        }, error => {
          // this.isValidateEmail = true;
          // this.errorMessage = 'Incorrect Phone Number'
          return Promise.resolve(false)
        })
        return x
      } else {
        return Promise.resolve(false)
      }

    },

    /**
     * Validate Phone Number
     * **/

    validationPhoneNumber() {
      const [phone, obj] = arguments;
      this.validPhoneNumber = !obj.isValid;
      this.$v.formData["phone"].$touch();
      let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
      this.isValidateNumber = !Number(str)
      this.setFormData({ countryCode: obj.regionCode })
    }

  },
}