<template>
  <div class="P-manage-admin">
    <TitleComponent :title-text="'Company Info'"/>
    <div class="P-company-info G-flex G-flex-wrap">
      <div class="P-padding-50">
        <ott-input
          v-model="companyName"
          :error="$v.formData.companyName.$error"
          label="Company Name*"
        />
      </div>
      <div class="P-padding-50">
        <ott-input
          v-model="website"
          :error="$v.formData.website.$error"
          label="Website*"
        />
      </div>
      <div class="P-padding-50 P-change-select-height">
        <ott-select-autocomplete
          v-model="country"
          outlined
          :items="allCountry"
          :height="38"
          :clear="true"
          selected-text="name"
          label="Country"
        />
      </div>
      <div class="P-padding-50 P-change-select-height">
        <ott-select-autocomplete
          v-if="!authProvider.timezone || authProvider.type === 0"
          v-model="timezone"
          :items="timeZoneList"
          :error="$v.formData.timezone.$error"
          :clear="true"
          :multiple="false"
          :persistentHint="true"
          label="Timezone*"
          selected-text="name"
        />
        
        <ott-input
          v-else
          :value="timezone"
          :disabled="true"
          label="Timezone*"
        />
      </div>
    </div>
    <div class="G-flex mt-2">
      <ott-button-loader
        @onClick="updateData()"
        button-text="Save"
        class-name="primary-sm-btn"
        :is-loading="isLoading"
      />
    </div>
  </div>
</template>
<script>
  import CompanyInfoAdminMixin from "./CompanyInfoAdminMixin";
  // components
  import TitleComponent from "@/components/TitleComponent";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  export default {
    name: 'CompanyInfoAdmin',
    components: {OttSelect, OttSelectAutocomplete, OttInput, TitleComponent, OttButtonLoader},
    mixins: [CompanyInfoAdminMixin]
  }
</script>
<style lang="scss" scoped>
  @import "../index.scss";

  .P-company-info {
    margin : 0 -10px;
  }
</style>
