import { mapState, mapMutations} from "vuex";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
// import {required, requiredIf} from "vuelidate/lib/validators";

export default {
  components: {OttSwitch, OttPhoneNumber, OttInput, OttButton},
  data() {
    return {
      showErrorMessage: false
    }
  },
  computed:{
    ...mapState({
      formData:state=>state.generalInfoUser.formData,
      roleUser:state=>state.roleUser,
      users:state=>state.users,
      isLoading: state => state.appModule.isLoading
    }),

    isSelectedRole() {
      let bool = false;
      this.roleUser.roleList.map(item => {
        if (item.value) {
          bool = true
        }
      })  

      return bool
    }
  },
  
  created() {
    if (!this.users.userId) {
      this.resetRolesList()
    }

   this.disableEnableleButton()
  },

  updated() {
   this.disableEnableleButton()
  },

  methods: {
    ...mapMutations({
      setData: 'users/setData',
      resetRolesList: 'roleUser/resetRolesList',
    }),

    disableEnableleButton() {
      if (this.isSelectedRole) {
        this.setData({ disableButton: false })
      }else {
        this.setData({ disableButton: true })
      }
    },
    // selectRole(e){
      // this.$store.commit('roleUser/selectRole', {roleName: role.name, roleValue: role.value})
      // if(this.users.disableFlag){
    //     this.setData({ disableButton: false })
      // }
      // const body = {data, index}
      // this.$store.commit('roleUser/selectRole', body)
      // this.isSelectedRole = false
    // },
    // validateRole(){
    //   let isValidation = false

    //   if(this.roleUser.selectedRoleList.length){
    //     isValidation = true
    //     this.isSelectedRole = false
    //   }else{
    //     isValidation = false
    //     this.isSelectedRole = true
    //   }
    //   return isValidation
    // }

  },

}