import {mapMutations, mapState} from "vuex";

export default {
  watch: {
    providerData(data) {
      if (data) {
        this.setRegisteredData(data)
      }
    }
  },
  computed: {
    ...mapState({
      providerData: state => state.manageAdminModule.providerData,
      registeredData: state => state.manageAdminModule.registeredData,
      isLoading: state => state.appModule.isLoading
    })
  },
  methods: {
    ...mapMutations({
      setRegisteredData: 'manageAdminModule/setRegisteredData',
      openBalanceRefillModal: 'balanceRefill/openBalanceRefillModal',
    }),

    numberToFinanceNumber(number) {
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(number);

      if (number === 0) {
        return '$0.00'
      }

      return formattedNumber
    },
  }
}
