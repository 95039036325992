<template>
  <div class="P-modal-components">
    <div
      v-if="!priceGroup.editMode && !priceGroup.addMode"
      class="P-modal-component-header G-justify-between"
    >
      <div class="P-payment-type G-align-start G-justify-between">
        <ul>
          <li
            :class="{
              'P-active-payment': priceGroup.priceGroupType === 1,
            }"
            @click="selectPaymentType(1)"
            class="G-flex secondary--text"
          >
            Clients Group
          </li>
          <li
            :class="{
              'P-active-payment': priceGroup.priceGroupType === 2,
            }"
            @click="selectPaymentType(2)"
            class="G-flex secondary--text"
          >
            Providers Group
          </li>
        </ul>
      </div>
    </div>
    <div class="P-modal-component-body">
      <ManagePriceGroup
        v-if="
          !priceGroup.priceGroupList.length ||
          priceGroup.editMode ||
          priceGroup.addMode
        "
      />
      <PriceGroupList
        v-if="
          priceGroup.priceGroupList.length &&
          !priceGroup.editMode &&
          !priceGroup.addMode
        "
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OttButton from "@/components/vuetifyComponents/OttButton";
import ManagePriceGroup from "./ManagePriceGroup";
import PriceGroupList from "./PriceGroupList";

export default {
  name: "PriceGroupAdmin",
  components: { PriceGroupList, ManagePriceGroup, OttButton },
  computed: {
    ...mapState({
      priceGroup: (state) => state.priceGroupAdmin,
    }),
  },
  async mounted() {
    await this.getPriceGroupList();
  },
  methods: {
    ...mapActions({
      getPriceGroupListAction: 'priceGroupAdmin/getPriceGroupList'
    }),

    ...mapMutations({
      changePriceGroup: "priceGroupAdmin/ChangePriceGroup",
    }),
    /***
     * Select  price Group
     * */

    async selectPaymentType(index) {
      this.changePriceGroup(index);
      await this.getPriceGroupListAction();
    },
    async getPriceGroupList() {
      await this.getPriceGroupListAction();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-payment-type {
  width: 100%;

  ul {
    margin: 0;
    padding: 1px;
    box-shadow: 0 0 5px #00000012;
    border-radius: 27px;
    display: flex;
    align-items: flex-start;

    li {
      font-size: $txt14;
      font-weight: $semiBold;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 27px;
      transition: $transition;
      text-transform: capitalize;

      &.P-active-payment {
        background-color: $neutral-color25;
      }
    }
  }
}

.theme--dark {
  .P-payment-type {
    ul {
      background-color: $darkMode43;
    }

    svg {
      filter: grayscale(1) invert(1);
    }

    .P-active-payment {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.theme--dark {
  .P-payment-type {
    ul {
      background-color: $darkMode43;
    }

    .P-active-payment {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}
</style>
