class PermissionService {
  hasPermission(userRoles, roles, permissions) {
    let allPermissionsHave = true;
    if (userRoles) {
      permissions.forEach(permission => {
        let found = false;
        userRoles.forEach(role => {
          role.permissions.forEach(userPermission => {
            if (!found) found = userPermission.keyword === permission;
          });
        });
        if (allPermissionsHave) allPermissionsHave = found;
      });
    }
    return allPermissionsHave;
  }
}

const permissionService = new PermissionService();

export default permissionService;
