<template>
  <div class="P-price-group-list">
    <TitleComponent title-text="Price Groups" :isAdd="true" :click="addNewPriceGroup"/>
    <div class="P-label-list">
      <div class="P-table-header">
        <ul class="G-flex ">
          <li class="secondary--text">Group Name</li>
          <li class="secondary--text">Initial Discount</li>
          <li class="secondary--text">Discount Rounding</li>
          <li class="secondary--text">Digits</li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <ul v-for="(items, index) of priceGroupAdmin.priceGroupList" :key="index" class="G-flex ">
          <li class="secondary--text">
            {{items.name[0].name}}
          </li>
          <li class="secondary--text">{{ items.percent }} %</li>
          <li class="secondary--text">{{ getRoundName(items.round )}}</li>
          <li class="secondary--text">{{ items.digits }}</li>
          <li class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="P-list" @click="editPriceGroup(items)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="P-list" @click="openDeleteModal(items)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="P-list" @click="goToCurrentPage('/providers-resellers')">
                    <v-list-item-title>Go To Providers Page</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="P-list" @click="goToCurrentPage('/discounts')">
                    <v-list-item-title>Go To Discount Table</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"       
      @btnFunction="deletePriceGroup()" 
      btnText="Delete"
    />
  </div>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import TitleComponent from "@/components/TitleComponent";
  import WarningModal from "@/components/WarningModal";

  export default {
    name: "PriceGroupList",
    components: { TitleComponent, WarningModal },
    data() {
      return {
        showWarningModal: false,
        deletedPriceGroup: null,
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
      }
    },
    computed: {
      ...mapState({
        priceGroupAdmin: state => state.priceGroupAdmin,
        paretnId: state => state.manageAdminModule.parentId
      }),

      warningModalHeading() {
        return `Delete price group?`
      },

      warningModalText() {
        return `You're about to <ins>delete</ins> company ${this.deletedPriceGroup ? '<ins>' + this.deletedPriceGroup.name[0].name +'</ins>' : '' } payment method. If you're not sure, you can cancel this operation.`
      },
    },
    methods: {
      ...mapActions({
        getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
        deletePriceGroupAction: 'priceGroupAdmin/deletePriceGroup',
        getPriceGroupListAction: 'priceGroupAdmin/getPriceGroupList',
      }),

      ...mapMutations({
        generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',

        resetActiveTabs: 'manageAdminModule/resetActiveTabs',
        toggleOpenModal: 'manageAdminModule/toggleOpenModal',
        editPriceGroupMutation: 'priceGroupAdmin/editPriceGroup',
        addNewPriceGroupMutation: 'priceGroupAdmin/addNewPriceGroup',
      }),

      /**
       * Get price group list
       * **/

      async getPriceGroupList() {
        const data = { // for the provider price grops
          type: 2
        }

        if (this.paretnId) {
          data.provider = this.paretnId
        }

        await Promise.all([
          this.getPriceGroupByTypeList({ type: 1 }), 
          this.getPriceGroupByTypeList(data)
        ])
        .then(data => {
          this.generateAllPriceGroups(data)
        })
      },
      goToCurrentPage(path) {
        if (this.$route.fullPath !== '/providers-resellers' && path === '/providers-resellers') {
          this.$router.push({path: '/providers-resellers'})
        }
        if (this.$route.fullPath !== '/discounts' && path === '/discounts') {
          this.$router.push({path: '/discounts'})
        }
        this.resetActiveTabs()
        this.toggleOpenModal(false)
      },
      getRoundName(name) {
        return this.priceGroupAdmin.discountRoundingList.filter(x => x.value === name)[0].name
      },
      editPriceGroup(item) {
        this.editPriceGroupMutation(item)
      },
      async deletePriceGroup() {
        this.warningModalIsLoading = true

        if (this.deletedPriceGroup) {
          await this.deletePriceGroupAction(this.deletedPriceGroup.id).then(async () => {
            await this.getPriceGroupListAction()
            await this.getPriceGroupList()
          }).finally(() => { 
            this.warningModalIsLoading = false
            this.showWarningModal = false
          })
        }
      },
      openDeleteModal(deletedPriceGroup) {
        this.deletedPriceGroup = deletedPriceGroup
        this.showWarningModal = true;
      },
      addNewPriceGroup() {
        this.addNewPriceGroupMutation()
      }
    },
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-table-header {
    ul {
      li {
        font-size      : $txt12;
        font-weight    : bold;
        padding        : 10px 17px;
        display        : flex;
        align-items    : center;
        text-transform : capitalize;
      }
    }
  }

  .P-table-body {
    ul {
      border-top : 1px solid $neutral-color45;

      li {
        font-size   : $txt14;
        word-break  : break-all;
        padding     : 10px 17px;
        transition  : $transition;
        display     : flex;
        align-items : center;

        & + li {
          border-left : 1px solid $neutral-color45;
        }

        p {
          font-size  : $txt14;
          word-break : break-all;
        }
      }

    }
  }

  .P-label-list {
    overflow      : hidden;
    border-radius : 4px;
    border        : 1px solid $neutral-color45;

    ul {
      padding : 0;

      li {
        max-width : 100%;
        width     : 100%;

        & + li {
          border-left : 1px solid $neutral-color45;
        }
      }
    }

    li {
      &:last-child {
        max-width   : 30px;
        min-width   : 30px;
        padding     : 3px 0;
        border-left : 1px solid transparent;
      }

      /*&:nth-child(1) {*/
      /*  max-width : 150px;*/
      /*  min-width : 150px;*/
      /*}*/

      /*&:nth-child(2) {*/
      /*  max-width : 150px;*/
      /*  min-width : 150px;*/
      /*}*/
    }
  }

  .P-actions {
    opacity    : 0;
    visibility : hidden;
    transition : $transition;
  }

  .P-price-group-list {
    margin-top : 10px;
  }

  .P-list {
    padding-bottom : 10px !important;
    padding-top    : 10px !important;
  }
</style>
