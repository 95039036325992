<template>
  <div>
    <title-component title-text="Check" />
    <div class="G-grid-column-2">
      <ott-input
        v-model="checkNumberInp"
        label="Number"
        is-percent
        :error="$v.checkNumber.$error"
      />
      <p class="P-available-block" v-if="checkNumber">
        <span class="P-available">Available:</span>
        <span class="P-available-number">{{ availableCheckNumber }}</span>
      </p>
    </div>
    <div class="P-checkbox">
      <ott-checkbox
        v-model="mailByCheckeeper"
        label="Mail By Checkeeper"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
// components
import TitleComponent from "@/views/clients/components/TitleComponent";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
export default {
  name: "Check",
  components: { OttCheckbox, OttInput, TitleComponent },
  validations: {
    checkNumber: {
      required,
    },
  },
  computed: {
    ...mapState({
      checkNumber: (state) => state.balanceRefill.checkNumber,
      availableCheckNumber: (state) => state.balanceRefill.availableCheckNumber,
      checkeeper: (state) => state.balanceRefill.mailByCheckeeper,
    }),

    checkNumberInp: {
      set(value) {
        this.setCheckNumber(value);
      },
      get() {
        return this.checkNumber;
      },
    },

     mailByCheckeeper: {
       set(value) {
        this.setMailByCheckeeper(value);
       },
       get() {
         return this.checkeeper;
       }
    }
  },
  methods: {
    ...mapMutations({
      setCheckNumber: "balanceRefill/setCheckNumber",
      setMailByCheckeeper: "balanceRefill/setMailByCheckeeper",
      changeActiveStep: "balanceRefill/changeActiveStep",
    }),

  },
};
</script>

<style scoped lang="scss">
@import "../balanceRefillAndRefund.scss";
@import "src/assets/scss/variables";
.G-grid-column-2 {
  align-items: center;
}
.P-available-block {
  margin-bottom: 0;
  .P-available {
    font-size: $txt12;
    font-weight: $semiBold;
    color: $neutral-color;
    margin-right: 22px;
  }
  .P-available-number {
    font-size: $txt14;
    color: $secondary-color;
  }
}
.P-checkbox {
  margin-top: 18px;
  margin-left: -2px;
}
::v-deep {
  .v-input--selection-controls {
    padding-top: 0;
  }
}
</style>