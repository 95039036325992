<template>
  <div class="P-tax-country-list">
    <div class="P-label-list">
      <div class="P-table-header">
        <ul class="G-flex ">
          <li class="secondary--text">Country</li>
          <li class="secondary--text">Service percent</li>
          <li class="secondary--text">Product percent</li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <div
          v-for="(items, index) of saleTaxes.countryTaxList"
          :key="index"
          :class="{ 'P-active-table': index === activeTable }"
          class="P-sub-table-block"         
          @click="toggleTable(index)"
        >
          <div class="P-main-sub-table">
            <span
              v-if="items.subList.length"
              class="mdi mdi-menu-up P-rotate-icon secondary--text"
            />
            <ul class="G-flex ">
              <li class="secondary--text">
                {{ getCountryList(items.country) }}
              </li>
              <li class="secondary--text">
                {{ items.subList.length ? "N/A" : items.servicePercent || "-" }}
              </li>
              <li class="secondary--text">
                {{ items.subList.length ? "N/A" : items.productPercent }}
              </li>

              <li class="G-align-center">
                <div v-if="!items.subList.length" class="P-actions">
                  <v-menu left content-class="P-actions-list">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="editTaxData(items, index, false)"
                        class="P-list"
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openWarningModal(index, false)"
                        class="P-list"
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="items.subList.length" class="P-sub-table">
            <h3 class="secondary--text">State</h3>
            <ul v-for="(item, i) of items.subList" :key="i" class="G-flex">
              <li class="secondary--text">{{ getStateList(item.state) }}</li>
              <li class="secondary--text">{{ item.servicePercent }}</li>
              <li class="secondary--text">{{ item.productPercent }}</li>
              <li>
                <div class="P-actions">
                  <v-menu left content-class="P-actions-list">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="editTaxData(item, i, true)"
                        class="P-list"
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openWarningModal(i, true)"
                        class="P-list"
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"  
      @btnFunction="deleteTax()" 
      btnText="Delete"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import allCountry from "@/constants/all-countries";
import usStates from "@/constants/us-states";
import WarningModal from "@/components/WarningModal";

export default {
  name: "CountryTaxList",
  components: {
    WarningModal
  },
  data() {
    return {
      countryList: allCountry,
      stateList: usStates,
      activeTable: null,
      showWarningModal: false,
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      deletedTaxIndex: null,
      deletedTaxIsSub: false
    };
  },

  computed: {
    ...mapState({
      saleTaxes: state => state.saleTaxesAdmin,
      addProviderModal: state => state.manageAdminModule
    }),

    warningModalHeading() {
      return `Delete company sale tax?`
    },

    warningModalText() {
      return `You're about to <ins>delete</ins> company sale tax. If you're not sure, you can cancel this operation.`
    },
  },

  methods: {
    ...mapActions({
      updateSaleTaxData: "saleTaxesAdmin/updateSaleTaxData"
    }),

    ...mapMutations({
      deleteTaxCountryMutation: 'saleTaxesAdmin/deleteTaxCountry',
      toSaveData: 'saleTaxesAdmin/toSaveData',
      editTaxDataMutation: 'saleTaxesAdmin/editTaxData',
    }),

    getCountryList(country) {
      return this.countryList.filter(x => x.value === country)[0].name;
    },

    getStateList(state) {
      return this.stateList.filter(x => x.value === state)[0].name;
    },

    async deleteTax() {
      this.warningModalIsLoading = true;
      await this.deleteTaxCountry().finally(() => {
        this.warningModalIsLoading = false;
      });
      this.showWarningModal = false;
    },

    async deleteTaxCountry(index, isSub) {
      const body = {
        index,
        isSub
      };
      this.deleteTaxCountryMutation(body);
      this.toSaveData();
      await this.updateSaleTaxData(this.addProviderModal.providerId);
    },

    editTaxData(data, index, isSubData) {
      const body = { data, index, isSubData };
      this.editTaxDataMutation(body);
    },

    toggleTable(index) {
      if ((index || index === 0) && index === this.activeTable) {
        this.activeTable = null;
      } else {
        this.activeTable = index;
      }
    },

    openWarningModal(index, isSub) {
      this.deletedTaxIndex = index;
      this.deletedTaxIsSub = isSub;
      this.showWarningModal = true;
      this.warningModalIsLoading = false;   
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-table-header {
  ul {
    li {
      font-size: $txt12;
      font-weight: bold;
      padding: 8px 17px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  }
}

.P-table-body {
  ul {
    li {
      font-size: $txt14;
      word-break: break-all;
      padding: 10px 17px;
      display: flex;
      align-items: center;

      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }

    border-top: 1px solid $neutral-color45;
  }
}

.P-label-list {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid $neutral-color45;

  ul {
    padding: 0;

    li {
      max-width: 100%;
      width: 100%;

      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }
  }

  li {
    &:last-child {
      max-width: 30px;
      min-width: 30px;
      padding: 3px 0;
      border-left: 1px solid transparent;
    }

    &:nth-child(2) {
      max-width: 230px;
      min-width: 230px;
    }

    &:nth-child(3) {
      max-width: 200px;
      min-width: 200px;
    }

    &:first-child {
      min-width: 320px;
      padding-left: 35px;
    }
  }
}

.P-actions {
  opacity: 0;
  visibility: hidden;
  transition: $transition;
}

.P-sub-table {
  max-height: 0;
  overflow: hidden;

  h3 {
    font-size: $txt12;
    font-weight: bold;
    padding: 10px 10px 10px 30px;
    border-top: 1px solid $neutral-color45;
  }
}
.P-sub-table-block {
  cursor: pointer;
  &.P-active-table {
    background-color: $neutral-color7;
    .P-sub-table {
      background-color: $neutral-color7;
      max-height: 1000px;
      h3 {
        background-color: $white;
      }
    }
    .P-rotate-icon {
      transform: translateY(-50%) rotate(0);
    }
  }
}
.P-main-sub-table {
  position: relative;

  .P-rotate-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    font-size: $txt22;
    transition: $transition;
  }
}
.theme--dark {
  .P-sub-table-block {
    cursor: pointer;
    &.P-active-table {
      background-color: #4a4d50;
      .P-sub-table {
        max-height: 1000px;
        h3 {
          background-color: $darkBgLighterDM;
        }
        li {
          background-color: #4a4d50;
        }
      }
      .P-rotate-icon {
        transform: translateY(-50%) rotate(0);
      }
    }
  }
  &.v-list {
    background-color: #3c4043;
  }
}
</style>
