import { mapActions, mapMutations, mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            balanceRefill: state => state.balanceRefill,
            balance: state => state.sidebar.balance,
            paymentList: state => state.paymentMethodsAdmin.paymentList,
            companyEmails: state => state.contactAdminModule.emails  

        }),

        emailForIncoice() {
            let invoiceEmail = null;
      
            if (this.companyEmails.length) {
              this.companyEmails.map(email => {
                if (email.address) {
                  if (email.forInvoice) {
                    invoiceEmail = email.address
                  }
                  
                  if (!email.forInvoice && email.isMain){
                    invoiceEmail = email.address
                  }
                }       
              })
            }
            
            return invoiceEmail
        },
          
        // balance type - refill, refund
        balanceType: {
            get() {
                return this.balanceRefill.balance
            },
            set(value) {
                this.setBalance(value)
            }
        },
        // balance amount
        amount: {
            get() {
                return this.balanceRefill.amount
            },
            set(value) {
                this.setBalanceAmount(value)
            }
        }
    },
    methods: {
        ...mapMutations({
            setBalance: 'balanceRefill/setBalance',
            setBalanceAmount: 'balanceRefill/setBalanceAmount',
            closeBalanceRefillModalMutation: 'balanceRefill/closeBalanceRefillModal',
            resetFields: 'balanceRefill/resetFields',
            sidebarSetData: 'sidebar/setData',
            companyContactSetData: 'contactAdminModule/setData',
            resetBalanceRefil: "balanceRefill/resetState",
            resetPaymentMethodsAdmin: "paymentMethodsAdmin/resetState"
        }),

        closeBalanceRefillModal() {
            this.closeBalanceRefillModalMutation()
            this.resetFields()
            this.resetBalanceRefil();
            this.resetPaymentMethodsAdmin();
            this.$v.$reset()
        },

    }
}