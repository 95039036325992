<template>
  <div class="P-bank-account">
    <div class="P-inputs">
      <div class="P-full-line G-flex">
        <div class="P-padding-33">
          <OttInput
            label="Bank name*"
            v-model="bankName"
            :error="$v.bankTransfer.bankName.$error"
          />
        </div>
        <div class="P-padding-33">
          <OttInput
            label="Routing number*"
            type="number"
            v-model="routingNumber"
            :error="$v.bankTransfer.routingNumber.$error"
          />
        </div>
        <div class="P-padding-33">
          <OttInput
            v-model="accountNumber"
            :error="$v.bankTransfer.accountNumber.$error"
            label="Account number*"
          />
        </div>
      </div>
      <v-radio-group v-model="bankTransfer.type">
        <div class="P-full-line G-flex ms-2 mb-2">
          <div class="P-radio-box mr-3">
            <OttRadio
              v-model="paymentMethod.bankAccountType.personal"
              label="Personal account"
            />
          </div>
          <div class="P-radio-box">
            <OttRadio
              v-model="paymentMethod.bankAccountType.business"
              label="Business account"
            />
          </div>
        </div>
      </v-radio-group>
      <div
        v-if="bankTransfer.type === paymentMethod.bankAccountType.personal"
        class="P-full-line G-flex"
      >
        <div class="P-padding-33">
          <OttInput
            v-model="firstname"
            :error="$v.bankTransfer.firstname.$error"
            label="First name*"
          />
        </div>
        <div class="P-padding-33">
          <OttInput
            v-model="lastname"
            :error="$v.bankTransfer.lastname.$error"
            label="Last name*"
          />
        </div>
        <div class="P-padding-33">
          <OttInput
            v-model="nickname"
            :error="$v.bankTransfer.nickname.$error"
            label="Nickname*"
          />
        </div>
      </div>
      <div class="P-full-line G-flex" v-else>
        <div class="P-padding-33">
          <OttInput
            v-model="companyName"
            :error="$v.bankTransfer.companyName.$error"
            label="Company name*"
          />
        </div>
      </div>
      <div class="P-full-line G-flex">
        <template v-if="!pageIsPayBalance">
          <div class="P-checkbox">
            <ott-checkbox v-model="bankTransfer.default" label="Default" />
            <!-- :disabled="paymentMethod.bankTransfer.inUse ||isMainCheck"-->
          </div>
          <div class="P-checkbox">
            <ott-checkbox v-model="bankTransfer.inUse" label="In Use" />
            <!-- :disabled="paymentMethod.bankTransfer.default || !paymentMethod.paymentList.length" -->
          </div>
         </template>

         <div v-else class="P-checkbox">
            <ott-checkbox v-model="bankTransfer.save" label="Save" />
          </div>
      </div>
    </div>


    <template v-if="!pageIsPayBalance">
      <save-reset-buttons
        v-if="paymentMethod.paymentList.length"
        :right-label="paymentMethod.editMode ? 'Save' : 'Add'"
        :right-click-func="() => manageCreditCard()"
        :left-click-func="() => resetData()"
        :is-loading="isLoading"
      />

      <div class="G-flex mt-5" v-else>
        <ott-button-loader
          @onClick="manageCreditCard()"
          :is-loading="isLoading"
          button-text="Add"
          class-name="primary-sm-btn"
        />
      </div>
    </template>

    <div class="mt-5">
      <ErrorMessageContainer v-if="error" :message="errorMessage" />
    </div>
  </div>
</template>
<script>
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import BankAccountMixin from "./BankAccountMixin";

export default {
  name: "BankAccount",
  components: {
    ErrorMessageContainer,
    OttCheckbox,
    OttRadio,
    OttInput,
    SaveResetButtons,
    OttButtonLoader,
  },
  mixins: [BankAccountMixin],
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-bank-account::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.P-bank-account {
  margin-top: 15px;

  .P-inputs {
    margin: 0 -10px;
  }
}
</style>
