import { mapState, mapMutations, mapActions } from "vuex";
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  props: {
    pageIsPayBalance: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isMainCheck:false,
      error:false,
      errorMessage:''
    }
  },
  validations: {
    bankTransfer: {
      bankName: {required},
      routingNumber: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      },
      accountNumber: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(12)
      },
      companyName: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.business
          );
        })
      },
      firstname: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.personal
          );
        })
      },
      lastname: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.personal
          );
        })
      },
      nickname: {
        required: requiredIf(function () {
          return (
            this.bankTransfer.type===this.paymentMethod.bankAccountType.personal
          );
        })
      },
    },
  },
  mounted() {
    this.isMainCheck = this.getCurrentData()
    this.getSomeData() 
  },
  computed: {
    ...mapState({
      paymentMethod: state => state.paymentMethodsAdmin,
      addProviderModal: state => state.manageAdminModule,
      bankTransfer: state => state.paymentMethodsAdmin.bankTransfer,
      adminPersonalInfoModule: state => state.personalInfoAdminModule,
      companyInfo: state => state.companyInfoAdminModule,
      isLoading: state => state.appModule.isLoading,
    }),

    bankName: {
      get() {
        return this.paymentMethod.bankTransfer.bankName
      },
      set(value) {
        this.setBankName(value)
      }
    },
    routingNumber: {
      get() {
        return this.paymentMethod.bankTransfer.routingNumber
      },
      set(value) {
        this.setRoutingNumber(value)
      }
    },
    accountNumber: {
      get() {
        return this.paymentMethod.bankTransfer.accountNumber
      },
      set(value) {
        this.setAccountNumber(value)
      }
    },
    companyName: {
      get() {
        return this.paymentMethod.bankTransfer.companyName
      },
      set(value) {
        this.setCompanyName(value)
      }
    },
    firstname: {
      get() {
        return this.paymentMethod.bankTransfer.firstname
      },
      set(value) {
        this.setFirstName(value)
      }
    },
    lastname: {
      get() {
        return this.paymentMethod.bankTransfer.lastname
      },
      set(value) {
        this.setLastName(value)
      }
    },
    nickname: {
      get() {
        return this.paymentMethod.bankTransfer.nickname
      },
      set(value) {
        this.setNickName(value)
      }
    },
    type: {
      get() {
        return this.paymentMethod.bankTransfer.type
      },
      set(value) {
        this.setType(value)
      }
    },
  },
  methods: {
    ...mapActions({
      updateBankAccount: 'paymentMethodsAdmin/updateBankAccount',
      getPaymentDataList: 'paymentMethodsAdmin/getPaymentDataList',
      addPaymentMethod: 'paymentMethodsAdmin/addPaymentMethod',
    }),

    ...mapMutations({
      setBankName: 'paymentMethodsAdmin/setBankName',
      setRoutingNumber: 'paymentMethodsAdmin/setRoutingNumber',
      setAccountNumber: 'paymentMethodsAdmin/setAccountNumber',
      setCompanyName: 'paymentMethodsAdmin/setCompanyName',
      setFirstName: 'paymentMethodsAdmin/setFirstName',
      setLastName: 'paymentMethodsAdmin/setLastName',
      setNickName: 'paymentMethodsAdmin/setNickName',
      setType: 'paymentMethodsAdmin/setType',
      toSaveDataBankAccount: 'paymentMethodsAdmin/toSaveDataBankAccount',
      closeAddPayment: 'paymentMethodsAdmin/closeAddPayment',
      setIsDefault: 'paymentMethodsAdmin/setIsDefault',
      closeAddEditPaymentData: 'paymentMethodsAdmin/closeAddEditPaymentData',
    }),
    
    /**
     * Add or edit credit Card
     * **/
    async manageCreditCard() {
      this.$v.bankTransfer.$touch()

      this.error = false;
      this.errorMessage = '';

      this.checkIsDefault()
      if(!this.$v.bankTransfer.$error) {

        this.error = false;
        this.errorMessage = ''

        this.toSaveDataBankAccount(this.addProviderModal.providerId)
        if(this.paymentMethod.editMode){

          try {
            await this.updateBankAccount().then(async data=>{
              if(data){
                await this.getPaymentDataList(this.addProviderModal.providerId).then(data=>{
                  this.closeAddPayment();
                })
              }
            })
          } catch(e) {
            if (e.response.status >= 300) {
              this.error = true;
              this.errorMessage = e.response.data.message
            }
          }
        
        }else{

          try {
            await this.addPaymentMethod().then(async data => {
              if(data){
                await this.getPaymentDataList(this.addProviderModal.providerId).then(data=>{
                  this.closeAddPayment();
                })
              }
            })
          } catch(e) {
            if (e.response.status >= 300) {
              this.error = true;
              this.errorMessage = e.response.data.message
            }
          }
          
        }
      }
    },

    /**
     * Check is Default payment method
     * when added in first time
     * **/

    checkIsDefault(){
      if(!this.paymentMethod.paymentList.length){
        this.setIsDefault(true)
      }
    },

    resetData(){
      this.closeAddEditPaymentData()
    },

    /**
     * get current  data for check inMain
     * **/
    getCurrentData() {
      let currentIndex = false
      if (!this.paymentMethod.addMode && this.paymentMethod.paymentList) {
        this.paymentMethod.paymentList.forEach((item, index) => {
          if (item.id === this.paymentMethod.creditCard.id) {
            currentIndex = item.default
          }
        })
      }
      return currentIndex
    },

    /**
     * Get company name, first name anf last  name data
     * **/

    getSomeData() {
      if (
        this.companyInfo.formData.companyName &&
        !this.paymentMethod.editMode
      ) {
        this.setCompanyName(this.companyInfo.formData.companyName);
      }

      if (this.adminPersonalInfoModule.companyAddressesForm.firstName &&
        !this.paymentMethod.editMode
      ) {
        this.setFirstName(this.adminPersonalInfoModule.companyAddressesForm.firstName);
      }
      if (this.adminPersonalInfoModule.companyAddressesForm.lastName &&
        !this.paymentMethod.editMode
      ) {
        this.setLastName(this.adminPersonalInfoModule.companyAddressesForm.lastName);
      }
    },
  },
}
